import {
	NumberGroupCreateRequest,
	NumberGroupIndexResponse,
	NumberGroupProfileResponse,
	NumberGroupUpdateRequest,
	NumberGroupProfileRequest,
	NumberGroupNumberIndexResponse,
	NumberGroupNumbersUpdateRequest
} from 'RtModels';
import { IRtxApiRoute, Rt800ApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';

export enum NumberGroupNumberUpdateType {
	Add = 1,
	Remove,
	Replace
}

export class NumberGroupResource extends HttpResource<
	NumberGroupIndexResponse,
	NumberGroupProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.NumberGroups);

		this.setGetAllParams({ pageSize: RtxPageSizes.ExcelMax });
	}

	public createNumberGroup(
		label: string,
		isActive: number,
		respOrgId: string,
		tfns: string[]
	) {
		const req: NumberGroupCreateRequest = {
			label,
			respOrgId,
			isActive,
			tfns
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<NumberGroupProfileResponse>(
			Rt800ApiRoutes.NumberGroups.Create,
			{ body }
		);
	}

	public updateNumberGroup(
		numberGroupId: number | string,
		label: string,
		isActive: number,
		respOrgId: string,
		tfns?: string[]
	) {
		const req: NumberGroupUpdateRequest = {
			label,
			respOrgId,
			isActive,
			tfns
		};
		const body = JSON.stringify(req);
		numberGroupId = Number(numberGroupId);
		const urlParams: NumberGroupProfileRequest = { numberGroupId };

		return this.fetchWithRoute<NumberGroupProfileResponse>(
			Rt800ApiRoutes.NumberGroups.Update,
			{ body, urlParams }
		);
	}

	public getNumberGroupNumbersUpdate(
		numberGroupId: number,
		tfns: string[],
		updateType: NumberGroupNumberUpdateType
	) {
		let endpoint: IRtxApiRoute;

		switch (updateType) {
			case NumberGroupNumberUpdateType.Add:
				endpoint = Rt800ApiRoutes.NumberGroupNumbersAdd.Update;
				break;
			case NumberGroupNumberUpdateType.Remove:
				endpoint = Rt800ApiRoutes.NumberGroupNumbersRemove.Update;
				break;
			default:
				endpoint = Rt800ApiRoutes.NumberGroupNumbersReplace.Update;
				break;
		}

		const request: NumberGroupNumbersUpdateRequest = { tfns };
		const urlParams: NumberGroupProfileRequest = { numberGroupId };
		const body = JSON.stringify(request);

		return this.fetchWithRoute<NumberGroupNumberIndexResponse[]>(endpoint, {
			urlParams,
			body
		});
	}

	public getNumberGroupNumbersResource(
		numberGroupId: number | string
	): HttpResource<NumberGroupNumberIndexResponse> {
		const NumberGroupNumbersResource =
			new ArrayResource<NumberGroupNumberIndexResponse>('tfn', []);
		numberGroupId = Number(numberGroupId);
		const urlParams: NumberGroupProfileRequest = { numberGroupId };

		NumberGroupNumbersResource.setApiRouteForGetAll(
			Rt800ApiRoutes.NumberGroupNumbers.Index,
			{ urlParams }
		);

		NumberGroupNumbersResource.setGetAllParams({
			pageSize: RtxPageSizes.ExcelMax
		});

		return NumberGroupNumbersResource;
	}
}
