import {
	$FileStreamProfileResponse,
	CompressionTypes,
	DownloadTypes
} from 'RtModels';
import { CompressionTypesSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/CompressionTypesSelect';
import { DownloadOptionSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/DownloadOptionSelect';
import { TFileStream } from 'RtUi/app/rtVue/FileStreams/lib/forms/FileStreamEditor';
import { RemoteCredentialsEditor } from 'RtUi/app/rtVue/FileStreams/lib/forms/RemoteCredentialsEditor';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { JSONEditor } from 'RtUi/components/ui/JsonEditor';
import { isValidJson } from 'RtUi/utils/json';
import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const isRegularCredentials = (value: string) => {
	const credentials = isValidJson(value) ? JSON.parse(value) : {};
	const regularCredentials = [
		'username',
		'password',
		'host',
		'port',
		'connectMethod'
	];
	for (const key of Object.keys(credentials)) {
		if (!regularCredentials.includes(key)) {
			return false;
		}
	}
	return true;
};

interface IFileStreamDownloadEditorProps {
	editingFileStream: TFileStream;
	displayMode?: boolean;
	onChange?: <K extends keyof TFileStream>(
		key: K,
		value: TFileStream[K]
	) => void;
}

export const FileStreamDownloadEditor = ({
	editingFileStream,
	displayMode = false,
	onChange = () => {}
}: IFileStreamDownloadEditorProps): JSX.Element => {
	const [downloadOption, setDownloadOption] = useState<
		DownloadTypes | undefined
	>(editingFileStream?.downloadRemoteProtocol);
	const [useAdvanced, setUseAdvanced] = useState<boolean>(
		editingFileStream.downloadRemoteCredentials
			? !isRegularCredentials(editingFileStream.downloadRemoteCredentials)
			: false
	);
	const [compressionOption, setCompressionOption] =
		useState<CompressionTypes>();
	const [remotePath, setRemotePath] = useState<string>(
		String(editingFileStream.downloadRemotePath ?? '')
	);
	const [remotePathError, setRemotePathError] = useState<string>();

	const onChangeRemotePath = (value: string) => {
		setRemotePathError(undefined);
		setRemotePath(value);
		const templateRegex = /^\${(.+)}$/g;
		const templateValue = templateRegex.exec(value);

		if (templateValue && !moment(templateValue[1]).isValid()) {
			setRemotePathError('Invalid Date format');
			return;
		}

		onChange('downloadRemotePath', value ?? '');
	};

	return (
		<Row>
			<Col md={6}>
				<Row>
					<Col>
						<DownloadOptionSelect
							displayMode={displayMode}
							label="Download Remote Protocol"
							clearable={false}
							onChange={(newDownloadOption) => {
								setDownloadOption(newDownloadOption);
								onChange('downloadRemoteProtocol', newDownloadOption);
								if (downloadOption !== newDownloadOption) {
									onChange('downloadRemoteCredentials', JSON.stringify({}));
								}
							}}
							value={downloadOption}
							initialOptionId={
								editingFileStream
									? editingFileStream.downloadRemoteProtocol
									: undefined
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<BooleanRadioFormControl
							required={
								$FileStreamProfileResponse.properties.isDownload.isRequired
							}
							onChange={(value) => onChange('isDownload', value)}
							label="Is Download"
							displayMode={displayMode}
							value={editingFileStream.isDownload}
						/>
					</Col>
					<Col>
						<BooleanRadioFormControl
							onChange={(val) => setUseAdvanced(Boolean(val))}
							label="Advanced"
							displayMode={displayMode}
							value={Number(useAdvanced)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{useAdvanced && (
							<JSONEditor
								label="Credentials"
								value={
									isValidJson(editingFileStream.downloadRemoteCredentials)
										? JSON.parse(editingFileStream.downloadRemoteCredentials)
										: undefined
								}
								onChange={(value) =>
									onChange('downloadRemoteCredentials', JSON.stringify(value))
								}
							/>
						)}
						{!useAdvanced && (
							<RemoteCredentialsEditor
								downloadRemoteCredentials={
									editingFileStream.downloadRemoteCredentials
								}
								isDownload={Boolean(editingFileStream.isDownload)}
								downloadType={downloadOption}
								displayMode={displayMode}
								onChange={onChange}
							/>
						)}
					</Col>
				</Row>
			</Col>
			<Col md={6}>
				<InputFormControl
					required={
						$FileStreamProfileResponse.properties.downloadRemotePath.isRequired
					}
					label="Download Remote Path"
					onChange={(value) => onChangeRemotePath(value)}
					displayMode={displayMode}
					customValidityError={remotePathError}
					value={remotePath}
					autoHeight
				/>
				<BooleanRadioFormControl
					required={
						$FileStreamProfileResponse.properties.downloadRemoteDelete
							.isRequired
					}
					onChange={(value) => onChange('downloadRemoteDelete', value)}
					label="Download Remote Delete"
					displayMode={displayMode}
					value={editingFileStream.downloadRemoteDelete}
				/>
				<InputFormControl
					onChange={(value) => onChange('downloadRemoteMovePath', value)}
					label="Download Remote Move Path"
					displayMode={displayMode}
					value={editingFileStream.downloadRemoteMovePath}
				/>
				<CompressionTypesSelect
					required={
						$FileStreamProfileResponse.properties.downloadCompressionType
							.isRequired
					}
					displayMode={displayMode}
					label="Download Compression Type"
					clearable={false}
					onChange={(compressionOption) => {
						setCompressionOption(compressionOption);
						onChange('downloadCompressionType', compressionOption);
					}}
					value={compressionOption}
					initialOptionId={editingFileStream.downloadCompressionType}
				/>
				<InputFormControl
					label="Download File Regex"
					onChange={(value) => onChange('downloadFileRegex', value ?? '')}
					displayMode={displayMode}
					value={String(editingFileStream.downloadFileRegex)}
					autoHeight
				/>
				<InputFormControl
					label="Download Frequency"
					onChange={(value) =>
						onChange('downloadFrequency', Number(value ?? 0))
					}
					displayMode={displayMode}
					value={String(editingFileStream.downloadFrequency || 0)}
					autoHeight
					type="number"
				/>
			</Col>
		</Row>
	);
};
