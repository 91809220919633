import { LcrCarrierRateIndexResponse } from 'RtModels';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';
import { useGetCarrierRates } from 'RtUi/app/rtLco/Carriers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCarrierRatesColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/carriers';
import { useMemo } from 'react';

interface ILcrCarrierRatesGridProps {
	lcrCarrierId: number;
	rateSheetTypeId: number;
}

export const LcrCarrierRatesGrid = ({
	rateSheetTypeId,
	lcrCarrierId
}: ILcrCarrierRatesGridProps) => {
	const { data, isFetching: isLoading } = useGetCarrierRates(
		{ lcrCarrierId },
		{ pageSize: RtxPageSizes.ExcelMax, page: 0 }
	);
	const { rateKeyHeader1, rateKeyHeader2 } = useMemo(() => {
		const keys = {
			rateKeyHeader1: 'NPA',
			rateKeyHeader2: 'NXX'
		};

		if (rateSheetTypeId === 2) {
			return {
				rateKeyHeader1: 'LATA',
				rateKeyHeader2: 'OCN'
			};
		}

		return keys;
	}, [rateSheetTypeId]);

	const columns = useMemo(
		() => getCarrierRatesColumns(rateKeyHeader1, rateKeyHeader2),
		[rateKeyHeader1, rateKeyHeader2]
	);

	return (
		<DataGrid<LcrCarrierRateIndexResponse>
			pageName="rtLco-carriersProfileRates"
			loading={isLoading}
			data={data?.data}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
