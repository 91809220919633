import AudioPlayer from 'react-h5-audio-player';
import { PromptResource } from 'RtUi/app/rtSip/PromptSegments/lib/resources/PromptResource';
import {
	PromptSegmentRouter,
	PromptSegmentsContainerTabs
} from 'RtUi/app/rtSip/PromptSegments/PromptSegment.router';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { PromptEditor } from 'RtUi/app/rtSip/PromptSegments/lib/forms/PromptEditor';
import { PromptProfileResponse } from 'RtModels';
import { Col, Row, Card } from 'react-bootstrap';

interface IPromptSegmentsProfileContainerState {
	activeTab: string;
	promptUrl: string;
	profile?: PromptProfileResponse;
}

@PromptSegmentRouter.getProfileRtUiController()
export class PromptSegmentProfileContainer extends ProfileApplicationContainer<
	{},
	IPromptSegmentsProfileContainerState
> {
	public Tabs = PromptSegmentsContainerTabs;
	public promptResource = new PromptResource();
	public state: IPromptSegmentsProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		promptUrl: ''
	};

	public componentDidMount() {
		this.init();
	}

	public async init() {
		const id = this.getIdParam();

		const profile = await this.promptResource.get(id);

		if (!profile) {
			return;
		}

		const promptUrl = await this.promptResource.getPromptFileUrl(
			profile.promptId
		);

		this.setState({ profile, promptUrl });
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		return (
			<TabbedLayout
				activeTab={this.state.activeTab}
				profile={profile}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={PromptSegmentRouter}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col lg={{ span: 6, order: 1 }} md={{ order: 2 }}>
							<Card body>
								<PromptEditor
									disableBody
									disablePadding
									editMode={profile}
									onUpdate={(newProfile) =>
										this.setState({
											profile: newProfile
										})
									}
								/>
							</Card>
						</Col>
						<Col lg={{ span: 6, order: 2 }} md={{ order: 1 }}>
							<AudioPlayer
								className="mb-3"
								customAdditionalControls={[]}
								volume={0.35}
								src={this.state.promptUrl}
							/>
							{this.state.promptUrl && (
								<div>
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											if (this.state.promptUrl) {
												const a = document.createElement('a');
												a.href = this.state.promptUrl;
												a.download = `prompt_${this.state.profile?.promptId}.wav`;
												document.body.appendChild(a);
												a.click();
												document.body.removeChild(a);
											}
										}}
									>
										Download Prompt File
									</button>
								</div>
							)}
						</Col>
					</Row>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
