import {
	CprRateSheetCreateRequest,
	CprRateSheetIndexResponse,
	CprRateSheetProfileRequest,
	CprRateSheetProfileResponse,
	CprRateSheetResultsProfileResponse,
	LcrRateSheetType
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';

export class RateSheetResource extends HttpResource<
	CprRateSheetIndexResponse,
	CprRateSheetProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.CprRateSheets);
	}

	public create(
		respOrgId: string,
		rateSheetTypeId: LcrRateSheetType,
		routingCacheTypeId: number,
		routingCacheKey: string,
		effectiveTs: Date,
		respOrgCarrierIds: number[],
		minuteProfileIds: number[]
	) {
		const req: CprRateSheetCreateRequest = {
			respOrgId,
			rateSheetTypeId,
			routingCacheTypeId,
			routingCacheKey,
			effectiveTs,
			respOrgCarrierIds,
			minuteProfileIds
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<CprRateSheetProfileResponse>(
			Rt800ApiRoutes.CprRateSheets.Create,
			{ body }
		);
	}

	public delete(cprRateSheetId: number) {
		const urlParams: CprRateSheetProfileRequest = { cprRateSheetId };

		return this.fetchWithRoute<CprRateSheetProfileResponse>(
			Rt800ApiRoutes.CprRateSheets.Delete,
			{ urlParams }
		);
	}

	public getResultsResource(
		cprRateSheetId: number,
		pageSize: number = RtxPageSizes.ExcelMax
	) {
		const rateSheetResultsResource =
			new ArrayResource<CprRateSheetResultsProfileResponse>('id');
		const urlParams: CprRateSheetProfileRequest = { cprRateSheetId };

		rateSheetResultsResource.setApiRouteForGetAll(
			Rt800ApiRoutes.CprRateSheetResults.Profile,
			{ urlParams }
		);
		if (pageSize) {
			rateSheetResultsResource.setGetAllParams({ pageSize });
		}
		return rateSheetResultsResource;
	}
}
