import { CprColDataGridColumnHeader } from 'RtUi/components/data/DataGrid/columns/CprColDataGridColumn/components/CprColDataGridColumnHeader';
import { CprColDataGridColumnHeaderActions } from 'RtUi/components/data/DataGrid/columns/CprColDataGridColumn/components/CprColDataGridColumnHeaderActions';
import { CprColDataGridColumnInput } from 'RtUi/components/data/DataGrid/columns/CprColDataGridColumn/components/CprColDataGridColumnInput';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2';
import { noop } from 'lodash-es';

interface CprColDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	cprCol: CprCol;
	onUpdate?: () => void;
}

export const CprColDataGridColumn = <T = any,>({
	displayMode,
	cprCol,
	size = 200,
	onUpdate = noop,
	...config
}: CprColDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (cprCol: CprCol) => {
		return cprCol.getRawValues().join(', ');
	};

	const renderHeader = () => (
		<CprColDataGridColumnHeader cprCol={cprCol} header={config.header} />
	);

	return DefaultDataGridColumn({
		...config,
		enableColumnActions: !displayMode,
		enableColumnDragging: !displayMode,
		enableEditing: !displayMode,
		size,
		exportValue: (value: CprCol) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<CprCol>()),
		renderColumnActionsMenuItems: () =>
			CprColDataGridColumnHeaderActions({ cprCol }),
		getHeader: () => renderHeader(),
		filterFn: (row, id, filterValue) => {
			const value: CprCol = row.getValue(id);
			const rawValues = value.getRawValues();
			const extValues = value.getExtValues();
			for (const x of rawValues) {
				try {
					const json = JSON.parse(x);
					if (
						json?.value &&
						json.value.toLowerCase().includes(filterValue.toLowerCase())
					) {
						return true;
					}
				} catch (e) {}
			}

			for (const x of extValues) {
				if (x && x.toLowerCase().includes(filterValue.toLowerCase())) {
					return true;
				}
			}

			return false;
		},
		Edit: ({ cell }) => (
			<CprColDataGridColumnInput
				cprCol={cell.getValue<CprCol>()}
				onUpdate={onUpdate}
			/>
		)
	});
};
