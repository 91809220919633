import { RtxApiRoutes } from 'RtExports/routes';
import {
	AuthorizationRequest,
	AuthorizationResetPasswordRequest,
	AuthorizationResetPasswordResponse,
	AuthorizationResponse,
	AuthorizationSendResetPasswordRequest,
	AuthorizationSendResetPasswordResponse,
	AuthorizationVerifyEmailRequest,
	AuthorizationVerifyEmailResponse,
	TotpAuthorizationRequest,
	UserIndexResponse,
	UserPermissionProfileResponse,
	UserPermissionUpdateRequest,
	UserProfileRequest,
	UserUpdateRequest,
	UserCreateRequest,
	UserProfileResponse,
	UserAuditIndexResponse,
	UserPermissionResponse
} from 'RtModels';
import { LoginAttemptsError } from 'RtUi/state/actions/user/interfaces';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { IRequestInitWithParams } from 'RtUi/utils/http/interfaces';
import { RtError } from 'RtUi/utils/errors/RtError';

export class UserHttp {
	/**
	 * Login a user
	 * @param email
	 * @param password
	 */
	public async login(email: string, password: string) {
		const bodyReq: AuthorizationRequest = { email, password };
		const body = JSON.stringify(bodyReq);

		const response = await HttpRequest.rawFetchWithRoute<AuthorizationResponse>(
			RtxApiRoutes.AuthorizationLogin.Index,
			{ body }
		);
		const loginAttemptsNumStr = HttpRequest.getHeaderValue(
			response.headers,
			'X-Login-Attempts',
			'0'
		);
		const maxLoginAttemptsNumStr = HttpRequest.getHeaderValue(
			response.headers,
			'X-Max-Login-Attempts',
			'3'
		);
		const loginAttempts = Number(loginAttemptsNumStr);
		const maxLoginAttempts = Number(maxLoginAttemptsNumStr);
		const authResponse = await response.json();

		if (response.ok) {
			return authResponse;
		}

		throw new LoginAttemptsError(loginAttempts, maxLoginAttempts);
	}

	/**
	 * Only invoke if user has done a login() action before
	 * @param email
	 * @param totpCode
	 * @param rememberDevice
	 */
	public challengeTfa(
		email: string,
		totpCode: string,
		rememberDevice: boolean
	) {
		const req: TotpAuthorizationRequest = {
			email,
			totpCode,
			rememberDevice
		};
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<AuthorizationResponse>(
			RtxApiRoutes.AuthorizationTotp.Index,
			{ body }
		);
	}

	/**
	 * Verify email given a token from email/somewhere
	 * @param token
	 */
	public verifyEmail(token: string) {
		const req: AuthorizationVerifyEmailRequest = { token };
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<AuthorizationVerifyEmailResponse>(
			RtxApiRoutes.AuthorizationVerifyEmail.Index,
			{ body }
		);
	}

	/**
	 * Admins only! Send password reset email to user
	 * @param email
	 */
	public sendResetPassword(email: string) {
		const req: AuthorizationSendResetPasswordRequest = { email };
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<AuthorizationSendResetPasswordResponse>(
			RtxApiRoutes.AuthorizationResetPassword.Create,
			{ body }
		);
	}

	/**
	 * Admins only! Send password reset email to user
	 * @param token
	 * @param newPassword
	 * @param confirmNewPassword
	 */
	public resetPassword(
		token: string,
		newPassword: string,
		confirmNewPassword: string
	) {
		const req: AuthorizationResetPasswordRequest = {
			token,
			newPassword,
			confirmNewPassword
		};
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<AuthorizationResetPasswordResponse>(
			RtxApiRoutes.AuthorizationResetPassword.Update,
			{ body }
		);
	}

	/**
	 * Get a list of users within partition
	 */
	public getUsers() {
		return HttpRequest.fetchWithRoute<UserIndexResponse[]>(
			RtxApiRoutes.Users.Index
		).then((users) => {
			//Sort users by first name
			return users.sort((u1, u2) => u1.firstName.localeCompare(u2.firstName));
		});
	}

	/**
	 * Get a resource of users within partition
	 */
	public getUsersResource() {
		const userResource = new ArrayResource<UserIndexResponse>('userId');

		userResource.setApiRouteForGetAll(RtxApiRoutes.Users.Index);

		return userResource;
	}

	/**
	 * Get a single user profile
	 * @param userId
	 */
	public getUser(userId: number) {
		const urlParams: UserProfileRequest = { userId };

		return HttpRequest.fetchWithRoute<UserIndexResponse>(
			RtxApiRoutes.Users.Profile,
			{ urlParams }
		);
	}

	/**
	 * Update a user's profile
	 * @param userId
	 * @param updateRequest
	 */
	public updateUser(userId: number, updateRequest: UserUpdateRequest) {
		const body = JSON.stringify(updateRequest);
		const urlParams: UserProfileRequest = { userId };

		return HttpRequest.fetchWithRoute<UserIndexResponse>(
			RtxApiRoutes.Users.Update,
			{
				body,
				urlParams
			}
		).then((result) => {
			HttpResource.showSuccessNotification(RtxApiRoutes.Users.Update.title);
			return result;
		});
	}

	/**
	 * Get a list of permissions for a user
	 * @param userId
	 */
	public getUserPermissions(userId: number) {
		const urlParams: UserProfileRequest = { userId };

		return HttpRequest.fetchWithRoute<UserPermissionProfileResponse>(
			RtxApiRoutes.UserPermissions.Profile,
			{ urlParams }
		);
	}

	/**
	 * Get a list of permissions a user can assign
	 * @param userId
	 */
	public getUserAssignablePermissions(userId: number) {
		const urlParams: UserProfileRequest = { userId };

		return HttpRequest.fetchWithRoute<UserPermissionResponse[]>(
			RtxApiRoutes.UserAssignablePermissions.Index,
			{ urlParams }
		);
	}

	/**
	 * Update a user's permissions
	 * @param userId
	 * @param request
	 */
	public async updateUserPermissions(
		userId: number,
		request: UserPermissionUpdateRequest
	) {
		const urlParams: UserProfileRequest = { userId };
		const body = JSON.stringify(request);
		const init: IRequestInitWithParams = { body, urlParams };

		const defaultRespOrgInRespOrgs =
			request.respOrgIds &&
			request.defaultRespOrgId &&
			request.respOrgIds.includes(request.defaultRespOrgId);

		let defaultRespOrgInEntities = false;
		if (request.entityIds && request.defaultRespOrgId) {
			for (const entity of request.entityIds) {
				if (entity === request.defaultRespOrgId.substring(0, 2)) {
					defaultRespOrgInEntities = true;
				}
			}
		}

		if (!defaultRespOrgInRespOrgs && !defaultRespOrgInEntities) {
			throw new RtError({
				message:
					'Default RespOrg is not in RespOrgs or Entities, please fix this.'
			});
		}

		const data =
			await HttpRequest.fetchWithRoute<UserPermissionProfileResponse>(
				RtxApiRoutes.UserPermissions.Update,
				init
			);

		HttpResource.showSuccessNotification(
			RtxApiRoutes.UserPermissions.Update.title
		);

		return data;
	}

	/**
	 * Force a user to reset their password
	 * @param userId
	 * @param email
	 */
	public forceResetPassword(userId: number, email: string) {
		const urlParams: UserProfileRequest = { userId };
		const bodyParams: AuthorizationSendResetPasswordRequest = { email };
		const body = JSON.stringify(bodyParams);

		return HttpRequest.fetchWithRoute<AuthorizationSendResetPasswordResponse>(
			RtxApiRoutes.UserForceResetPassword.Create,
			{
				body,
				urlParams
			}
		);
	}

	/**
	 * Remove TFA
	 * @param userId
	 * @param email
	 */
	public removeTFA(userId: number) {
		const urlParams: UserProfileRequest = { userId };

		return HttpRequest.fetchWithRoute<UserProfileResponse>(
			RtxApiRoutes.User2FA.Delete,
			{
				urlParams
			}
		);
	}

	/**
	 * Create a user
	 * @param {} createRequest
	 * @returns {Promise<>}
	 */
	public createUser(createRequest: UserCreateRequest) {
		const body = JSON.stringify(createRequest);

		return HttpRequest.fetchWithRoute<UserProfileResponse>(
			RtxApiRoutes.Users.Create,
			{ body }
		);
	}

	/**
	 * Get Users Audit data
	 * @returns {ArrayResource<unknown>}
	 */
	public getUsersAuditResource() {
		const userAuditResource = new ArrayResource<UserAuditIndexResponse>(
			'recordId'
		);

		userAuditResource.setApiRouteForGetAll(RtxApiRoutes.UserAudit.Index);

		return userAuditResource;
	}
}
