import { Rt800ApiRoutes } from 'RtExports/routes';
import { CprLergIndexRequest, CprLergIndexResponse } from 'RtModels';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2';

export class CprLergHttp {
	public async loadCprLerg() {
		if (CprLergCache.HasCacheBeenInitialized()) {
			return;
		}

		const params: CprLergIndexRequest = { pageSize: RtxPageSizes.ExcelMax };
		const cprLergRows = await HttpRequest.fetchWithRoute<
			CprLergIndexResponse[]
		>(Rt800ApiRoutes.CprLerg.Index, { params });

		await CprLergCache.Initialize(cprLergRows);
	}
}
