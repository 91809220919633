import { Box, IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMemo } from 'react';
import {
	RatePlanDefaultIndexRequest,
	RatePlanDefaultIndexResponse,
	RatePlanDefaultProfileResponse
} from 'RtModels';
import {
	getRatePlanDefaultColumns,
	useGetRatePlanDefaults
} from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { ensureFullResults } from 'RtUi/utils/http/AxiosHttpRequest';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtCommonApiRoutes } from 'RtExports/routes';

interface IRatePlanDefaultGridProps {
	ratePlanId: number;
	onEditClick?: (row: RatePlanDefaultIndexResponse) => void;
}

export const RatePlanDefaultDataGrid = ({
	ratePlanId,
	onEditClick
}: IRatePlanDefaultGridProps) => {
	const resourceParams: RatePlanDefaultIndexRequest = {
		ratePlanId: ratePlanId
	};
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetRatePlanDefaults(ensureFullResults(resourceParams));
	const columns = useMemo(() => getRatePlanDefaultColumns(), []);

	const handleRatePlanDefaultProfile = (row: RatePlanDefaultIndexResponse) => {
		if (onEditClick) {
			onEditClick(row);
		}
	};

	const handleRatePlanDefaultDelete = async (
		row: RatePlanDefaultIndexResponse
	) => {
		const confirm = await Confirmation.createDelete(
			`Default Rate Plan Id: ${row.ratePlanDefaultId}`
		);
		if (!confirm) {
			return;
		}

		const deleteParams = {
			ratePlanId: ratePlanId,
			ratePlanDefaultId: row.ratePlanDefaultId
		};

		const result = HttpRequest.fetchWithRoute<RatePlanDefaultProfileResponse>(
			RtCommonApiRoutes.RatePlanDefaults.Delete,
			{ urlParams: deleteParams }
		);
		refetch();

		return result;
	};

	const inlineActions = (row: RatePlanDefaultIndexResponse) => (
		<Box sx={{ display: 'flex', justifyContent: 'left' }}>
			<Tooltip title="View Rate Plan Default" arrow placement="right">
				<IconButton
					type="button"
					color="success"
					size="small"
					onClick={(e) => {
						handleRatePlanDefaultProfile(row);
						e.stopPropagation();
					}}
					sx={{
						padding: 0
					}}
				>
					<SearchIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Delete Rate Plan Default" arrow placement="right">
				<IconButton
					type="button"
					color="error"
					size="small"
					onClick={(e) => {
						handleRatePlanDefaultDelete(row);
						e.stopPropagation();
					}}
					sx={{
						padding: 0
					}}
				>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</Box>
	);

	return (
		<DataGrid<RatePlanDefaultIndexResponse>
			data={data}
			columns={columns}
			totalRows={data?.length}
			loading={isLoading}
			inlineActions={inlineActions}
			positionActionsColumn="first"
			//router={RatePlanDefaultRouter}
			pageName={'ratePlan_defaults'}
		/>
	);
};
