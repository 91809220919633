/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RtxRoutingRegion {
    UnitedStates = '0',
    Canada = '2',
    Alaska = '3',
    Caribbean = '4',
    Hawaii = '6',
    SpecialServices = '9',
    PersonalServices = 'B',
    Pacific = 'C',
}