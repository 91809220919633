import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	CdrSearchIndexResponse,
	FileStreamProfileResponse,
	FileTypeProfileRequest,
	FileTypeProfileResponse
} from 'RtModels';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { Aside } from 'RtUi/components/ui/Aside';
import { Loading } from 'RtUi/components/ui/Loading';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { flattenObject } from 'RtUi/utils/object';

interface ICdrDetailsAsideProps {
	show: boolean;
	toggle: () => void;
	cdrSearchItem: CdrSearchIndexResponse;
}

interface ICdrDetailsAsideState {
	isLoading: boolean;
	cdrSearchHeaders?: string[];
	cdrSearchItemParsed?: string[];
	fileStream?: FileStreamProfileResponse;
	error?: any;
	recordToCopy?: string;
	btnClass?: string;
}

export class CdrDetailsAside extends React.Component<
	ICdrDetailsAsideProps,
	ICdrDetailsAsideState
> {
	private static getFileTypes(fileStreamId: number) {
		const urlParams: FileTypeProfileRequest = { fileStreamId };
		return HttpRequest.fetchWithRoute<FileTypeProfileResponse>(
			RtVueApiRoutes.FileTypes.Profile,
			{ urlParams }
		);
	}

	public state: ICdrDetailsAsideState = {
		isLoading: true,
		error: undefined,
		btnClass: 'btn btn-primary'
	};

	public componentDidMount() {
		this.init();
	}

	public componentDidUpdate(prevProps: ICdrDetailsAsideProps) {
		if (!isEqual(prevProps.cdrSearchItem.vuid, this.props.cdrSearchItem.vuid)) {
			this.init();
		}
	}

	public async init() {
		this.setState({
			isLoading: true,
			cdrSearchItemParsed: undefined,
			cdrSearchHeaders: undefined
		});

		const fileStreamId = this.props.cdrSearchItem.file_stream_id;
		const ConnectionFileStreamResource = new FileStreamResource();

		let cdrSearchItemParsed: string[];
		let cdrSearchHeaders;

		try {
			const [fileTypesResponse, fileStreamResponse] = await Promise.all([
				CdrDetailsAside.getFileTypes(
					Number(this.props.cdrSearchItem.file_stream_id)
				),
				ConnectionFileStreamResource.get(fileStreamId)
			]);

			if (fileTypesResponse.formatType.toLowerCase() === 'xml') {
				const cdrRecordParsed = flattenObject(
					JSON.parse(this.props.cdrSearchItem.cdr_record.replace(/'/g, '"'))
				);
				cdrSearchItemParsed = Object.values(cdrRecordParsed);
				cdrSearchHeaders = Object.keys(cdrRecordParsed);
			} else {
				cdrSearchItemParsed = this.props.cdrSearchItem.cdr_record.split(
					fileTypesResponse.delimiter
				);
				cdrSearchHeaders = fileTypesResponse.cdrFields;
			}

			this.setState({
				isLoading: false,
				cdrSearchItemParsed,
				cdrSearchHeaders,
				fileStream: fileStreamResponse
			});
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isLoading: false });
		}

		const headerFieldCount: number[] = [];
		const headerFields: string[] = [];
		const dataFields: string[] = [];
		let recordToCopy = '';
		if (this.state.cdrSearchHeaders) {
			this.state.cdrSearchHeaders.map((cdrField: string, idx: number) => {
				headerFieldCount.push(idx + 1);
				headerFields.push(cdrField);
				dataFields.push(this.state.cdrSearchItemParsed![idx]);
			});
			recordToCopy = `"${headerFieldCount.join('","')}"\n"${headerFields.join(
				'","'
			)}"\n"${dataFields.join('","')}"`;
			this.setState({ recordToCopy });
		}
	}

	public render() {
		return (
			<Aside isOpen={this.props.show} disabledBody className="overflow-hidden">
				<Aside.Header
					header={this.props.cdrSearchItem.vuid}
					onClose={() => this.props.toggle()}
				/>
				{this.state.isLoading && (
					<div className="mx-auto p-5">
						<Loading internal message="Loading data" />
					</div>
				)}
				{!this.state.isLoading && this.state.cdrSearchHeaders && (
					<Modal.Body className="stripped-list overflow-auto pb-5 vh-100">
						{this.state.recordToCopy && (
							<>
								<br />
								<div className="text-end mb-3">
									<button
										style={{ fontSize: '0.75rem', padding: '0.2rem 0.4rem' }}
										className={this.state.btnClass}
										onClick={() => {
											navigator.clipboard
												.writeText(this.state.recordToCopy || '')
												.then(() => {
													this.setState({ btnClass: 'btn btn-success' });
												});
										}}
									>
										Copy record to Clipboard
									</button>
								</div>
							</>
						)}
						<dl className="row">
							<dt className="col-6">
								<b>File Stream</b>
							</dt>
							<dd className="col-6">{this.state.fileStream?.description}</dd>
						</dl>
						{this.state.cdrSearchHeaders.map(
							(cdrField: string, idx: number) => (
								<dl className="row" key={`${cdrField}_${idx}`}>
									<dt className="col-6">
										<b>{cdrField}</b>
									</dt>
									<dd className="col-6">
										{this.state.cdrSearchItemParsed![idx]}
									</dd>
								</dl>
							)
						)}
					</Modal.Body>
				)}
				<FormErrors error={this.state.error} />
			</Aside>
		);
	}
}
