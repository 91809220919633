/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobUpdateFieldsRequest = {
    properties: {
        priorityId: {
            type: 'number',
        },
        label: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        scheduledTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};