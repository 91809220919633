import { Form } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

export interface SelectValue {
	label: string;
	value: string;
}

interface CreatableMultiSelectProps {
	value: SelectValue[] | [];
	onChange: (data: MultiValue<SelectValue>) => void;
	label: string;
	disabled: boolean;
}

const CreatableMultiSelect = (
	props: CreatableMultiSelectProps
): JSX.Element => {
	const { onChange, label, disabled } = props;
	let { value } = props;

	if (value?.length && !value[0]?.value) {
		value = (value as string[]).map((v) => {
			return { value: v, label: v };
		});

		onChange(value);
	}

	return (
		<Form.Group className="mb-3">
			<Form.Label>{label}</Form.Label>
			<CreatableSelect
				onInputChange={(inputValue) => inputValue.toUpperCase()}
				formatCreateLabel={(inputValue) => {
					const isValid = /^[a-zA-Z0-9]{1,10}$/.test(inputValue);
					return isValid ? `Add Label "${inputValue}"` : 'Invalid input';
				}}
				isValidNewOption={(inputValue) =>
					/^[a-zA-Z0-9]{1,10}$/.test(inputValue)
				}
				isDisabled={disabled}
				placeholder=""
				isMulti
				onChange={onChange}
				value={value}
			/>
		</Form.Group>
	);
};

export default CreatableMultiSelect;
