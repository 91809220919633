import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationCreateRequest,
	GuardianReconciliationIndexResponse,
	GuardianReconciliationProfileResponse,
	GuardianReconciliationProfileUrlRequest,
	GuardianReconciliationUpdateRequest,
	ReconciliationType
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class GuardianReconciliationResource extends HttpResource<
	GuardianReconciliationIndexResponse,
	GuardianReconciliationProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliations);
	}

	public create(request: GuardianReconciliationCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianReconciliationProfileResponse>(
			RtVueApiRoutes.GuardianReconciliations.Create,
			{
				body
			}
		);
	}

	public update(
		guardianReconciliationId: number,
		request: GuardianReconciliationUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianReconciliationProfileUrlRequest = {
			guardianReconciliationId
		};

		return this.fetchWithRoute<GuardianReconciliationProfileResponse>(
			RtVueApiRoutes.GuardianReconciliations.Update,
			{
				body,
				urlParams
			}
		);
	}

	public createNewProfile(): GuardianReconciliationProfileResponse {
		return {
			guardianReconciliationId: -1,
			guardianReconciliationControlId: null,
			reconciliationType: ReconciliationType.Charge,
			summary: '',
			summaryOnly: 1,
			mappingId: -1,
			reconciliationStatus: null,
			subscriptionId: -1,
			serviceId: -1,
			carrierBanNumber: null,
			carrierInvoiceId: null,
			startTs: new Date(),
			endTs: new Date(),
			carrierInvoiceCharges: null,
			rtTgtCharges: null,
			rtSrcCharges: null,
			disputedAmount: null,
			approvedAmount: null,
			executionStatus: 0,
			lastRunStartTs: null,
			lastRunEndTs: null,
			createdBy: -1,
			createdTs: null,
			updatedBy: -1,
			updateTs: null,
			followupTs: null,
			receivedAmount: null
		};
	}
}
