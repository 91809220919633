import * as moment from 'moment';
import { FirebaseApp } from 'firebase/app';
import {
	ref as firebaseGetRef,
	getDatabase as firebaseGetDb,
	DataSnapshot as firebaseDbDataSnapshot,
	onValue as firebaseDbOnValue,
	off as firebaseDbOff
} from 'firebase/database';
import { User as FirebaseAuthUser } from 'firebase/auth';
import { FirebaseMessage, ProductId, FirebaseMessageType } from 'RtModels';
import { differenceBy, pullAllBy, unionBy } from 'lodash-es';

export interface IFirebaseUIMessage<ContentType = any>
	extends Omit<FirebaseMessage, 'content' | 'createTs' | 'expireTs'> {
	uuid: string;
	databasePath: string;
	userHasRead: boolean;
	createTs: moment.Moment;
	expireTs: moment.Moment;
	content: ContentType;
}

export interface IFirebaseMessages {
	[uuid: string]: FirebaseMessage;
}

export type FireBaseUserMessageListener = (
	messages: IFirebaseUIMessage[],
	error?: any
) => void;

declare const FIREBASE_DB_URL: string;

export abstract class BaseFireBase {
	protected firebaseConfig = {
		/* cspell:disable-next-line */
		apiKey: 'AIzaSyAHgfyf_n2p8IXEGEuu9dUUt5b5kvGahkI',
		/* cspell:disable-next-line */
		authDomain: 'routetrust-test.firebaseapp.com',
		databaseURL: FIREBASE_DB_URL,
		projectId: 'routetrust-test',
		/* cspell:disable-next-line */
		storageBucket: 'routetrust-test.appspot.com',
		messagingSenderId: '423236254145',
		appId: '1:423236254145:web:9d22446db99fb78c87d740'
	};

	//@ts-expect-error
	protected firebaseApp: FirebaseApp;
	protected partitionUserId: number | null = null;
	protected fireToken: string | null = null;
	protected currentUser: FirebaseAuthUser | null = null;
	protected partitionId: number | null = null;
	protected productIds: ProductId[] = [];
	protected isInitializedDefer!: Promise<FirebaseAuthUser | null>;
	protected isInitializedResolver!: (user: FirebaseAuthUser) => void;
	protected databasePaths = [];

	/**
	 * Listen for changes in a user's messages
	 * @param listener
	 * @returns a function to remove the listener
	 */
	public onMessages(
		listener: FireBaseUserMessageListener,
		messageTypes?: FirebaseMessageType[],
		filter: boolean = true
	) {
		const removeListenerFns: Array<() => void> = [];
		let aggregateMessages: IFirebaseUIMessage[] = [];
		const filterMessages = (messages: IFirebaseUIMessage[]) => {
			const now = moment.utc();

			//remove expired messages
			messages = messages.filter((message) => message.expireTs.isAfter(now));

			//remove messageTypes that don't match
			if (typeof messageTypes !== 'undefined') {
				messages = messages.filter((message) =>
					messageTypes.includes(message.messageType)
				);
			}

			if (messages.length <= 0) {
				return messages;
			}

			//Get closest expiry in unix
			const closestExpiryUnixInMs = messages.reduce((prev, message) => {
				const messageExpiryUnixInMs = message.expireTs.valueOf();

				return Math.min(messageExpiryUnixInMs, prev);
			}, messages[0].expireTs.valueOf());

			const expiryDeltaInMs = closestExpiryUnixInMs - now.valueOf();
			const expiryDuration = moment.duration(expiryDeltaInMs, 'milliseconds');

			// If expiry is within a day (more than a day causes setTimeout errors),
			// setTimeout to fire aggregateListener() so it can filter expired
			// messages once again
			if (expiryDuration.asDays() <= 1) {
				setTimeout(() => aggregateListener(messages), expiryDeltaInMs);
			}

			return messages;
		};

		const aggregateListener: FireBaseUserMessageListener = (
			messages,
			error
		) => {
			if (messages.length <= 0) {
				return;
			}

			const databasePath = messages[0].databasePath;
			const messageIdentity = (message: IFirebaseUIMessage) => message.uuid;

			if (filter) {
				messages = filterMessages(messages);
			}
			const currentMessagesWithCurrentDbPath = aggregateMessages.filter(
				(am) => am.databasePath === databasePath
			);

			//Check to see if Firebase messages were removed from Firebase Console
			//If so, remove from aggregates
			if (currentMessagesWithCurrentDbPath.length > 0) {
				const removedMessages = differenceBy(
					currentMessagesWithCurrentDbPath,
					messages,
					messageIdentity
				);

				if (removedMessages.length > 0) {
					//Removes removedMessages from aggregate
					pullAllBy(aggregateMessages, removedMessages, messageIdentity);
				}
			}
			//Combine to aggregate messages
			aggregateMessages = unionBy(messages, aggregateMessages, messageIdentity);

			//Sort desc based on created timestamp
			aggregateMessages.sort(
				(fm1, fm2) => fm2.createTs.unix() - fm1.createTs.unix()
			);

			listener(aggregateMessages, error);
		};

		for (const databasePath of this.getDataBasePaths()) {
			const removeListenerFn = this.onFirebaseMessage(
				databasePath,
				aggregateListener
			);

			removeListenerFns.push(removeListenerFn);
		}

		return () => {
			for (const removeListenerFn of removeListenerFns) {
				removeListenerFn();
			}
		};
	}

	protected abstract getDataBasePaths(): string[];

	/**
	 * Listen for changes in Firebase messages
	 * @param databasePath
	 * @param listener
	 * @returns a function to remove the listener
	 */
	public onFirebaseMessage(
		databasePath: string,
		listener: FireBaseUserMessageListener
	) {
		let isListening = false;
		let hasConsumerTurnedOffListener = false;
		const onError = (err: any) => {
			console.error(err);
			listener([], err);
		};
		const onUpdate = (snapshot: firebaseDbDataSnapshot) => {
			const firebaseMessages: IFirebaseMessages = snapshot.val() ?? {};
			const messages: IFirebaseUIMessage[] = [];

			for (const firebaseMessageKey in firebaseMessages) {
				if (firebaseMessageKey in firebaseMessages) {
					const firebaseUiMessage = this.createFirebaseUIMessage(
						firebaseMessageKey,
						databasePath,
						firebaseMessages[firebaseMessageKey]
					);

					messages.push(firebaseUiMessage);
				}
			}

			listener(messages);
		};

		//Init database messages when initialized (logged in)
		this.isInitializedDefer.then(() => {
			if (!hasConsumerTurnedOffListener) {
				const database = firebaseGetDb(this.firebaseApp);
				const databaseRef = firebaseGetRef(database, databasePath);

				firebaseDbOnValue(databaseRef, onUpdate, onError);
				isListening = true;
			}
		});

		return () => {
			if (isListening) {
				const database = firebaseGetDb(this.firebaseApp);
				const databaseRef = firebaseGetRef(database, databasePath);

				firebaseDbOff(databaseRef, 'value', onUpdate);
				isListening = false;
			}

			hasConsumerTurnedOffListener = true;
		};
	}

	/**
	 * Create IFirebaseUIMessage from FirebaseMessage
	 * @param firebaseMessage
	 */
	protected createFirebaseUIMessage(
		uuid: string,
		databasePath: string,
		firebaseMessage: FirebaseMessage
	) {
		const createTs = moment.unix(firebaseMessage.createTs);
		const expireTs = moment.unix(firebaseMessage.expireTs);

		const message: IFirebaseUIMessage = {
			uuid,
			databasePath,
			createTs,
			expireTs,
			productId: firebaseMessage.productId ?? null,
			routeType: firebaseMessage.routeType ?? null,
			routeTypeId: firebaseMessage.routeTypeId ?? null,
			//Firebase cannot handle undefined data
			additionalData: firebaseMessage.additionalData ?? null,
			messageType: firebaseMessage.messageType,
			userHasRead: firebaseMessage.userHasRead,
			severity: firebaseMessage.severity,
			summary: firebaseMessage.summary,
			content: firebaseMessage.content,
			label: firebaseMessage.label
		};

		return message;
	}
	/**
	 * Serialize FirebaseUiMessage to FirebaseMessage
	 * @param message
	 */
	protected serializeFirebaseUiMessage(message: IFirebaseUIMessage) {
		const { createTs: createTsMoment, expireTs: expireTsMoment } = message;

		const createTs = createTsMoment.unix();
		const expireTs = expireTsMoment.unix();

		const firebaseMessage: FirebaseMessage = {
			messageType: message.messageType,
			routeType: message.routeType || null,
			routeTypeId: message.routeTypeId || null,
			productId: message.productId,
			summary: message.summary,
			content: message.content,
			label: message.label,
			userHasRead: message.userHasRead,
			severity: message.severity,
			additionalData: message.additionalData ?? null,
			createTs,
			expireTs
		};

		return firebaseMessage;
	}
}
