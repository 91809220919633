import { SystemNotificationForm } from 'RtUi/app/rtAdmin/SystemNotifications/lib/forms/SystemNotificationForm';
import { Aside } from 'RtUi/components/ui/Aside';
import { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useToggle } from 'react-use';
import { useGetSystemNotifications } from 'RtUi/app/rtAdmin/SystemNotifications/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSystemNotificationColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/systemNotifications';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { FirebaseMessage } from 'RtModels';
import { Box, IconButton, Tooltip as ToolTip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { Confirmation } from 'RtUi/components/form/Confirmation';

export const SystemNotificationsGrid = () => {
	const [selectedRecord, setSelectedRecord] = useState<
		FirebaseMessage | undefined
	>();

	const columns = useMemo(() => getSystemNotificationColumns(), []);
	const [isAsideOpen, toggleAsideOpen] = useToggle(false);

	const { data = [], isLoading, refetch } = useGetSystemNotifications();
	const closeAside = () => {
		toggleAsideOpen();
		setSelectedRecord(undefined);
		refetch();
	};

	const onRowClick = (row: FirebaseMessage) => {
		setSelectedRecord(row);
		if (!selectedRecord) {
			toggleAsideOpen();
		}
	};

	const deleteMessage = async (row: FirebaseMessage) => {
		if (row && row.referenceId) {
			const confirm = await Confirmation.createDelete(
				`Notification Message with label: '${row.label}'`
			);
			if (!confirm) {
				return;
			}
			try {
				await HttpRequest.fetchWithRoute(RtAdmApiRoutes.Firebase.Delete, {
					urlParams: {
						firebaseMessageId: encodeURIComponent(row.referenceId)
					}
				});
				await refetch();
			} catch (error) {
				console.error('Error deleting message:', error);
			}
		}
	};

	const inlineActions = (row: FirebaseMessage) => (
		<Box sx={{ display: 'flex', justifyContent: 'left' }}>
			<ToolTip title="Delete Notification" arrow placement="right">
				<IconButton
					type="button"
					color="error"
					size="small"
					onClick={(e) => {
						deleteMessage(row);
						e.stopPropagation();
					}}
					sx={{
						padding: 0,
						marginLeft: '5px'
					}}
				>
					<Delete
						color="error"
						sx={{
							fontSize: 16
						}}
					/>
				</IconButton>
			</ToolTip>
		</Box>
	);

	return (
		<>
			<DataGrid<FirebaseMessage>
				defaultSorting={[
					{
						id: 'expireTs',
						desc: true
					}
				]}
				data={data}
				columns={columns}
				pageName={'rtAdmin-systemNotification'}
				onRowClick={onRowClick}
				loading={isLoading}
				inlineActions={inlineActions}
				positionActionsColumn="first"
				rowThemeColor={(record) =>
					record.referenceId === selectedRecord?.referenceId
						? RowThemeColor.WARNING
						: undefined
				}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id="addNotificationBtn-tooltip" {...props}>
									Add Notification
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={toggleAsideOpen}
									variant="white"
									id="addNotificationBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<Aside isOpen={isAsideOpen} disabledBody>
				{isAsideOpen && (
					<>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Notification"
							onClose={() => closeAside()}
						/>
						<SystemNotificationForm
							profile={selectedRecord}
							onCancel={closeAside}
							onSuccess={closeAside}
						/>
					</>
				)}
			</Aside>
		</>
	);
};
