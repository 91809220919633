import { noop } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	BlockTypeIndexResponse,
	CallflowService,
	ReleaseCodeIndexResponse,
	RoutePlanIndexResponse,
	ScenarioIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionRoutingProfileResponse,
	SubscriptionRoutingUpdateRequest,
	TrackingGroupIndexResponse
} from 'RtModels';
import { SectionHeaders } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionEditor';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { useGetSubscriptionRouting } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxPhoneNumberInput } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxPhoneNumberInput';
import { RtxBlockTypeSelect } from 'RtUi/components/rtx/inputs/Select/instances/BlockType/RtxBlockTypeSelect';
import { RtxReleaseCodeSelect } from 'RtUi/components/rtx/inputs/Select/instances/ReleaseCode/RtxReleaseCodeSelect';
import { RtxRoutePlanSelect } from 'RtUi/components/rtx/inputs/Select/instances/RoutePlan/RtxRoutePlanSelect';
import { RtxScenarioSelect } from 'RtUi/components/rtx/inputs/Select/instances/Scenario/RtxScenarioSelect';
import { RtxCustomerOriginationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxCustomerOriginationSubscriptionSelect';
import { RtxVendorTerminationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxVendorTerminationSubscriptionSelect';
import { RtxTrackingGroupSelect } from 'RtUi/components/rtx/inputs/Select/instances/TrackingGroup/RtxTrackingGroupSelect';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISubscriptionRoutingFormProps {
	subscriptionId: number;
	serviceId: number;
	onSuccess?: (profile: SubscriptionRoutingProfileResponse) => void;
	onCancel?: () => void;
}

export const SubscriptionRoutingForm = ({
	subscriptionId,
	serviceId,
	onSuccess = noop
}: ISubscriptionRoutingFormProps) => {
	const [blockType, setBlockType] = useState<BlockTypeIndexResponse>();
	const [routePlan, setRoutePlan] = useState<RoutePlanIndexResponse>();
	const [defaultSubscription, setDefaultSubscription] =
		useState<SubscriptionIndexResponse>();
	const [sourceSubscription, setSourceSubscription] =
		useState<SubscriptionIndexResponse>();
	const [trackingGroup, setTrackingGroup] =
		useState<TrackingGroupIndexResponse>();
	const [defaultIsdnRelease, setDefaultIsdnRelease] =
		useState<ReleaseCodeIndexResponse>();
	const [defaultScenario, setDefaultScenario] =
		useState<ScenarioIndexResponse>();
	const [vendorSubscription, setVendorSubscription] =
		useState<SubscriptionIndexResponse>();
	const { data, isFetching: isLoading } = useGetSubscriptionRouting({
		subscriptionId
	});
	const [error, setError] = useState<RtError>();
	const [displayMode, setDisplayMode] = useState<boolean>(true);

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const onSubmit = async (data: SubscriptionRoutingUpdateRequest) => {
		const rs = new SubscriptionResource();
		setError(undefined);

		try {
			const profile = await rs.updateSubscriptionRouting(subscriptionId, data);
			setDisplayMode(true);
			onSuccess(profile);
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<RtxForm<SubscriptionRoutingUpdateRequest>
			defaultValues={data}
			displayMode={displayMode}
			onSubmit={onSubmit}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onEdit={() => setDisplayMode(false)}
			onCancel={() => setDisplayMode(true)}
		>
			{({ control }) => (
				<Row>
					<Col>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>Routing</h6>
							</legend>
							<Controller
								control={control}
								name="blockTypeId"
								render={({ field: { onChange, value } }) => (
									<RtxBlockTypeSelect
										isClearable
										displayMode={displayMode}
										className="mb-3"
										onChange={(blockType) => {
											setBlockType(blockType);
											onChange(blockType?.blockTypeId);
										}}
										value={blockType}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
							<Controller
								control={control}
								name="routePlanId"
								render={({ field: { onChange, value } }) => (
									<RtxRoutePlanSelect
										name="routePlanId"
										displayMode={displayMode}
										onChange={(routePlan: RoutePlanIndexResponse) => {
											setRoutePlan(routePlan);
											onChange(routePlan.routePlanId);
										}}
										required={[
											CallflowService.OutboundCustomerTermination,
											CallflowService.InboundCustomerIdentification,
											CallflowService.InboundCustomerIdentificationClone,
											CallflowService.OutboundCustomerIdentification
										].includes(serviceId)}
										value={routePlan}
										initialOptionId={value ?? undefined}
										allowedRoutePlanIds={[3, 6]}
									/>
								)}
							/>
						</fieldset>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>{SectionHeaders.origination}</h6>
							</legend>
							<Controller
								control={control}
								name="defaultSubscriptionId"
								render={({ field: { onChange, value } }) => (
									<RtxCustomerOriginationSubscriptionSelect
										label="Default Subscription"
										className="mb-3"
										isActive={1}
										displayMode={displayMode}
										isDisabled={
											serviceId !== CallflowService.InboundVendorOrigination
										}
										onChange={(defaultSubscription) => {
											setDefaultSubscription(defaultSubscription);
											onChange(defaultSubscription.subscriptionId);
										}}
										required={
											CallflowService.InboundVendorOrigination === serviceId
										}
										value={defaultSubscription}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
							<Controller
								control={control}
								name="sourceSubscriptionId"
								render={({ field: { onChange, value } }) => (
									<RtxCustomerOriginationSubscriptionSelect
										label="Clone Subscription"
										isActive={1}
										className="mb-3"
										displayMode={displayMode}
										isDisabled={
											serviceId !==
											CallflowService.InboundCustomerIdentificationClone
										}
										required={
											serviceId ===
											CallflowService.InboundCustomerIdentificationClone
										}
										onChange={(sourceSubscription) => {
											setSourceSubscription(sourceSubscription);
											onChange(sourceSubscription.subscriptionId);
										}}
										value={sourceSubscription}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
							<Controller
								control={control}
								name="trackingGroupId"
								render={({ field: { onChange, value } }) => (
									<RtxTrackingGroupSelect
										label="Tracking Group"
										className="mb-3"
										displayMode={displayMode}
										isDisabled={
											serviceId !==
											CallflowService.OutboundCustomerIdentification
										}
										onChange={(trackingGroup) => {
											setTrackingGroup(trackingGroup);
											onChange(trackingGroup.trackingGroupId);
										}}
										value={trackingGroup}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
							<Controller
								control={control}
								name="defaultIsdnReleaseId"
								render={({ field: { onChange, value } }) => (
									<RtxReleaseCodeSelect
										className="mb-3"
										allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
										displayMode={displayMode}
										isDisabled={
											serviceId !== CallflowService.InboundVendorOrigination
										}
										label="Default ISDN Rel."
										valueKey="isdnReleaseId"
										value={defaultIsdnRelease}
										initialOptionId={value ?? undefined}
										onChange={(defaultIsdnReleaseId) => {
											setDefaultIsdnRelease(defaultIsdnReleaseId);
											onChange(defaultIsdnReleaseId.isdnReleaseId);
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name="defaultIsdnReleaseMs"
								render={({ field: { onChange, value } }) => (
									<RtxNumberInput
										min={0}
										max={18000}
										className="mb-3"
										label="Default ISDN Rel. ms"
										displayMode={displayMode}
										disabled={
											CallflowService.InboundVendorOrigination !== serviceId
										}
										value={value}
										onChange={(value) => {
											onChange(Number(value));
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name="hasServiceNumbers"
								render={({ field: { onChange, value } }) => (
									<RtxBooleanInput
										className="mb-3"
										label="Service Number Override"
										displayMode={displayMode}
										disabled={
											serviceId !== CallflowService.InboundVendorOrigination
										}
										value={value}
										onChange={(value) => onChange(value)}
									/>
								)}
							/>
							<Controller
								control={control}
								name="defaultScenarioId"
								render={({ field: { onChange, value } }) => (
									<RtxScenarioSelect
										label="Default Scenario"
										displayMode={displayMode}
										className="mb-3"
										isDisabled={
											![
												CallflowService.InboundCustomerIdentification,
												CallflowService.InboundCustomerIdentificationClone,
												CallflowService.OutboundCustomerIdentification
											].includes(serviceId)
										}
										isClearable={[
											CallflowService.OutboundCustomerIdentification
										].includes(serviceId)}
										onChange={(defaultScenario) => {
											onChange(defaultScenario?.scenarioId);
											setDefaultScenario(defaultScenario);
										}}
										value={defaultScenario}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
							<Controller
								control={control}
								name="translatedNumberStd"
								render={({ field: { onChange, value } }) => (
									<RtxPhoneNumberInput
										className="mb-3"
										label="Translated Number"
										displayMode={displayMode}
										disabled={
											CallflowService.OutboundCustomerIdentification !==
											serviceId
										}
										value={value ?? undefined}
										onChange={(value) => onChange(value)}
									/>
								)}
							/>
						</fieldset>
					</Col>
					<Col>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>{SectionHeaders.directVendorTermination}</h6>
							</legend>
							<Controller
								control={control}
								name="directSubscriptionId"
								render={({ field: { onChange, value } }) => (
									<RtxVendorTerminationSubscriptionSelect
										label="Direct Routing"
										isActive={1}
										displayMode={displayMode}
										isDisabled={
											![
												CallflowService.OutboundCustomerTermination,
												CallflowService.OutboundCustomerIdentification
											].includes(serviceId)
										}
										isClearable
										onChange={(vendorSubscription) => {
											setVendorSubscription(vendorSubscription);
											onChange(vendorSubscription?.subscriptionId);
										}}
										value={vendorSubscription}
										initialOptionId={value ?? undefined}
									/>
								)}
							/>
						</fieldset>
					</Col>
				</Row>
			)}
		</RtxForm>
	);
};
