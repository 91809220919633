import moment from 'moment';
import { useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Permissions } from 'RtExports/routes';
import {
	NumberGroupIndexResponse,
	RespOrgIndexResponse,
	RoutingCacheTypes,
	TaskUpdateRequest
} from 'RtModels';
import { NumberGroupSelect } from 'RtUi/app/rt800/NumberGroups/lib/controls/NumberGroupSelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TaskResource } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import {
	getIsBeforeScheduled,
	getIsNotCompleteAndAfterScheduled,
	getReferenceProfile,
	renderSummary
} from 'RtUi/app/rt800/Tasks/lib/utils';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/Task.profile';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { LcrTaskResultRouter } from 'RtUi/app/rtLco/TaskResults/LcrTaskResult.router';
import { LcrTaskRouter } from 'RtUi/app/rtLco/Tasks/LcrTask.router';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PriorityRadioButtons } from 'RtUi/components/form/PriorityRadioButtons';
import { FormReturnProps, RtxForm } from 'RtUi/components/rtx/form';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { UserActions } from 'RtUi/state/actions/user';
import { RtError } from 'RtUi/utils/errors/RtError';
import { parseJson } from 'RtUi/utils/json';
import { timestampToReadable } from 'RtUi/utils/maps';

interface ITaskProfileProps {
	taskProfile: ITaskProfile;
	onUpdateTask: (task: ITaskProfile) => void;
}

export const TaskProfile = ({
	taskProfile,
	onUpdateTask
}: ITaskProfileProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(false);
	const [updateErrorsRtError, setUpdateErrorsRtError] = useState<RtError>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [numberGroup, setNumberGroup] = useState<NumberGroupIndexResponse>();
	const isCompleted = Boolean(taskProfile.completedAt);
	const formRef = useRef<FormReturnProps<ITaskProfile>>(null);

	const updateTask = async (data: ITaskProfile) => {
		const taskResource = new TaskResource();
		const updateRequest: TaskUpdateRequest = {
			scheduledAt: data.scheduledAt,
			priority: data.priority,
			processedAt: data.processedAt,
			completedAt: data.completedAt
		};

		setUpdateErrorsRtError(undefined);

		try {
			const task = await taskResource.update(data.taskId, updateRequest);
			setDisplayMode(true);
			onUpdateTask(task as any);
		} catch (updateErrors: any) {
			setUpdateErrorsRtError(new RtError(updateErrors));
		}
	};

	const renderParamsProfile = () => {
		const params = parseJson(
			taskProfile.parameters ? taskProfile.parameters : '{}'
		);
		const isLcrSource = params.sourceTypeId === RoutingCacheTypes.LcrGenerator;

		if (
			isLcrSource &&
			typeof params.sourceKey !== 'undefined' &&
			typeof params.sourceKeyParent !== 'undefined'
		) {
			return (
				<>
					<Col md={6}>
						<Form.Group className="mb-3">
							<Form.Label>LCO Task</Form.Label>
							<div className="form-control-plaintext">
								<Link
									to={LcrTaskRouter.getProfileRoute(params.sourceKeyParent)}
								>
									{params.sourceKeyParent}
								</Link>
							</div>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group className="mb-3">
							<Form.Label>LCO Task Result</Form.Label>
							<div className="form-control-plaintext">
								<Link
									to={LcrTaskResultRouter.getProfileRouteWithIds(
										params.sourceKeyParent,
										params.sourceKey
									)}
								>
									{params.sourceKey}
								</Link>
							</div>
						</Form.Group>
					</Col>
				</>
			);
		}
		const isRtxAdmin = UserActions.has(Permissions.RtxAdministrator);
		if (isRtxAdmin) {
			return (
				<Card className="d-flex flex-row w-100">
					<Card.Body className="bg-light">
						<h5 style={{ marginBottom: '10px' }}>
							Task Parameters (rtxAdmin only)
						</h5>
						{Object.entries(params).map(([key, value]) => (
							<div key={key}>
								<strong>{key}</strong>: {String(value)}
							</div>
						))}
					</Card.Body>
				</Card>
			);
		}

		return null;
	};

	const renderCancelButton = (render: boolean) => {
		const cancelJob = async () => {
			const confirm = await Confirmation.create(
				<span>
					Are you sure you want to cancel this job?
					<br />
					<br />
					<ul>
						<li>
							This will mark the task as completed and no processing will
							happen.
						</li>
					</ul>
				</span>
			);

			if (!confirm) {
				return;
			}

			const nowTs = new Date();
			updateTask({
				...taskProfile,
				completedAt: nowTs,
				processedAt: nowTs,
				scheduledAt: nowTs
			});
		};

		const hasPermissions = UserActions.has(
			Permissions.TollFreeOperator,
			Permissions.TollFreeRespOrgOperator,
			Permissions.TollFreeRespOrgManager
		);

		if (!render || !hasPermissions) {
			return '';
		}

		return (
			<Button style={{ float: 'right' }} variant="warning" onClick={cancelJob}>
				<i className="fas fa-fw fa-ban" /> Cancel Job
			</Button>
		);
	};

	const renderStatusCard = () => {
		const isBeforeScheduled = getIsBeforeScheduled(taskProfile);
		const isNotCompleteAndAfterScheduled =
			getIsNotCompleteAndAfterScheduled(taskProfile);
		const internalDisplayMode = isCompleted || displayMode;

		let header = '';
		let headerIcon = '';

		if (isCompleted) {
			header = 'Task Completed';
			headerIcon = 'fas fa-fw fa-check-circle text-success';
		} else if (isNotCompleteAndAfterScheduled) {
			header = 'Task in Progress';
			headerIcon = 'fas fa-fw fa-cog text-info fa-spin';
		} else if (isBeforeScheduled) {
			header = 'Awaiting Scheduled Time';
			headerIcon = 'fas fa-fw fa-clock text-warning';
		}

		return (
			<CollapsibleCard header={header} headerIcon={headerIcon}>
				<RtxForm<ITaskProfile>
					ref={formRef}
					defaultValues={taskProfile}
					onSubmit={updateTask}
					hideButtons={isCompleted || isNotCompleteAndAfterScheduled}
					onCancel={() => formRef.current?.reset()}
					error={
						updateErrorsRtError && {
							name: 'root',
							error: {
								message: updateErrorsRtError.message
							}
						}
					}
				>
					{({ control }) => (
						<>
							<Row
								className="d-flex justify-content-between"
								style={{ marginBottom: '10px' }}
							>
								{renderCancelButton(isBeforeScheduled)}
							</Row>
							<Controller
								control={control}
								name="scheduledAt"
								render={({ field: { onChange, value } }) => (
									<DatetimeFormControl
										label="Scheduled At"
										displayMode={
											isNotCompleteAndAfterScheduled || internalDisplayMode
										}
										disableNowPreset
										required
										onChange={(scheduledAt) => onChange(scheduledAt?.toDate())}
										value={value && moment(value)}
										minDate={moment()}
									/>
								)}
							/>
							<Controller
								control={control}
								name="priority"
								render={({ field: { onChange, value } }) => (
									<PriorityRadioButtons
										displayMode={
											isNotCompleteAndAfterScheduled || internalDisplayMode
										}
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="processedAt"
								render={({ field: { onChange, value } }) => (
									<DatetimeFormControl
										label="Processed At"
										displayMode
										disableNowPreset
										value={value && moment(value)}
										onChange={(processedAt) => onChange(processedAt?.toDate())}
									/>
								)}
							/>
							<Controller
								control={control}
								name="completedAt"
								render={({ field: { onChange, value } }) => (
									<DatetimeFormControl
										label="Completed At"
										displayMode
										disableNowPreset
										value={value && moment(value)}
										onChange={(completedAt) => onChange(completedAt?.toDate())}
									/>
								)}
							/>
						</>
					)}
				</RtxForm>
			</CollapsibleCard>
		);
	};

	return (
		<>
			<Row>
				<Col xs={{ order: 2 }} lg={{ span: 8, order: 1 }}>
					<Card body>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3">
									<Form.Label>Summary</Form.Label>
									<div className="form-control-plaintext">
										{renderSummary(taskProfile.summary)}
									</div>
								</Form.Group>
							</Col>
							{taskProfile.referenceKey && (
								<Col md={6}>
									<Form.Group className="mb-3">
										<Form.Label>Reference</Form.Label>
										<div className="form-control-plaintext">
											<Link to={getReferenceProfile(taskProfile.referenceKey)}>
												{TaskRouter.getFormattedReferenceKey(
													taskProfile.referenceKey
												)}
											</Link>
										</div>
									</Form.Group>
								</Col>
							)}
							<Col md={6}>
								<InputFormControl
									label="Requested At"
									displayMode
									value={timestampToReadable(taskProfile.requestedAt)}
								/>
							</Col>
							<Col md={6}>
								<RespOrgSelect
									label="RespOrg"
									displayMode
									initialOptionId={taskProfile.respOrgId}
									onChange={setRespOrg}
									value={respOrg}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="E-Mail"
									displayMode
									value={String(taskProfile.email)}
								/>
							</Col>
							{taskProfile.numberGroupId && (
								<Col md={6}>
									<NumberGroupSelect
										label="Number Group"
										displayMode
										onChange={setNumberGroup}
										value={numberGroup}
										initialOptionId={String(taskProfile.numberGroupId)}
									/>
								</Col>
							)}
							{renderParamsProfile()}
						</Row>
					</Card>
				</Col>
				<Col xs={{ order: 1 }} lg={{ span: 4, order: 2 }}>
					{renderStatusCard()}
				</Col>
			</Row>
		</>
	);
};
