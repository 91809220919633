import clsx from 'clsx';
import { FC } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	DownloadProfileResponse,
	FireBaseAdditionalDataResourceType,
	FirebaseMessageSeverity,
	FirebaseRouteType,
	ProductId
} from 'RtModels';
import { ProductIdToNameMap } from 'RtUi/app/rtCommon/Products/utils';
import { AlertHistoryRouter } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.router';
import { AlertRulesRouter } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.router';
import { DownloadsResource } from 'RtUi/app/user/MyProfile/lib/resources/DownloadsResource';
import { TimeFromNow } from 'RtUi/components/ui/TimeFromNow';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
interface INotificationGroupItemProps {
	message: IFirebaseUIMessage;
	onClick: () => void;
	isLight?: boolean;
}

export const AdditionalDataRouterMap = new Map([
	[
		FireBaseAdditionalDataResourceType.AlertRule,
		(id: number | string) => AlertRulesRouter.getProfileRoute(id)
	]
]);

export const SeverityIconsMap = new Map<FirebaseMessageSeverity, string>([
	[FirebaseMessageSeverity.Critical, 'fa-skull-crossbones'],
	[FirebaseMessageSeverity.Info, 'fa-info-circle'],
	[FirebaseMessageSeverity.Minor, 'fa-bell'],
	[FirebaseMessageSeverity.Major, 'fa-exclamation-triangle'],
	[FirebaseMessageSeverity.Success, 'fa-check-circle']
]);

const getProductName = (productId: ProductId) => {
	let productName = ProductIdToNameMap.get(productId);
	const commonProducts = [ProductId.RT_COMMON, ProductId.RT_ADM];

	if (commonProducts.includes(productId)) {
		productName = 'Message';
	}

	return productName;
};

const fileUtils = new FileUtils();

export const NotificationGroupItem: FC<
	React.PropsWithChildren<INotificationGroupItemProps>
> = ({ message, onClick, isLight }) => {
	const productName = getProductName(message.productId);
	const severity = FirebaseMessageSeverity[message.severity];
	const severityIcon = SeverityIconsMap.get(message.severity);
	const hasRoute = typeof message.routeType === 'number' && message.routeTypeId;
	const listGroupItemClassName = clsx(
		`rtNotification rtNotification--${severity}`,
		{
			'rtNotification--Clickable': hasRoute,
			'rtNotification--light': isLight
		}
	);

	const downloadFile = async (downloadId: number) => {
		const res = (await new DownloadsResource().get(
			downloadId
		)) as DownloadProfileResponse;

		return fileUtils.downloadFromUrlWithPromise(
			Promise.resolve({
				url: res.signedUrl,
				fileName: res.fileName
			})
		);
	};

	return (
		<ListGroup.Item className={listGroupItemClassName} onClick={onClick}>
			<header className="rtNotification__heading small mb-2">
				<i
					className={`rtNotification__heading__icon fas fa-fw ${severityIcon}`}
				/>
				<span>{productName}&nbsp;·&nbsp;Created: </span>
				<TimeFromNow className="text-muted" value={message.createTs} />
			</header>
			<section className="rtNotification__subject mb-1">
				<b className="text-body">{message.label}</b>
			</section>
			<ListGroup.Item
				bsPrefix="list-group-item-text"
				className="mb-0 rtNotification__text"
			>
				{message.summary}
				{'  '}
				{message.content}
				{message.routeType === FirebaseRouteType.GuardianAlertHistory && (
					<footer className="mt-1">
						<Link to={AlertHistoryRouter.getIndexRoute()}>
							<strong>View Alert History</strong>
						</Link>
					</footer>
				)}
				{message.routeType === FirebaseRouteType.MgiTask && (
					<footer className="mt-1">
						<Link to={`/rt800/tasks/${message.routeTypeId}?tab=Profile`}>
							<strong>View Task</strong>
						</Link>
					</footer>
				)}
				{message.routeType === FirebaseRouteType.RateLoad && (
					<footer className="mt-1">
						<Link to={`/rtSip/rateLoads/${message.routeTypeId}?tab=Profile`}>
							<strong>View Rate Load</strong>
						</Link>
					</footer>
				)}

				{message.routeTypeId &&
					message.routeType === FirebaseRouteType.Download && (
						<footer className="mt-1">
							<Button
								variant="link"
								onClick={() => downloadFile(Number(message.routeTypeId))}
							>
								<strong>Download file</strong>
							</Button>
						</footer>
					)}
				{message.additionalData && (
					<footer className="mt-1">
						<Link
							to={AdditionalDataRouterMap.get(
								message.additionalData.resourceType
							)!(message.additionalData.resourceId)}
						>
							<strong>View Alert</strong>
						</Link>
					</footer>
				)}
			</ListGroup.Item>
		</ListGroup.Item>
	);
};
