import * as moment from 'moment';
import * as moo from 'moo';
import { AosType, CprNodeType, RoutingCacheProfileRequest } from 'RtModels';
import {
	NameToCprNodeTypeMap,
	SomosNodeTypeLabels
} from 'RtUi/app/rt800/Cprs/lib/Constants';
import { RoutingCacheHttp } from 'RtUi/app/rt800/RoutingCache/lib/http/RoutingCacheHttp';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import {
	AosCol,
	AosNodeType,
	Cpr,
	CprApprovalIndicator,
	CprCol,
	CprStatus,
	RoutingCacheTypes
} from 'Somos/lib/SomosCpr/RtCprV2';
import { SomosCprFactory } from 'Somos/lib/SomosCpr/SomosCprFactory';
import {
	I8MsTypes,
	ICpr8Ms,
	IHdr8Ms,
	ILabel8Ms,
	IParsed8Ms,
	ITabbedCprSpreadsheet,
	LabelTypes
} from './Constants';

export type IMgiProfileImportTypes = '8ms' | 'routetrust' | 'lco';

export class MgiProfileImporter {
	private static readonly xlsxMimeType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	private static readonly plainMimeType = 'text/plain';

	public fileUtils = new FileUtils();
	public routingCacheHttp = new RoutingCacheHttp();

	/**
	 * Given an importType, return the file types allowed for that type
	 * @param importType
	 */
	public getMimeTypeAcceptStrFor(importType: IMgiProfileImportTypes) {
		let acceptStr = MgiProfileImporter.xlsxMimeType;

		if (importType === '8ms') {
			acceptStr += `,${MgiProfileImporter.plainMimeType}`;
		}

		return acceptStr;
	}

	/**
	 * Given a Routing Cache spec, parse and return a Cpr
	 */
	public async importFileWithRoutingCache(
		routingCacheReq: RoutingCacheProfileRequest,
		respOrgId: string,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null,
		routingCacheTypeId: RoutingCacheTypes,
		cprStatus: CprStatus,
		approvalIndicator: CprApprovalIndicator
	): Promise<Cpr> {
		const routingCacheRes =
			await this.routingCacheHttp.getProfile(routingCacheReq);
		const newCpr = new Cpr(
			respOrgId,
			routingCacheTypeId,
			routingCacheKey,
			sourceEffectiveTs
		);

		newCpr.setCprStatus(cprStatus);
		newCpr.setApprovalIndicator(approvalIndicator);
		newCpr.setInterLataCarriers(routingCacheRes.interLataCarriers ?? []);
		newCpr.setIntraLataCarriers(routingCacheRes.intraLataCarriers ?? []);

		newCpr.setValidation(false);

		//CAD
		const { aosCols = [] } = routingCacheRes;

		for (const aos of aosCols) {
			newCpr.makeAosCol(aos.aosNodeTypeId).setValues(aos.values);
		}

		if (routingCacheRes.approvalIndicator) {
			newCpr.setApprovalIndicator(routingCacheRes.approvalIndicator);
		}

		if (routingCacheRes.cprStatusId) {
			newCpr.setCprStatus(routingCacheRes.cprStatusId);
		}

		for (const cprCol of routingCacheRes.cprCols) {
			newCpr.makeCprCol(cprCol.cprNodeTypeId);
		}

		//LAD
		for (const label of routingCacheRes.cprLbls) {
			newCpr
				.makeCprLabel(label.cprNodeTypeId, label.name)
				?.setValues(label.values);
		}

		//CPR
		for (const cprRowDto of routingCacheRes.cprRows) {
			const cprRow = newCpr.makeCprRow();

			if (cprRowDto.announcement) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Announcement);
				cprCol.setValues(cprRowDto.announcement);
			}
			if (cprRowDto.areaCodes) {
				const cprCol = cprRow.makeCprCol(CprNodeType.AreaCode);
				cprCol.setValues(cprRowDto.areaCodes);
			}
			if (cprRowDto.carrier) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Carrier);
				cprCol.setValues(cprRowDto.carrier);
			}
			if (cprRowDto.dates) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Date);
				cprCol.setValues(cprRowDto.dates);
			}
			if (cprRowDto.daysOfWeek) {
				const cprCol = cprRow.makeCprCol(CprNodeType.DayOfWeek);
				cprCol.setValues(cprRowDto.daysOfWeek);
			}
			if (cprRowDto.latas) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Lata);
				cprCol.setValues(cprRowDto.latas);
			}
			if (cprRowDto.npaNxxs) {
				const cprCol = cprRow.makeCprCol(CprNodeType.NpaNxx);
				cprCol.setValues(cprRowDto.npaNxxs);
			}
			if (cprRowDto.percent) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Percent);
				cprCol.setValues(cprRowDto.percent);
			}
			if (cprRowDto.sixDigits) {
				const cprCol = cprRow.makeCprCol(CprNodeType.SixDigit);
				cprCol.setValues(cprRowDto.sixDigits);
			}
			if (cprRowDto.states) {
				const cprCol = cprRow.makeCprCol(CprNodeType.State);
				cprCol.setValues(cprRowDto.states);
			}
			if (cprRowDto.tenDigits) {
				const cprCol = cprRow.makeCprCol(CprNodeType.TenDigit);
				cprCol.setValues(cprRowDto.tenDigits);
			}
			if (cprRowDto.terminatingNumber) {
				const cprCol = cprRow.makeCprCol(CprNodeType.TerminatingNumber);
				cprCol.setValues(cprRowDto.terminatingNumber);
			}
			if (cprRowDto.times) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Time);
				cprCol.setValues(cprRowDto.times);
			}
			if (cprRowDto.switches) {
				const cprCol = cprRow.makeCprCol(CprNodeType.Switch);
				cprCol.setValues(cprRowDto.switches);
			}
		}

		newCpr.setValidation(true);
		newCpr.validate();

		return newCpr;
	}

	/**
	 * Given a file with RouteTrust formatting, parse and return a Cpr
	 */
	public async importFileWithRouteTrustFormat(
		file: File,
		respOrgId: string,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null,
		routingCacheTypeId: RoutingCacheTypes,
		cprStatus: CprStatus,
		approvalIndicator: CprApprovalIndicator
	): Promise<Cpr> {
		const newCpr = new Cpr(
			respOrgId,
			routingCacheTypeId,
			routingCacheKey,
			sourceEffectiveTs
		);

		newCpr.setApprovalIndicator(approvalIndicator);
		newCpr.setCprStatus(cprStatus);
		newCpr.setValidation(false);

		const cprSpreadsheet =
			await this.fileUtils.fileToTabbedSpreadsheet<ITabbedCprSpreadsheet>(file);

		let lastAos: AosCol | undefined;
		newCpr.removeCarriersSync();

		for (const cadRow of cprSpreadsheet['Admin (CAD)']) {
			const header = cadRow[''] || cadRow.__EMPTY;
			const value = cadRow.Data || cadRow.Formatted;
			const valueNum = Number(value);
			const valueArr = String(value)
				.split(',')
				.map((v) => v.trim());

			if (header === 'Area of Service Type') {
				let aosNodeTypeV2: AosNodeType;

				if (typeof value === 'number') {
					//v1 to v2
					const aosType = value as AosType;
					aosNodeTypeV2 = SomosCprFactory.RtAosV1ToRtAosV2(aosType);
				} else {
					aosNodeTypeV2 = value as AosNodeType;
				}

				lastAos = newCpr.makeAosCol(aosNodeTypeV2);
			} else if (header === 'Area of Service Values') {
				lastAos?.setValues(valueArr);
			} else if (header === 'Approval Indicator') {
				newCpr.setApprovalIndicator(value as CprApprovalIndicator);
			} else if (header === 'CPR Status') {
				newCpr.setCprStatus(valueNum as CprStatus);
			} else if (header === 'Effective TimeStamp') {
				//don't import effective TS
			} else if (header === 'InterLata Carriers') {
				newCpr.setInterLataCarriers(valueArr);
			} else if (header === 'IntraLata Carriers') {
				newCpr.setIntraLataCarriers(valueArr);
			} else if (header === 'Area of Service Labels') {
				newCpr.setAosLabelsByName(valueArr);
			}
		}

		newCpr.determineAndSetIfCarriersAreInSync();

		for (const ladRow of cprSpreadsheet['Labels (LAD)']) {
			const { Label: name, Values, Type } = ladRow;
			const emptyCells = this.fileUtils.getEmptyCellValuesFrom(ladRow);
			const allValues = [Values, ...emptyCells].join(',');
			const values = allValues.split(',').map((v) => v.trim());
			const cprNodeType = NameToCprNodeTypeMap.get(Type as SomosNodeTypeLabels);
			const nodeType = cprNodeType ?? (Type as CprNodeType);

			newCpr.makeCprLabel(nodeType, name)?.setValues(values);
		}

		const cprRows = cprSpreadsheet['Routing (CPR)'];

		for (const cprRow of cprRows) {
			const newRow = newCpr.makeCprRow();
			const headers = Object.keys(cprRow) as SomosNodeTypeLabels[];

			for (const header of headers) {
				const values = this.toCommaSeparatedArray(cprRow[header]);
				let cprCol: CprCol | undefined;

				if (header === 'Announce') {
					newCpr.makeCprCol(CprNodeType.Announcement);
					cprCol = newRow.makeCprCol(CprNodeType.Announcement);
				} else if (header === 'Area Code') {
					newCpr.makeCprCol(CprNodeType.AreaCode);
					cprCol = newRow.makeCprCol(CprNodeType.AreaCode);
				} else if (header === 'Carrier') {
					newCpr.makeCprCol(CprNodeType.Carrier);
					cprCol = newRow.makeCprCol(CprNodeType.Carrier);
				} else if (header === 'Day of Week') {
					newCpr.makeCprCol(CprNodeType.DayOfWeek);
					cprCol = newRow.makeCprCol(CprNodeType.DayOfWeek);
				} else if (header === 'Date') {
					newCpr.makeCprCol(CprNodeType.Date);
					cprCol = newRow.makeCprCol(CprNodeType.Date);
				} else if (header === 'Exchange') {
					newCpr.makeCprCol(CprNodeType.NpaNxx);
					cprCol = newRow.makeCprCol(CprNodeType.NpaNxx);
				} else if (header === 'LATA') {
					newCpr.makeCprCol(CprNodeType.Lata);
					cprCol = newRow.makeCprCol(CprNodeType.Lata);
				} else if (header === 'Percent') {
					newCpr.makeCprCol(CprNodeType.Percent);
					cprCol = newRow.makeCprCol(CprNodeType.Percent);
				} else if (header === 'Six Digit') {
					newCpr.makeCprCol(CprNodeType.SixDigit);
					cprCol = newRow.makeCprCol(CprNodeType.SixDigit);
				} else if (header === 'State') {
					newCpr.makeCprCol(CprNodeType.State);
					cprCol = newRow.makeCprCol(CprNodeType.State);
				} else if (header === 'Ten Digit') {
					newCpr.makeCprCol(CprNodeType.TenDigit);
					cprCol = newRow.makeCprCol(CprNodeType.TenDigit);
				} else if (
					header === 'Destination Number' ||
					header === 'Destination'
				) {
					newCpr.makeCprCol(CprNodeType.TerminatingNumber);
					cprCol = newRow.makeCprCol(CprNodeType.TerminatingNumber);
				} else if (header === 'Time') {
					newCpr.makeCprCol(CprNodeType.Time);
					cprCol = newRow.makeCprCol(CprNodeType.Time);
				} else if (header === 'Switch') {
					newCpr.makeCprCol(CprNodeType.Switch);
					cprCol = newRow.makeCprCol(CprNodeType.Switch);
				}

				if (cprCol) {
					cprCol.setValues(values);
				}
			}
		}

		newCpr.setValidation(true);
		newCpr.validate();

		return newCpr;
	}

	/**
	 * Given a file with 8MS formatting, parse and return an Cpr
	 */
	public async importFileWith8MsFormat(
		file: File,
		respOrgId: string,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null,
		routingCacheTypeId: RoutingCacheTypes,
		cprStatus: CprStatus,
		approvalIndicator: CprApprovalIndicator
	): Promise<Cpr> {
		const mimeType = await this.fileUtils.getMimeType(file);
		let flatText = '';

		if (mimeType === MgiProfileImporter.xlsxMimeType) {
			flatText = await this.getFlatFileFromSpreadsheet(file);
		} else if (mimeType === MgiProfileImporter.plainMimeType) {
			flatText = await this.fileUtils.getContentFromFile(file);
		}

		return this.import8ms(
			flatText,
			respOrgId,
			routingCacheKey,
			sourceEffectiveTs,
			routingCacheTypeId,
			cprStatus,
			approvalIndicator
		);
	}

	/**
	 * Import 8MS string from file
	 */
	public import8ms(
		fileStr: string,
		respOrgId: string,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null,
		routingCacheTypeId: RoutingCacheTypes,
		cprStatus: CprStatus,
		approvalIndicator: CprApprovalIndicator
	): Cpr {
		const sectionStartType = 'sectionStart';
		const sectionEndType = 'sectionEnd';
		const propertyAndValueType = 'propertyAndValue';

		const lexer = moo.compile({
			//ignored matches
			newline: { match: /[\r\n]{1,2}/, lineBreaks: true },
			comment: /#.*/,
			fileStart: /\-{1,2}\s*[Ss][Tt][Aa][Rr][Tt]\s*[Nn][Uu][Mm]\s*\-{1,2}\s*/,
			fileEnd: /\-{1,2}\s*[Ee][Nn][Dd]\s*[Nn][Uu][Mm]\s*\-{1,2}\s*/,
			//used matches
			[sectionStartType]: /\-{1,2}\s*[Ss][Tt][Aa][Rr][Tt]\s*\w*\s*\-{1,2}\s*/,
			[sectionEndType]: /\-{1,2}\s*[Ee][Nn][Dd]\s*\w*\s*\-{1,2}\s*/,
			[propertyAndValueType]: /.*\s*:\s*.*\s*/
		});

		//set fileStr as input to tokenizer
		lexer.reset(fileStr);

		const parsed8ms: IParsed8Ms = { hdr: {}, cprs: [], labels: [] };
		let currentSectionIsHdr = false;
		let currentSectionIsLabels = false;
		let currentSectionIsCpr = false;

		//Iterate through tokens
		for (const token of lexer) {
			if (token.type === sectionStartType) {
				//-START <HDR/CPR/LBLS>-
				const textToLower = token.text.toLowerCase();
				currentSectionIsHdr = textToLower.includes('hdr');
				currentSectionIsLabels = textToLower.includes('lbls');
				currentSectionIsCpr = textToLower.includes('cpr');

				if (currentSectionIsCpr) {
					//Push new CPR object to be filled in future iterations
					parsed8ms.cprs.push({});
				}

				if (
					!currentSectionIsHdr &&
					!currentSectionIsLabels &&
					!currentSectionIsCpr
				) {
					throw new Error(`Unknown Section Type: ${token.text}`);
				}
			} else if (token.type === propertyAndValueType) {
				//<property>:<value>
				const { text } = token;
				const indexOfColon = text.indexOf(':');
				const name = text.substr(0, indexOfColon).trim();
				const value = text.substr(indexOfColon + 1).trim();

				if (currentSectionIsHdr) {
					this.loadHdrWithProperty(parsed8ms.hdr, name, value);
				} else if (currentSectionIsLabels) {
					this.loadLabelsWithProperty(parsed8ms.labels, name, value);
				} else if (currentSectionIsCpr) {
					this.loadCprWithProperty(parsed8ms.cprs, name, value);
				}
			} else if (token.type === sectionEndType) {
				//cSpell:ignore LBLS
				//-END <HDR/CPR/LBLS>-
				if (currentSectionIsCpr) {
					this.postCprParsing(parsed8ms);
				} else if (currentSectionIsHdr) {
					this.postHdrParsing(parsed8ms.hdr);
				}
			}
		}

		this.post8MSParsing(parsed8ms);

		return this.parse8MsToImportedMgiProfile(
			parsed8ms,
			respOrgId,
			routingCacheKey,
			sourceEffectiveTs,
			routingCacheTypeId,
			cprStatus,
			approvalIndicator
		);
	}

	/**
	 * Get a flat fileStr from a spreadsheet
	 * @param spreadsheetFile
	 */
	private getFlatFileFromSpreadsheet(spreadsheetFile: File) {
		return this.fileUtils
			.fileToSpreadsheet(spreadsheetFile)
			.then((spreadsheet) => {
				let flatText = '';

				for (const row of spreadsheet) {
					const cell = row.join(' ');

					flatText += `${cell}\n`;
				}

				return flatText;
			});
	}

	/**
	 * Given a IParsed8Ms result, return an Cpr
	 * @param parsed8ms
	 */
	private parse8MsToImportedMgiProfile(
		parsed8ms: IParsed8Ms,
		respOrgId: string,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null,
		routingCacheTypeId: RoutingCacheTypes,
		cprStatus: CprStatus,
		approvalIndicator: CprApprovalIndicator
	): Cpr {
		const newCpr = new Cpr(
			respOrgId,
			routingCacheTypeId,
			routingCacheKey,
			sourceEffectiveTs
		);

		newCpr.setCprStatus(cprStatus);
		newCpr.setApprovalIndicator(approvalIndicator);

		const { labels: parsedLabels = [] } = parsed8ms;
		// const { cprs: parsedCprs = [] } = parsed8ms;
		//const { hdr: parsedHdr } = parsed8ms;

		// HDR parsing
		// TODO

		//Labels
		for (const parsedLabel of parsedLabels) {
			const { name, values, labelType } = parsedLabel;
			const nodeType = this.labelTypeToNodeType(labelType);

			newCpr.makeCprLabel(nodeType, name)?.setValues(values);
		}

		//CPRs
		// eslint-disable-next-line @typescript-eslint/prefer-for-of

		newCpr.validate();

		return newCpr;
	}

	/**
	 * - Fill in and assert CPR data structure after parsing
	 * - Set base CPR if exists
	 * @param parsed8ms
	 */
	private postCprParsing(parsed8ms: IParsed8Ms) {
		//Set completedCpr to baseCpr if it does not contain a Name property
		const completedCpr = parsed8ms.cprs[parsed8ms.cprs.length - 1];
		const isBaseCpr = typeof completedCpr.Name === 'undefined';

		if (isBaseCpr) {
			//One and only one CPR must have no name; this is the base CPR.
			if (parsed8ms.baseCpr) {
				throw new Error('More than one base CPR detected. Only one is allowed');
			} else {
				parsed8ms.baseCpr = completedCpr;
			}
		}
	}

	/**
	 * Fill in and assert HDR data structure after parsing
	 * @param hdr
	 */
	private postHdrParsing(hdr: IHdr8Ms) {
		//The first two header fields (Type and Cprname) are required. The remaining fields are optional.
		const requiredHDRTypes: Array<keyof IHdr8Ms> = ['Cprname', 'Type'];
		const requiredHdrTypesNotFound: Array<keyof IHdr8Ms> = [];

		for (const requiredHDRType of requiredHDRTypes) {
			if (typeof hdr[requiredHDRType] === 'undefined') {
				requiredHdrTypesNotFound.push(requiredHDRType);
			}
		}

		if (requiredHdrTypesNotFound.length > 0) {
			throw new Error(
				`HDR is missing the following required properties: ${requiredHdrTypesNotFound.join(
					','
				)}`
			);
		}

		//If activation is specified without supplying an effective date/time, NOW is assumed.
		if (typeof hdr.Activate === 'boolean') {
			if (!hdr.Effdate) {
				hdr.Effdate = 'NOW';
			}
		}
	}

	/**
	 * Fill in and assert 8MS data structure after parsing
	 * @param parsed8ms
	 */
	private post8MSParsing(parsed8ms: IParsed8Ms) {
		//One and only one CPR must have no name; this is the base CPR.
		if (typeof parsed8ms.baseCpr === 'undefined') {
			throw new Error('Base CPR was not found; a CPR without a Name property');
		}

		//Labels without labelType reference the base CPR or HDR type as the labelType
		const labelsThatRequireType = parsed8ms.labels.filter(
			(label) => typeof label.labelType === 'undefined'
		);
		const baseType = this.getBaseType(parsed8ms);

		if (labelsThatRequireType.length > 0) {
			if (!baseType) {
				throw new Error(
					'Some labels required a type that was not defined in the base CPR or HDR'
				);
			}

			//@ts-ignore -- translate works because LabelTypes uses same enum values as ITypes8Ms
			const baseLabelType = baseType as LabelTypes;

			for (const labelThatRequireType of labelsThatRequireType) {
				labelThatRequireType.labelType = baseLabelType;
			}
		}

		//Cprs with type reference the base CPR or HDR type as the CPR type
		const cprsThatRequireType = parsed8ms.cprs.filter(
			(cpr) => typeof cpr.Type === 'undefined'
		);

		if (cprsThatRequireType.length > 0) {
			if (!baseType) {
				throw new Error(
					'Some CPRs required a type that was not defined in the base CPR or HDR'
				);
			}

			for (const cprThatRequireType of cprsThatRequireType) {
				cprThatRequireType.Type = baseType;
			}
		}
	}

	/**
	 * First looks for base type from base CPR
	 * Secondly looks for the base type in the HDR (if it does not exist in base CPR)
	 * @param parsed8ms
	 */
	private getBaseType(parsed8ms: IParsed8Ms) {
		let baseType: I8MsTypes | undefined;

		if (parsed8ms.baseCpr && typeof parsed8ms.baseCpr.Type === 'number') {
			baseType = parsed8ms.baseCpr.Type;
		} else if (typeof parsed8ms.hdr.Type === 'number') {
			baseType = parsed8ms.hdr.Type;
		}

		return baseType;
	}

	/**
	 * Given an array of labels, use the latest element in the array to enter in property data
	 * @param labels
	 * @para name
	 * @param value
	 * @throws error if labelType is malformed
	 */
	private loadLabelsWithProperty(
		labels: ILabel8Ms[],
		name: string,
		value: string
	) {
		const values = value.split(',').map((v) => v.trim());
		let labelType: LabelTypes | undefined;
		const labelTypeShouldBeDefined = name.indexOf('/') > 0;

		if (labelTypeShouldBeDefined) {
			const nameParts = name.split('/');
			const possibleLabelType = nameParts[1].toUpperCase();
			//@ts-expect-error
			const labelTypeIndex = LabelTypes[possibleLabelType];
			name = nameParts[0];

			if (labelTypeIndex >= 0) {
				labelType = labelTypeIndex;
			} else {
				throw new Error(`Label Type could be found for ${possibleLabelType}`);
			}
		}

		labels.push({ name, values, labelType });
	}

	/**
	 * Given an array of CPRs, use the latest element in the array to enter in property data
	 * @param cprs
	 * @param name
	 * @param value
	 */
	private loadCprWithProperty(cprs: ICpr8Ms[], name: string, value: string) {
		const nameToLower = name.toLowerCase();
		const currentCpr: ICpr8Ms | undefined = cprs[cprs.length - 1];

		if (!currentCpr) {
			throw new Error('Property found outside of CPR');
		}

		if (nameToLower === 'c') {
			//Carrier
			if (!currentCpr.carriers) {
				currentCpr.carriers = [];
			}

			const carrierParts = value.split(':');
			const values = carrierParts[1].split(',').map((v) => v.trim());
			let cic = carrierParts[0];
			let pots: string | undefined;

			if (cic.indexOf('/') > 0) {
				const cicParts = cic.split('/');
				cic = cicParts[0];
				pots = cicParts[1];
			}

			currentCpr.carriers.push({ cic, values, pots });
		} else if (nameToLower === 'n') {
			//NPA
			if (!currentCpr.npas) {
				currentCpr.npas = [];
			}

			const npaParts = value.split(':');
			const npa = npaParts[0];
			const cic = npaParts[1];

			currentCpr.npas.push({ npa, cic });
		} else if (nameToLower === 'b') {
			//Block
			if (!currentCpr.blocks) {
				currentCpr.blocks = [];
			}

			const blockParts = value.split(':');
			const annType = blockParts[0];
			const values = blockParts[1].split(',').map((v) => v.trim());

			currentCpr.blocks.push({ annType, values });
		} else if (nameToLower === 'o') {
			//Other
			if (!currentCpr.others) {
				currentCpr.others = [];
			}

			currentCpr.others.push({ cic: value });
		} else if (nameToLower === 't') {
			//Tree
			if (!currentCpr.trees) {
				currentCpr.trees = [];
			}

			const treeParts = value.split(':');
			const name = treeParts[0];
			const values = treeParts[1].split(',').map((v) => v.trim());

			currentCpr.trees.push({ name, values });
		} else if (nameToLower === 'name') {
			currentCpr.Name = value;
		} else if (nameToLower === 'type') {
			currentCpr.Type = this.get8MsType(value);
		}
	}

	/**
	 * Enter property into HDR
	 * cSpell:disable
	 */
	private loadHdrWithProperty(hdr: IHdr8Ms, name: string, value: string) {
		const hdrLowerPropertyNameLower = name.toLowerCase();
		const valueToLower = value.toLowerCase();
		const getYesNoValue = () => valueToLower === 'y';

		if (hdrLowerPropertyNameLower === 'type') {
			hdr.Type = this.get8MsType(value);
		} else if (hdrLowerPropertyNameLower === 'cprname') {
			hdr.Cprname = value;
		} else if (hdrLowerPropertyNameLower === 'target#') {
			hdr['Target#'] = value;
		} else if (hdrLowerPropertyNameLower === 'activate') {
			hdr.Activate = getYesNoValue();
		} else if (hdrLowerPropertyNameLower === 'effdate') {
			if (valueToLower === 'now') {
				hdr.Effdate = 'NOW';
			} else {
				hdr.Effdate = moment(value).toDate();
			}
		} else if (hdrLowerPropertyNameLower === 'source#') {
			hdr['Source#'] = value;
		} else if (hdrLowerPropertyNameLower === 'ignoreinvalidnpanxx') {
			hdr.IgnoreInvalidNpaNxx = getYesNoValue();
		} else if (hdrLowerPropertyNameLower === 'reducetoother') {
			hdr.ReduceToOther = getYesNoValue();
		} else if (hdrLowerPropertyNameLower === 'maxmsgsperhour') {
			hdr.MaxMsgsPerHour = Number(value);
		} else if (hdrLowerPropertyNameLower === 'pec') {
			hdr.Pec = value;
		} else if (hdrLowerPropertyNameLower === 'pac') {
			hdr.Pac = value;
		} else if (hdrLowerPropertyNameLower === 'servicearea') {
			hdr.ServiceArea = value.split(',').map((v) => v.trim());
		}
	}
	//cSpell:enable

	/**
	 * Given a parsed value, return the 8MS type
	 * @param value
	 * @throws error if type is now found
	 */
	private get8MsType(value: string): I8MsTypes {
		const valueToUpper = value.toUpperCase() as keyof typeof I8MsTypes;
		const type = I8MsTypes[valueToUpper];

		if (type >= 0) {
			return type;
		} else {
			throw new Error(`Type could not be determined from: ${valueToUpper}`);
		}
	}

	/**
	 * Translate LabelType to ICprNodeType
	 * @param labelType
	 */
	private labelTypeToNodeType(labelType: LabelTypes | undefined): CprNodeType {
		let nodeType: CprNodeType = CprNodeType.Announcement;
		const setNodeTypeIfMatches = (
			possibleLabelType: LabelTypes,
			possibleNodeType: CprNodeType
		) => {
			if (possibleLabelType === labelType) {
				nodeType = possibleNodeType;
			}
		};

		setNodeTypeIfMatches(LabelTypes.AC, CprNodeType.Announcement);
		setNodeTypeIfMatches(LabelTypes.LT, CprNodeType.Lata);
		setNodeTypeIfMatches(LabelTypes.SD, CprNodeType.SixDigit);
		setNodeTypeIfMatches(LabelTypes.ST, CprNodeType.State);
		setNodeTypeIfMatches(LabelTypes.TD, CprNodeType.TenDigit);

		return nodeType;
	}

	private toCommaSeparatedArray = (str: string | number | undefined) => {
		if (typeof str === 'undefined' || str === '') {
			return [];
		}

		return String(str)
			.split(',')
			.map((v) => v.trim());
	};
}
