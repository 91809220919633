import { ITabbedSpreadsheet } from 'RtUi/workers/lib/spreadsheet/interfaces';
import { CprNodeType, ICprLerg } from 'Somos/lib/SomosCpr/RtCprV2';
import { SomosNodeTypeLabels } from '../Constants';

//cSpell:ignore NOREDUCE
export enum I8MsTypes {
	'6DIGIT' = 0,
	'6DIGIT_NOREDUCE',
	NPA,
	LATA,
	STATE,
	'10DIGIT',
	NPANXX
}

export enum LabelTypes {
	SD = I8MsTypes['6DIGIT'],
	AC = I8MsTypes.NPA,
	LT = I8MsTypes.LATA,
	TD = I8MsTypes['10DIGIT'],
	ST = I8MsTypes.STATE
}

export interface ICarrier8Ms {
	cic: string;
	values: string[];
	pots?: string;
}

export interface INpa8Ms {
	npa: string;
	cic: string;
}

export interface IBlock8Ms {
	annType: string;
	values: string[];
}

export interface IOther8Ms {
	cic: string;
}

export interface ITree8Ms {
	name: string;
	values: string[];
}

export interface ICpr8Ms {
	Name?: string;
	Type?: I8MsTypes; //WILL exist by the end of parsing
	carriers?: ICarrier8Ms[];
	npas?: INpa8Ms[];
	blocks?: IBlock8Ms[];
	others?: IOther8Ms[];
	trees?: ITree8Ms[];
}

export interface IHdr8Ms {
	'Type'?: I8MsTypes; //WILL exist by the end of parsing
	'Cprname'?: string; //WILL exist by the end of parsing
	'Target#'?: string;
	'Activate'?: boolean;
	'Effdate'?: 'NOW' | Date;
	'Source#'?: string;
	'IgnoreInvalidNpaNxx'?: boolean;
	'ReduceToOther'?: boolean;
	'MaxMsgsPerHour'?: number;
	'Pec'?: string;
	'Pac'?: string;
	'ServiceArea'?: string[];
}

export interface ILabel8Ms {
	name: string;
	values: string[];
	labelType?: LabelTypes; //WILL exist by the end of parsing
}

export interface IParsed8Ms {
	hdr: IHdr8Ms;
	cprs: ICpr8Ms[];
	labels: ILabel8Ms[];
	baseCpr?: ICpr8Ms;
}

export type AdminCadHeaders =
	| 'Effective TimeStamp'
	| 'Area of Service Type'
	| 'Area of Service Values'
	| 'Area of Service Labels'
	| 'InterLata Carriers'
	| 'IntraLata Carriers'
	| 'CPR Status'
	| 'Approval Indicator';

export interface IAdminCAD {
	'': AdminCadHeaders;
	'Formatted': string;
	'Data'?: any;
	'__EMPTY'?: any;
}

export type IRoutingCPR = {
	[headerName in SomosNodeTypeLabels]?: string;
};

export interface ILabelsLAD {
	Label: string;
	Type: string;
	Values: string;
}

export interface ITabbedCprSpreadsheet extends ITabbedSpreadsheet {
	'Admin (CAD)': IAdminCAD[];
	'Routing (CPR)': IRoutingCPR[];
	'Labels (LAD)': ILabelsLAD[];
}

export type CprLergGenericContainer<T> = {
	[key in CprNodeType]?: T;
};

export type CprGenericContainer<T> = {
	[key in CprNodeType]?: T;
};

export type TCprLergSearchForKeyResults = CprGenericContainer<string[]>;
export type TCprLergSearchRequest = {
	lerg?: {
		key: CprNodeType;
		value: string;
	};
	cic?: string;
	searchForDescendants?: boolean;
	searchForAscendants?: boolean;
};

export type TLergLookups = {
	[key in CprNodeType]?: Record<string, ICprLerg>;
};
