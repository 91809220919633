import { DefaultSipAgentConfig, SipAgentMedSource } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface SipAgentMedSourceDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SipAgentMedSourceDataGridColumn = <T = any,>({
	...config
}: SipAgentMedSourceDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: DefaultSipAgentConfig) => {
		if (value) {
			const config = value;
			const parsedConfig = config.medSources.map((value) => {
				switch (value) {
					case SipAgentMedSource.FromHeader:
						return 'From Header';
					case SipAgentMedSource.PAssertedIdentityHeader:
						return 'PA Asserted Identity Header';
					case SipAgentMedSource.RequestUri:
						return 'Request Uri';
					default:
						return value;
				}
			});

			return parsedConfig.join(', ');
		}

		return '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<any>())
	});
};
