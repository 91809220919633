import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import {
	approvalIndicatorToName,
	cprStatusIdToName,
	timestampToReadable
} from 'RtUi/utils/maps';
import * as moment from 'moment';
import {
	CSSProperties,
	FC,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { Button } from 'react-bootstrap';
import { AreaOfServicesOptions } from '../controls/AreaOfServiceSelect';
import { AreaOfServiceAllValueTypes } from '../resources/AreaOfServiceValuesResource';
import {
	AdminCadHeaders,
	IAdminCAD,
	ITabbedCprSpreadsheet
} from '../util/Constants';

interface IExportCprButtonProps {
	btnColor?: string;
	className?: string;
	style?: CSSProperties;
}

export const ExportCprButton: FC<
	React.PropsWithChildren<IExportCprButtonProps>
> = ({ className, style, btnColor = 'white-alt' }) => {
	const {
		cpr,
		id,
		isNumber,
		isNumberAndHasTemplateAssigned,
		cprDataGrid,
		cprLabelDataGrid
	} = useContext(CprProfileContext);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const fileUtils = useMemo(() => new FileUtils(), []);

	const getCadWorksheetInput = useCallback(() => {
		const cadData: IAdminCAD[] = [];

		const addCadData = (
			header: AdminCadHeaders,
			formattedData: string,
			rawData?: string | number
		) => {
			cadData.push({
				'': header,
				'Formatted': formattedData,
				'Data': rawData
			});
		};

		const sourceEffectiveTs = cpr.getSourceEffectiveTs();

		addCadData(
			'Effective TimeStamp',
			timestampToReadable(sourceEffectiveTs),
			sourceEffectiveTs?.toISOString()
		);

		for (const [aosTypeId, areaOfService] of cpr.getAosColEntries()) {
			if (!areaOfService) {
				continue;
			}

			const aosValues = areaOfService.getRawValues();

			if (aosValues.length <= 0) {
				continue;
			}

			let areaOfServiceLabel = String(aosTypeId);
			const foundAreaOfServiceOption = AreaOfServicesOptions.find(
				(option) => option.value === aosTypeId
			);

			if (foundAreaOfServiceOption) {
				areaOfServiceLabel = foundAreaOfServiceOption.label;
			}

			let areaOfServiceValues = aosValues.join(', ');
			const foundAreaOfServiceValues = AreaOfServiceAllValueTypes.filter(
				(option) => aosValues.includes(option.value)
			);

			if (foundAreaOfServiceValues.length > 0) {
				areaOfServiceValues = foundAreaOfServiceValues
					.map((val) => val.label)
					.join(', ');
			}

			addCadData('Area of Service Type', areaOfServiceLabel, aosTypeId);
			addCadData(
				'Area of Service Values',
				areaOfServiceValues,
				aosValues.join(', ')
			);
		}

		const aosLabels = cpr.getAosLabelNames();
		if (aosLabels.length > 0) {
			addCadData('Area of Service Labels', aosLabels.join(', '));
		}

		addCadData('InterLata Carriers', cpr.getInterLataCarriers().join(', '));
		addCadData('IntraLata Carriers', cpr.getIntraLataCarriers().join(', '));

		const cprStatusId = cpr.getCprStatus();
		const cprStatus = cprStatusIdToName(cprStatusId)!;

		addCadData('CPR Status', cprStatus, cprStatusId);

		const approvalIndicator = cpr.getApprovalIndicator();
		const approvalIndicatorStr = approvalIndicatorToName(approvalIndicator)!;
		addCadData('Approval Indicator', approvalIndicatorStr, approvalIndicator);

		return cadData;
	}, [cpr]);

	const onSubmit = useCallback(async () => {
		if (!cpr || !cprDataGrid.current || !cprLabelDataGrid.current) {
			return;
		}

		let formattedName = id.replace('*', '');

		if (isNumber) {
			formattedName =
				TollFreeNumbersUtils.tollFreeNumberToReadableString(formattedName);
		}
		try {
			setIsSubmitting(true);

			const [cprExportedData, labelsExportedData] = await Promise.all([
				cprDataGrid.current.data,
				cprLabelDataGrid.current.data
			]);

			const exportSprSpreadsheet: ITabbedCprSpreadsheet = {
				'Admin (CAD)': getCadWorksheetInput(),
				'Routing (CPR)': cprExportedData,
				'Labels (LAD)': labelsExportedData
			};

			const mgiProfileDate = moment(cpr.getSourceEffectiveTs());
			const fileName = `${formattedName}_${mgiProfileDate.format(
				'YYYY-MM-DD'
			)}_${mgiProfileDate.format('HH-MM')}.xlsx`;

			await fileUtils.saveSpreadsheet(exportSprSpreadsheet, fileName);
		} catch (err) {
			console.error(err);
		} finally {
			setIsSubmitting(false);
		}
	}, [
		cpr,
		id,
		isNumber,
		fileUtils,
		getCadWorksheetInput,
		cprDataGrid,
		cprLabelDataGrid
	]);

	return (
		<Button
			variant={btnColor}
			type="button"
			className={className}
			style={style}
			onClick={onSubmit}
			disabled={isNumberAndHasTemplateAssigned || isSubmitting}
		>
			{isSubmitting ? (
				<i className="fas fa-fw fa-cog fa-spin" />
			) : (
				<i className="fas fa-fw fa-file-excel" />
			)}
			<span>&nbsp;Export</span>
		</Button>
	);
};
