/* eslint-disable max-classes-per-file */
import { ResourceConfigurationIndexResponse } from 'RtModels';
import { RtCarrierNumberRouter } from 'RtUi/app/rtCarrierConnect/Numbers/RtCarrierNumber.router';
import {
	IResourceTypeOption,
	ResourceTypeSelect
} from 'RtUi/app/rtDid/Dashboard/lib/controls/ResourceTypeSelect';
import { DidNumberRouter } from 'RtUi/app/rtDid/Numbers/DidNumber.router';
import { UploadDidBillingInventoryForm } from 'RtUi/app/rtDid/Numbers/lib/forms/UploadDidBillingInventoryForm';
import { UploadDidSwitchInventoryForm } from 'RtUi/app/rtDid/Numbers/lib/forms/UploadDidSwitchInventoryForm';
import { DidNumbersGrid } from 'RtUi/app/rtDid/Numbers/lib/grids/DidNumbersGrid';
import {
	IDidNumberIndexRequest,
	INumberCreateResponse
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { ResourceConnectionSelect } from 'RtUi/app/rtDid/Resources/lib/controls/ResourceConnectionSelect';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	vendor: ResourceConfigurationIndexResponse | undefined;
	ResourceTypeOption: IResourceTypeOption[] | undefined;
	numberType: number | undefined;
}

interface IDidNumberIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

export abstract class DidNumberIndexContainerClass extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IDidNumberIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		vendor: undefined,
		ResourceTypeOption: undefined,
		numberType: 0
	};

	public state: IDidNumberIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Search,
		resourceParams: {}
	};

	protected router: RtUiRouter = DidNumberRouter;
	protected disableReferences: RtUiRouter[] = [RtCarrierNumberRouter];

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { vendor, ResourceTypeOption, numberType } = this.state;

		const resourceParams: IDidNumberIndexRequest = {};

		resourceParams.numberType =
			!numberType || numberType === 0 ? undefined : numberType;

		if (vendor) {
			resourceParams.resourceId = vendor.resourceId;
		}

		if (ResourceTypeOption && ResourceTypeOption.length > 0) {
			resourceParams.inTypes = ResourceTypeOption.map((option) => option.value);
		}

		return resourceParams;
	}

	public onInventoryUpdate(res: INumberCreateResponse) {
		const activeTab = this.tabs.Results;

		this.setState({ activeTab });
	}

	public render() {
		return (
			<TabbedLayout
				router={this.router}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm
						hideButtons
						onSubmit={(e) => this.submitAndGoToResults(e)}
					>
						<ResourceConnectionSelect
							label="Connection"
							onChange={(vendor: any) => this.setState({ vendor })}
							value={this.state.vendor as any}
						/>
						<RadioFormControl<number>
							label="Number Type"
							onChange={(numberType) =>
								this.setState({
									numberType
								})
							}
							value={this.state.numberType}
							options={[
								{
									value: 0,
									label: 'All',
									tooltip: 'Show all numbers'
								},
								{
									value: 1,
									label: 'DIDs',
									tooltip: 'Show only DID numbers'
								},
								{
									value: 2,
									label: 'Toll-Free',
									tooltip: 'Show only Toll-Free numbers'
								}
							]}
						/>{' '}
						<ResourceTypeSelect<true>
							multi
							onChange={(ResourceTypeOption) =>
								this.setState({ ResourceTypeOption })
							}
							value={this.state.ResourceTypeOption}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<DidNumbersGrid
						disableReferences={this.disableReferences}
						resourceParams={this.state.resourceParams}
						autoFocusFilter
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Upload Billing Inventory" isAction>
					<UploadDidBillingInventoryForm
						onUpdate={(res) => this.onInventoryUpdate(res)}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Upload Switch Inventory" isAction>
					<UploadDidSwitchInventoryForm
						onUpdate={(res) => this.onInventoryUpdate(res)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}

@DidNumberRouter.getIndexRtUiController()
export class DidNumberIndexContainer extends DidNumberIndexContainerClass {}
