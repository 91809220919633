import { DefaultSipAgentConfig, SipAgentMedValue } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface SipAgentMedValueDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SipAgentMedValueDataGridColumn = <T = any,>({
	...config
}: SipAgentMedValueDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: DefaultSipAgentConfig) => {
		if (value) {
			const config = value;
			const parsedConfig = config.medValues.map((value) => {
				switch (value) {
					case SipAgentMedValue.MedDefaultSubId:
						return 'Default Sub Id';
					case SipAgentMedValue.Fqdn:
						return 'Fqdn';
					case SipAgentMedValue.MedNotFoundSubId:
						return 'Not Found Sub Id';
					case SipAgentMedValue.ParamOtg:
						return 'Param Otg';
					case SipAgentMedValue.ParamTgrp:
						return 'Param Tgrp';
					default:
						return value;
				}
			});

			return parsedConfig.join(', ');
		}

		return '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<any>())
	});
};
