import * as RtCprV1 from 'Somos/lib/SomosCpr/RtCprV1';
import * as RtCprV2 from 'Somos/lib/SomosCpr/RtCprV2';

export class SomosCprFactory {

	public static RtAosV1ToRtAosV2(aosV1: RtCprV1.AosType): RtCprV2.AosNodeType {
		let aosV2: RtCprV2.AosNodeType;

		switch (aosV1) {
			case RtCprV1.AosType.AreaCode:
				aosV2 = RtCprV2.AosNodeType.AreaCode;
				break;
			case RtCprV1.AosType.Lata:
				aosV2 = RtCprV2.AosNodeType.Lata;
				break;
			case RtCprV1.AosType.Network:
				aosV2 = RtCprV2.AosNodeType.Network;
				break;
			default:
				aosV2 = RtCprV2.AosNodeType.State;
		}

		return aosV2;
	}

	public static RtAosV2ToRtAosV1(aosV2: RtCprV2.AosNodeType): RtCprV1.AosType {
		let aosV1: RtCprV1.AosType;

		switch (aosV2) {
			case RtCprV2.AosNodeType.AreaCode:
				aosV1 = RtCprV1.AosType.AreaCode;
				break;
			case RtCprV2.AosNodeType.Lata:
				aosV1 = RtCprV1.AosType.Lata;
				break;
			case RtCprV2.AosNodeType.Network:
				aosV1 = RtCprV1.AosType.Network;
				break;
			default:
				aosV1 = RtCprV1.AosType.States;
		}

		return aosV1;
	}

	public static RtCprV2CloneCpr(cpr: RtCprV2.Cpr): RtCprV2.Cpr {
		const dto = SomosCprFactory.RtCprV2CprToDto(cpr);
		return SomosCprFactory.RtCprV2DtoToCpr(dto);
	}

	public static RtCprV2CloneDto(dto: RtCprV2.CprDto): RtCprV2.CprDto {
		const cpr = SomosCprFactory.RtCprV2DtoToCpr(dto);
		return SomosCprFactory.RtCprV2CprToDto(cpr);
	}

	public static RtCprV2CprToDto(cpr: RtCprV2.Cpr, terminatingNumberOverride: string | null = null): RtCprV2.CprDto {
		const dto = new RtCprV2.CprDto();

		dto.routingCacheTypeId = cpr.routingCacheTypeId;
		dto.routingCacheKey = cpr.routingCacheKey;

		dto.sourceRespOrgId = cpr.getSourceRespOrgId();
		dto.sourceEffectiveTs = cpr.getSourceEffectiveTs();
		dto.sourceRecVersionId = cpr.getSourceRecVersionid();
		dto.sourceTemplateName = cpr.getSourceTemplateName();

		dto.targetRespOrgId = cpr.getTargetRespOrgId();
		dto.targetEffectiveTs = cpr.getTargetEffectiveTs();
		dto.targetTemplateName = cpr.getTargetTemplateName();

		dto.cprStatusId = cpr.getCprStatus();
		dto.approvalIndicator = cpr.getApprovalIndicator();

		// dto.somosCprs = cpr.somosCprs.slice();

		dto.contactName = cpr.getContactName();
		dto.contactNumber = cpr.getContactNumber();

		dto.notes = cpr.getNotes();
		dto.summary = cpr.getSummary();

		dto.company = cpr.getCompany();
		dto.timeZone = cpr.getTimeZone();
		dto.daylightSavings = cpr.getDaylightSavings();

		dto.lineQty = cpr.getLineQty();

		dto.interLataCarriers = cpr.getInterLataCarriers();
		dto.intraLataCarriers = cpr.getIntraLataCarriers();

		for (const src of cpr.getAosLabels()) {
			dto.aosLbls.push({ name: src.getName(), aosNodeTypeId: src.aosNodeTypeId, values: src.getRawValues() });
		}

		for (const src of cpr.getAosCols()) {
			const values = src.getRawValues();
			if (values.length === 0) {
				continue;
			}
			dto.aosCols.push({ aosNodeTypeId: src.aosNodeTypeId, values: values });
		}

		for (const src of cpr.getCprLabels()) {
			dto.cprLbls.push({ name: src.getName(), cprNodeTypeId: src.cprNodeTypeId, values: src.getRawValues() });
		}

		for (const src of cpr.getCprCols()) {
			dto.cprCols.push({ cprNodeTypeId: src.cprNodeTypeId, timeZone: src.getTimeZone() });
		}

		dto.cprRows = [];

		for (const srcRow of cpr.getCprRows()) {
			const dtoRow: RtCprV2.ICprRow = {
			};

			for (const srcCol of cpr.getCprCols()) {
				const src = srcRow.getCprCol(srcCol.cprNodeTypeId);

				if (!src) {
					// @TODO WTF
					continue;
				}

				const values = src.getRawValues();

				if (values.length === 0) {
					continue;
				}

				switch (src.cprNodeTypeId) {
					case RtCprV2.CprNodeType.Announcement:
						dtoRow.announcement = values;
						break;
					case RtCprV2.CprNodeType.AreaCode:
						dtoRow.areaCodes = values;
						break;
					case RtCprV2.CprNodeType.Carrier:
						dtoRow.carrier = values;
						break;
					case RtCprV2.CprNodeType.DayOfWeek:
						dtoRow.daysOfWeek = values;
						break;
					case RtCprV2.CprNodeType.Date:
						dtoRow.dates = values;
						break;
					case RtCprV2.CprNodeType.GoTo:
						dtoRow.goto = values;
						break;
					case RtCprV2.CprNodeType.Lata:
						dtoRow.latas = values;
						break;
					case RtCprV2.CprNodeType.NpaNxx:
						dtoRow.npaNxxs = values;
						break;
					case RtCprV2.CprNodeType.Percent:
						dtoRow.percent = values;
						break;
					case RtCprV2.CprNodeType.SixDigit:
						dtoRow.sixDigits = values;
						break;
					case RtCprV2.CprNodeType.State:
						dtoRow.states = values;
						break;
					case RtCprV2.CprNodeType.TenDigit:
						dtoRow.tenDigits = values;
						break;
					case RtCprV2.CprNodeType.TerminatingNumber:
						if (terminatingNumberOverride) {
							dtoRow.terminatingNumber = [terminatingNumberOverride];
						} else {
							dtoRow.terminatingNumber = values;
						}
						break;
					case RtCprV2.CprNodeType.Time:
						dtoRow.times = values;
						break;
					case RtCprV2.CprNodeType.Switch:
						dtoRow.switches = values;
						break;
				}
			}

			dto.cprRows.push(dtoRow);
		}

		return dto;
	}

	public static RtCprV2DtoToCpr(dto: RtCprV2.CprDto): RtCprV2.Cpr {
		const cpr = new RtCprV2.Cpr(dto.sourceRespOrgId, dto.routingCacheTypeId, dto.routingCacheKey, dto.sourceEffectiveTs, dto.sourceRecVersionId);

		cpr.setValidation(false);

		cpr.setSourceTemplateName(dto.sourceTemplateName);

		cpr.setTargetEffectiveTs(dto.targetEffectiveTs);
		cpr.setTargetTemplateName(dto.targetTemplateName);

		cpr.setInterLataCarriers(dto.interLataCarriers);
		cpr.setIntraLataCarriers(dto.intraLataCarriers);

		cpr.setCprStatus(dto.cprStatusId);
		cpr.setApprovalIndicator(dto.approvalIndicator);

		cpr.setLineQty(dto.lineQty);

		cpr.setContactName(dto.contactName);
		cpr.setContactNumber(dto.contactNumber);

		cpr.setNotes(dto.notes);
		cpr.setSummary(dto.summary);

		cpr.setCompany(dto.company);
		cpr.setTimeZone(dto.timeZone);
		cpr.setDaylightSavings(dto.daylightSavings);

		for (const src of dto.aosLbls) {
			const tgt = cpr.makeAosLabel(src.aosNodeTypeId, src.name);
			if (!tgt) {
				// @TODO WTF
				continue;
			}
			tgt.setValues(src.values);
		}

		for (const src of dto.aosCols) {
			const tgt = cpr.makeAosCol(src.aosNodeTypeId);
			if (!tgt) {
				// @TODO WTF
				continue;
			}
			tgt.setValues(src.values);
		}

		for (const src of dto.cprLbls) {
			const tgt = cpr.makeCprLabel(src.cprNodeTypeId, src.name);
			if (!tgt) {
				// @TODO WTF
				continue;
			}
			tgt.setValues(src.values);
		}

		for (const src of dto.cprCols) {
			const tgt = cpr.makeCprCol(src.cprNodeTypeId);
			if (!tgt) {
				// @TODO WTF
				continue;
			}
			if (src.timeZone) {
				if (tgt.hasTimeZone) {
					tgt.setTimeZone(src.timeZone);
				}
			}
		}

		for (const src of dto.cprRows) {
			const tgt = cpr.makeCprRow();

			if (!tgt) {
				// @TODO WTF
				continue;
			}

			if (src.announcement) tgt.announcement.setValues(src.announcement);
			if (src.areaCodes) tgt.areaCodes.setValues(src.areaCodes);
			if (src.carrier) tgt.carrier.setValues(src.carrier);
			if (src.daysOfWeek) tgt.daysOfWeek.setValues(src.daysOfWeek);
			if (src.dates) tgt.dates.setValues(src.dates);
			if (src.goto) tgt.goto.setValues(src.goto);
			if (src.latas) tgt.latas.setValues(src.latas);
			if (src.npaNxxs) tgt.npaNxxs.setValues(src.npaNxxs);
			if (src.percent) tgt.percent.setValues(src.percent);
			if (src.sixDigits) tgt.sixDigits.setValues(src.sixDigits);
			if (src.states) tgt.states.setValues(src.states);
			if (src.tenDigits) tgt.tenDigits.setValues(src.tenDigits);
			if (src.terminatingNumber) tgt.terminatingNumber.setValues(src.terminatingNumber);
			if (src.times) tgt.times.setValues(src.times);
			if (src.switches) tgt.switches.setValues(src.switches);
		}

		return cpr;
	}

	/**
	 * V2 -> V1
	 */
	public static RtCprV2ToRtCprV1(cprV2: RtCprV2.Cpr): RtCprV1.CprRouting {
		const interLataCarriers = cprV2.getInterLataCarriers();
		const intraLataCarriers = cprV2.getIntraLataCarriers();

		const cprV1: RtCprV1.CprRouting = {
			interLataCarriers: interLataCarriers,
			intraLataCarriers: intraLataCarriers,
			cprStatusId: cprV2.getCprStatus(),
			approvalIndicator: cprV2.getApprovalIndicator(),
			summary: cprV2.getSummary(),
			notes: cprV2.getNotes(),
			contactName: cprV2.getContactName(),
			contactNumber: cprV2.getContactNumber(),
			templateName: cprV2.getTargetTemplateName() || cprV2.getSourceTemplateName() || undefined,
			respOrgId: cprV2.getSourceRespOrgId(),
			aosLabels: cprV2.getAosLabels().map((aosLbl) => aosLbl.getName()),
		};

		const sourceEffectiveTs = cprV2.getSourceEffectiveTs();

		if (sourceEffectiveTs) {
			cprV1.effectiveTs = sourceEffectiveTs;
		}

		/**
		 * Areas of Service
		 */
		for (const [aosType, aos] of cprV2.getAosColEntries()) {
			if (!aos) {
				continue;
			}

			if (!cprV1.areasOfService) {
				cprV1.areasOfService = [];
			}

			const aosTypeId = SomosCprFactory.RtAosV2ToRtAosV1(aosType);
			const aosValues = aos.getRawValues();
			cprV1.areasOfService.push({ aosTypeId, aosValues });
		}

		// CPR

		cprV1.cpr = {
			interLataCarriers: interLataCarriers,
			intraLataCarriers: intraLataCarriers,
			nodeTypes: [],
			nodes: [],
			labels: [],
		};

		const cprRowsV2 = cprV2.getCprRows();
		const cprColsV2 = cprV2.getCprCols();
		const cprLblsV2 = cprV2.getCprLabels();

		// check labels first (it is possible to have labels without rows and cols)
		for (const cprLblV2 of cprLblsV2) {
			cprV1.cpr.labels.push({
				name: cprLblV2.getName(),
				nodeType: cprLblV2.cprNodeTypeId,
				values: cprLblV2.getRawValues(),

			});
		}

		if (cprRowsV2.length === 0 || cprColsV2.length === 0) {
			return cprV1;
		}

		for (const cprColV2 of cprColsV2) {
			cprV1.cpr.nodeTypes.push(cprColV2.cprNodeTypeId);
		}

		for (const cprRowV2 of cprRowsV2) {
			const nodeV1: RtCprV1.CprRow = {};

			for (const cprColV2 of cprColsV2) {
				const rawValues = cprRowV2.getCprCol(cprColV2.cprNodeTypeId)?.getRawValues();

				if (!rawValues || rawValues.length === 0) {
					// do not add empty values
					continue;
				}

				switch (cprColV2.cprNodeTypeId) {
					case RtCprV2.CprNodeType.Announcement:
						nodeV1.announcement = rawValues[0];
						break;
					case RtCprV2.CprNodeType.AreaCode:
						nodeV1.areaCodes = rawValues;
						break;
					case RtCprV2.CprNodeType.Carrier:
						nodeV1.cic = rawValues[0];
						break;
					case RtCprV2.CprNodeType.DayOfWeek:
						nodeV1.daysOfWeek = rawValues;
						break;
					case RtCprV2.CprNodeType.Date:
						nodeV1.dates = rawValues;
						break;
					case RtCprV2.CprNodeType.GoTo:
						nodeV1.goto = rawValues;
						break;
					case RtCprV2.CprNodeType.Lata:
						nodeV1.latas = rawValues;
						break;
					case RtCprV2.CprNodeType.NpaNxx:
						nodeV1.npaNxxs = rawValues;
						break;
					case RtCprV2.CprNodeType.Percent:
						nodeV1.percent = rawValues[0];
						break;
					case RtCprV2.CprNodeType.SixDigit:
						nodeV1.sixDigits = rawValues;
						break;
					case RtCprV2.CprNodeType.State:
						nodeV1.states = rawValues;
						break;
					case RtCprV2.CprNodeType.TenDigit:
						nodeV1.tenDigits = rawValues;
						break;
					case RtCprV2.CprNodeType.TerminatingNumber:
						nodeV1.terminatingNumber = rawValues[0];
						break;
					case RtCprV2.CprNodeType.Time:
						nodeV1.times = rawValues;
						break;
					case RtCprV2.CprNodeType.Switch:
						nodeV1.switches = rawValues;
						break;
				}
			}

			cprV1.cpr.nodes.push(nodeV1);
		}

		return cprV1;
	}

	/**
	 * V1 -> V2
	 */
	public static RtCprV1ToRtCprV2(respOrgId: string, routingCacheKey: string, effectiveTs: Date, cprV1: RtCprV1.CprRouting): RtCprV2.Cpr {
		let routingCacheTypeId: RtCprV2.RoutingCacheTypes;

		if (routingCacheKey.startsWith('*')) {
			routingCacheTypeId = RtCprV2.RoutingCacheTypes.Template;
		} else {
			routingCacheTypeId = RtCprV2.RoutingCacheTypes.TollFree;
		}

		const newCpr = new RtCprV2.Cpr(
			respOrgId,
			routingCacheTypeId,
			routingCacheKey,
			effectiveTs,
		);

		newCpr.setValidation(false);

		if (cprV1.cprStatusId) {
			newCpr.setCprStatus(cprV1.cprStatusId);
		}

		if (cprV1.approvalIndicator) {
			newCpr.setApprovalIndicator(cprV1.approvalIndicator);
		}

		if (cprV1.interLataCarriers) {
			newCpr.setInterLataCarriers(cprV1.interLataCarriers);
		}

		if (cprV1.intraLataCarriers) {
			newCpr.setIntraLataCarriers(cprV1.intraLataCarriers);
		}

		newCpr.determineAndSetIfCarriersAreInSync();

		if (cprV1.templateName) {
			newCpr.setSourceTemplateName(cprV1.templateName);
			newCpr.setTargetTemplateName(cprV1.templateName);
		}

		let cprIdx: number = 0;

		if (cprV1.summary) {
			newCpr.setSummary(cprV1.summary);
		}
		if (cprV1.notes) {
			newCpr.setNotes(cprV1.notes);
		}
		if (cprV1.contactName) {
			newCpr.setContactName(cprV1.contactName);
		}
		if (cprV1.contactNumber) {
			newCpr.setContactNumber(cprV1.contactNumber);
		}

		newCpr.setAosLabelsByName(cprV1.aosLabels || []);

		if (cprV1.areasOfService) {
			for (const aos of cprV1.areasOfService) {

				let aosCol: RtCprV2.AosCol;

				switch (aos.aosTypeId) {
					case RtCprV1.AosType.AreaCode:
						aosCol = newCpr.makeAosCol(RtCprV2.AosNodeType.AreaCode);
						break;
					case RtCprV1.AosType.Lata:
						aosCol = newCpr.makeAosCol(RtCprV2.AosNodeType.Lata);
						break;
					case RtCprV1.AosType.Network:
						aosCol = newCpr.makeAosCol(RtCprV2.AosNodeType.Network);
						break;
					case RtCprV1.AosType.States:
						aosCol = newCpr.makeAosCol(RtCprV2.AosNodeType.State);
						break;
				}

				if (aosCol) {
					aosCol.setValues(aos.aosValues);
				}
			}
		}

		if (!cprV1.cpr) {
			newCpr.setValidation(true);
			return newCpr;
		}

		cprIdx = 0;
		for (const nodeTypeId of cprV1.cpr.nodeTypes) {
			newCpr.makeCprCol(nodeTypeId, cprIdx);
			cprIdx++;
		}

		for (const oldLbl of cprV1.cpr.labels) {
			const newLbl = newCpr.makeCprLabel(oldLbl.nodeType, oldLbl.name);
			if (!newLbl) {
				console.error(`Could not makeCprLabel: ${oldLbl.name}`);
				continue;
			}
			newLbl.setValues(oldLbl.values);
		}

		cprIdx = 0;
		for (const oldRow of cprV1.cpr.nodes) {
			const newRow = newCpr.makeCprRow(cprIdx);

			if (oldRow.announcement) newRow.announcement.setValues([oldRow.announcement]);
			if (oldRow.areaCodes) newRow.areaCodes.setValues(oldRow.areaCodes);
			if (oldRow.cic) newRow.carrier.setValues([oldRow.cic]);
			if (oldRow.dates) newRow.dates.setValues(oldRow.dates);
			if (oldRow.daysOfWeek) newRow.daysOfWeek.setValues(oldRow.daysOfWeek);
			if (oldRow.latas) newRow.latas.setValues(oldRow.latas);
			if (oldRow.goto) newRow.goto.setValues(oldRow.goto);
			if (oldRow.npaNxxs) newRow.npaNxxs.setValues(oldRow.npaNxxs);
			if (oldRow.percent) newRow.percent.setValues([oldRow.percent]);
			if (oldRow.sixDigits) newRow.sixDigits.setValues(oldRow.sixDigits);
			if (oldRow.states) newRow.states.setValues(oldRow.states);
			if (oldRow.tenDigits) newRow.tenDigits.setValues(oldRow.tenDigits);
			if (oldRow.terminatingNumber) newRow.terminatingNumber.setValues([oldRow.terminatingNumber]);
			if (oldRow.times) newRow.times.setValues(oldRow.times);
			if (oldRow.switches) newRow.switches.setValues(oldRow.switches);

			cprIdx++;
		}

		newCpr.setValidation(true);
		return newCpr;
	}

}
