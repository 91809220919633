import * as React from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { map } from 'lodash-es';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import {
	ResourceConfigurationIndexResponse,
	ResourceType,
	TFActionResponse
} from 'RtModels';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import { UploadTollFreeCarrierConnect } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/UploadTollFreeCarrierConnect';
import { PriorityRadioButtons } from 'RtUi/components/form/PriorityRadioButtons';
import { Alert } from 'react-bootstrap';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';
import { RtxEmailInput } from 'RtUi/components/rtx/inputs/RtxEmailInput';

enum CarrierConnectActionsEnum {
	Add = 'Add',
	Remove = 'Remove',
	Reroute = 'Reroute'
}

interface IUploadCarrierConnectFormProps {
	onUpdate: (res: TFActionResponse | void) => void;
}

interface IUploadCarrierConnectFormState {
	error?: any;
	isSubmitting: boolean;
	loadingTfns: boolean;
	disableWizardActions: boolean;
	currentStep: number;
	jobName: string;
	carriers: ResourceConfigurationIndexResponse[];
	priority?: number;
	tfns: string[];
	action?: CarrierConnectActionsEnum;
	hasTaskTypeId: number[] | null;
	showTfnsWarning: boolean;
	emailList: string[];
}

export class UploadCarrierConnectForm extends React.Component<
	IUploadCarrierConnectFormProps,
	IUploadCarrierConnectFormState
> {
	public state: IUploadCarrierConnectFormState = {
		error: undefined,
		isSubmitting: false,
		loadingTfns: false,
		currentStep: 1,
		priority: 2,
		disableWizardActions: false,
		jobName: '',
		carriers: [],
		tfns: [],
		hasTaskTypeId: [],
		action: undefined,
		showTfnsWarning: false,
		emailList: []
	};

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { jobName, carriers, action, tfns, priority, emailList } = this.state;

		if (!carriers.length) {
			return;
		}

		const resourceConfigurationIds = map(carriers, 'resourceConfigurationId');
		this.setState({ isSubmitting: true, error: undefined });

		let response: TFActionResponse | undefined;

		try {
			const uploadTollFreeCarrierConnectResource =
				new UploadTollFreeCarrierConnect();

			const request = {
				jobName,
				resourceConfigurationId: resourceConfigurationIds,
				tfns,
				priority,
				emailList
			};

			if (action === CarrierConnectActionsEnum.Add) {
				response = await uploadTollFreeCarrierConnectResource.activateTollFree(
					request as any
				);
			}

			if (action === CarrierConnectActionsEnum.Remove) {
				response = await uploadTollFreeCarrierConnectResource.deleteTollFree(
					request as any
				);
			}

			if (action === CarrierConnectActionsEnum.Reroute) {
				response = await uploadTollFreeCarrierConnectResource.rerouteTollFree(
					request as any
				);
			}

			this.props.onUpdate(response);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public handleRadioButtonToggle = async (
		action?: CarrierConnectActionsEnum
	) => {
		if (action === CarrierConnectActionsEnum.Add) {
			this.setState({ hasTaskTypeId: null }, () => {
				this.setState({ hasTaskTypeId: [8] });
			});
		} else if (action === CarrierConnectActionsEnum.Remove) {
			this.setState({ hasTaskTypeId: null }, () => {
				this.setState({ hasTaskTypeId: [9] });
			});
		} else if (action === CarrierConnectActionsEnum.Reroute) {
			this.setState({ hasTaskTypeId: null }, () => {
				this.setState({ hasTaskTypeId: [11] });
			});
		}
		this.setState({ action, carriers: [] });
	};

	public handleTfnsAdd(tfns: string[]) {
		if (tfns.length > 500) {
			return this.setState({
				tfns,
				priority: undefined,
				showTfnsWarning: true
			});
		}

		this.setState({ tfns, showTfnsWarning: false });
	}

	public render() {
		return (
			<RtUiNarrowForm
				createMode={true}
				isSubmitting={this.state.isSubmitting}
				displayMode={false}
				error={this.state.error}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<RadioFormControl<IUploadCarrierConnectFormState['action']>
					required
					vertical
					label="Action Type"
					onChange={(action) => this.handleRadioButtonToggle(action)}
					value={this.state.action}
					options={[
						{
							label: 'Activation (Add to Resource)',
							value: CarrierConnectActionsEnum.Add
						},
						{
							label: 'Disconnect (Remove from Resource)',
							value: CarrierConnectActionsEnum.Remove
						},
						{
							label: 'Reroute (Move to new Resource)',
							value: CarrierConnectActionsEnum.Reroute
						}
					]}
				/>
				<ResourceConfigurationSelect<true>
					multi
					required
					label="Resources"
					appendDropdownToBody
					resourceType={ResourceType.CarrierConnect}
					onChange={(carriers) => {
						this.setState({ carriers });
						carriers.map((carrier) => {
							if (carrier.emailList) {
								this.setState({
									emailList: [
										...new Set([...this.state.emailList, ...carrier.emailList])
									]
								});
							}
						});
					}}
					disabled={
						!this.state.hasTaskTypeId || !this.state.hasTaskTypeId.length
					}
					value={this.state.carriers}
					resourceParams={{
						isActive: 1,
						hasTaskTypeId: this.state.hasTaskTypeId as any
					}}
					filterOption={(option) => {
						this.state.carriers.map((carrier) => {
							if (carrier.resourceId === option.data.resourceId) {
								return false;
							}
						});
						return true;
					}}
				/>
				<br />
				<RtxEmailInput
					isMulti={true}
					label="Job Completion email addresses"
					onChange={(emailList) => this.setState({ emailList })}
					value={this.state.emailList}
					required={false}
					disabled={false}
					displayMode={false}
				/>
				<br />

				<InputFormControl
					placeholder={
						this.state.action === CarrierConnectActionsEnum.Remove
							? 'Toll-Free Disconnect'
							: 'Toll-Free Activation'
					}
					label="Job Name"
					onChange={(jobName) => this.setState({ jobName })}
					value={this.state.jobName}
					maxLength={32}
				/>
				<TollFreeNumbersTextArea
					label="Toll-Free Numbers"
					required={true}
					allowForUpload
					loading={this.state.loadingTfns}
					displayMode={false}
					onChange={(tfns) => this.handleTfnsAdd(tfns)}
					value={this.state.tfns}
					maxLimit={10000}
				/>
				{this.state.showTfnsWarning && (
					<Alert variant="warning">
						<article>
							You have entered more than 500 numbers. Jobs of this size may take
							a considerable amount of time, and may create backlogs with your
							vendors (preventing smaller jobs from completing sooner). Please
							select your desired priority, and consider the &apos;Low (when
							idle)&apos; priority if its applicable to this job.
						</article>
					</Alert>
				)}
				<PriorityRadioButtons
					useDefaultValue
					vertical
					required
					onChange={(priority) => this.setState({ priority })}
					value={this.state.priority}
				/>
			</RtUiNarrowForm>
		);
	}
}
