/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum IntegrationTaskType {
    DATA_INITIALIZATION = 0,
    TOLLFREE_SEARCH_QTY = 1,
    TOLLFREE_SEARCH_LIST = 2,
    TOLLFREE_RESERVE_QTY = 3,
    TOLLFREE_RESERVE_LIST = 4,
    TOLLFREE_QUERY_LIST = 5,
    TOLLFREE_STATUS_CHANGE = 6,
    TOLLFREE_RESPORG_CHANGE = 8,
    TOLLFREE_ACTIVATION = 9,
    TOLLFREE_DISCONNECT = 11,
    TOLLFREE_REROUTE = 10,
    TOLLFREE_SPARE = 12,
    ROUTING_GROUP_ACTIVATE = 44,
    TEMPLATE_INDEX = 45,
    TEMPLATE_SCP_INDEX = 23,
    TEMPLATE_CPR_CLONE = 13,
    TEMPLATE_CPR_COPY = 14,
    TEMPLATE_CPR_CREATE = 16,
    TEMPLATE_CPR_DELETE = 42,
    TEMPLATE_CPR_DISCONNECT = 52,
    TEMPLATE_CPR_INDEX = 43,
    TEMPLATE_CPR_PROFILE = 17,
    TEMPLATE_CPR_TRANSFER = 15,
    TEMPLATE_CPR_UPDATE = 89,
    TEMPLATE_SCP_RESEND = 62,
    TOLLFREE_CPR_COPY = 63,
    TOLLFREE_CPR_CREATE = 64,
    TOLLFREE_CPR_DELETE = 65,
    TOLLFREE_CPR_DISCONNECT = 66,
    TOLLFREE_CPR_INDEX = 67,
    TOLLFREE_CPR_PROFILE = 68,
    TOLLFREE_CPR_TRANSFER = 69,
    TOLLFREE_CPR_UPDATE = 24,
    TOLLFREE_CAD_COPY = 19,
    TOLLFREE_CAD_CREATE = 21,
    TOLLFREE_CAD_DELETE = 50,
    TOLLFREE_CAD_DISCONNECT = 46,
    TOLLFREE_CAD_INDEX = 48,
    TOLLFREE_CAD_PROFILE = 22,
    TOLLFREE_CAD_TRANSFER = 20,
    TOLLFREE_CAD_UPDATE = 70,
    TOLLFREE_CAD_RESPORG_CHANGE = 58,
    TOLLFREE_PAD_COPY = 61,
    TOLLFREE_PAD_CREATE = 57,
    TOLLFREE_PAD_DELETE = 53,
    TOLLFREE_PAD_DISCONNECT = 49,
    TOLLFREE_PAD_INDEX = 51,
    TOLLFREE_PAD_PROFILE = 59,
    TOLLFREE_PAD_TRANSFER = 54,
    TOLLFREE_PAD_UPDATE = 71,
    TOLLFREE_PAD_RESPORG_CHANGE = 55,
    TOLLFREE_PAD_TO_CAD = 56,
    TOLLFREE_CAD_TO_PAD = 80,
    TOLLFREE_UNSOLICITED_ACT = 81,
    TOLLFREE_UNSOLICITED_APP = 82,
    TOLLFREE_UNSOLICITED_ASL = 83,
    TOLLFREE_UNSOLICITED_CRA = 84,
    TOLLFREE_UNSOLICITED_RCH = 85,
    TOLLFREE_UNSOLICITED_RSV = 86,
    TOLLFREE_UNSOLICITED_SCP = 87,
    TOLLFREE_UNSOLICITED_SNA = 25,
    LCR_ACTIVATION_TEMPLATE = 26,
    LCR_ACTIVATION_TOLL_FREE = 29,
    TOLLFREE_COMPLETION = 30,
    DID_INDEX_LIST = 31,
    DID_PROFILE_LIST = 32,
    DID_SEARCH_QTY = 33,
    DID_SEARCH_LIST = 34,
    DID_ACTIVATE_LIST = 102,
    DID_ASSIGN_LIST = 35,
    DID_DEACTIVATE_LIST = 104,
    DID_REROUTE_LIST = 105,
    DID_RESERVE_QTY = 36,
    TOLLFREE_INDEX_LIST = 37,
    TOLLFREE_PROFILE_LIST = 38,
    TOLLFREE_SEARCH_RESERVE_QTY = 39,
    TOLLFREE_SEARCH_RESERVE_WILDCARD = 40,
    TOLLFREE_TROUBLE = 72,
    TOLLFREE_SCP_INDEX = 88,
    TOLLFREE_SCP_RESEND = 90,
    IDDD_INDEX = 91,
    IDDD_PROFILE = 92,
    IDDD_SEARCH_QTY = 93,
    IDDD_SEARCH_LIST = 94,
    IDDD_ACTIVATE = 95,
    IDDD_DEACTIVATE = 103,
    IDDD_ASSIGN_LIST = 100,
    PORTING_PORT_IN = 101,
    PORTING_PORT_OUT = 106,
    DATA_LOOKUP = 107,
    DATA_ENHANCE = 500,
    SOMOS_GUN_PROFILE = 501,
    SOMOS_ROC_INDEX = 502,
    SOMOS_ROC_PROFILE = 503,
    SOMOS_ROC_DOCUMENT_GET = 504,
    SOMOS_ROC_CANCEL = 505,
    SOMOS_ROC_CREATE_LOA = 506,
    SOMOS_ROC_FILE_UPLOAD = 507,
    SOMOS_ROC_DOCUMENT_UPLOAD = 508,
    SOMOS_ROC_CREATE = 509,
    SOMOS_ROC_LOA_UPLOAD = 510,
    SOMOS_ROC_ACTION = 511,
    SOMOS_ROC_REMOVE_TFN = 512,
    TEMPLATE_IVR_PROFILE = 513,
    TOLLFREE_IVR_PROFILE = 514,
    RESERVATION_LIMITS = 515,
    SOMOS_GUN_INDEX = 516,
    SOMOS_ASL_INDEX = 517,
    SOMOS_ASL_PROFILE = 1000,
    INTEGRATION_LOGIN = 1001,
    INTEGRATION_PASSWORD_UPDATE = 2000,
    MISC_ORDER_DETAIL = 2001,
    MISC_LIST_NPA_NXX = 2002,
    MISC_LIST_BULK_JOBS = 2003,
    MISC_GET_COUNTRIES = 2004,
    MISC_GET_NANPA_PREFIXES = 2005,
    LUMEN_DID_RATE_CENTER_LIST = 5000,
    MINUTE_PROFILE_IMPORT = 5001,
    PROCESS_MESSAGE = 5002,
    SOMOS_TASK = 5003,
    RATE_LOAD_PROCESS = 5004,
    LCR_TASK = 5005,
    CPR_RATE_SHEET = 5006,
    CALL_SIMULATE = 5007,
    INTEGRATION_WORKER = 5008,
    CDR_FILE_PROCESSOR = 5009,
    FILE_PROCESSOR = 5010,
    CACHE_LOADER = 5011,
    OPENSIPS_SYNC = 5012,
    REPORT_PROCESS = 5013,
    JOBS_PROCESS = 5014,
    CDR_EXPORT = 5015,
    EVENT_PROCESS = 5016,
    DOWNLOADER_PROCESS = 5017,
    XFS_CDR_PROCESSOR = 5018,
    DASHBOARD_PROCESS = 5019,
    VUE_ARCHIVER = 5020,
}