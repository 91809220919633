import {
	LcrCarrierIndexResponse,
	LcrCarrierRatesLookupIndexRequest
} from 'RtModels';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { FC, useState } from 'react';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { CarriersRatesRouter } from 'RtUi/app/rtLco/CarriersRates/CarriersRates.router';
import { CarriersRatesGrid } from 'RtUi/app/rtLco/CarriersRates/lib/grids/CarriersRatesGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { LcrCarrierSelect } from 'RtUi/app/rtLco/Carriers/lib/controls/LcrCarrierSelect';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';

export const CarriersRatesIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' }
	});
	const [lcrCarriersSelected, setLcrCarriersSelected] =
		useState<LcrCarrierIndexResponse[]>();
	const [npaNxx, setNpaNxx] = useState<string>();
	const resourceParams: LcrCarrierRatesLookupIndexRequest = {};

	if (lcrCarriersSelected) {
		resourceParams.carrierIds = lcrCarriersSelected.map(
			(lcrCarrier) => lcrCarrier.lcrCarrierId
		);
	}

	if (npaNxx) {
		resourceParams.rateKey = npaNxx;
	}
	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={CarriersRatesRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					isSubmitDisabled={!npaNxx && !lcrCarriersSelected}
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<LcrCarrierSelect<true>
						value={lcrCarriersSelected}
						required={!npaNxx && !lcrCarriersSelected}
						multi
						label="Carrier"
						onChange={(value) => {
							setLcrCarriersSelected(value);
						}}
					/>
					<InputFormControl
						type="number"
						required={!npaNxx && !lcrCarriersSelected}
						maxLength={6}
						label="Rate key"
						value={npaNxx}
						onChange={(val) => {
							setNpaNxx(val);
						}}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab
				header={tabs.Results.header}
				isDisabled={!npaNxx && !lcrCarriersSelected}
			>
				<CarriersRatesGrid
					resourceParams={{
						pageSize: RtxPageSizes.SoftAll,
						...resourceParams
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CarriersRatesIndexContainer.displayName = 'CarriersRatesIndexContainer';

CarriersRatesRouter.setIndexRtUiFunctionalComponent(
	CarriersRatesIndexContainer
);
