import { NumberIndexResponse } from 'RtModels';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';
import {
	ApplicationNotificationTypes,
	ApplicationNotifications,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';
import {
	IDidNumberIndex,
	IDidNumberIndexRequest
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import {
	fetchDidNumbers,
	useGetDidNumbers
} from 'RtUi/app/rtDid/Numbers/lib/services';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDidNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/numbers';
import { FC, useMemo } from 'react';

interface IDidNumbersGridProps {
	onRowClick?: (row: IDidNumberIndex) => void;
	resourceParams?: IDidNumberIndexRequest;
	autoFocusFilter?: boolean;
	disableReferences?: RtUiRouter[];
}

export const DidNumbersGrid: FC<
	React.PropsWithChildren<IDidNumbersGridProps>
> = ({ resourceParams, onRowClick }) => {
	const { data, isFetching: isLoading } = useGetDidNumbers(resourceParams);
	const columns = useMemo(() => getDidNumbersColumns(), []);

	const exportToEmail = async () => {
		try {
			await fetchDidNumbers({
				...resourceParams,
				pageSize: RtxPageSizes.AllAll,
				page: 0,
				emailCsv: true
			});
			const notification: INotification = {
				message: 'A link to your report will be emailed to you when complete.',
				title: 'Export will be emailed',
				notificationId: `CC-Numbers-Numbers-Export-${Math.random()}`,
				notificationTypeId: ApplicationNotificationTypes.Info,
				effectiveTs: new Date(),
				expirationTs: new Date(),
				isUrgent: 0
			};

			ApplicationNotifications.openNotification(notification, {
				autoClose: 10_000
			});
		} catch (e) {}
	};

	return (
		<DataGrid<NumberIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			onRowClick={onRowClick}
			exportToEmail={exportToEmail}
			disableExport={
				(data?.totalCount && data.totalCount > 1_000_000) || undefined
			}
			pageName={'rtDid_numbers'}
		/>
	);
};
