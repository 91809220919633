/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FirebaseMessage = {
    properties: {
        messageType: {
            type: 'FirebaseMessageType',
            isRequired: true,
        },
        severity: {
            type: 'FirebaseMessageSeverity',
            isRequired: true,
        },
        productId: {
            type: 'ProductId',
            isRequired: true,
        },
        routeType: {
            type: 'FirebaseRouteType',
            isNullable: true,
        },
        routeTypeId: {
            type: 'string',
            isNullable: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        createTs: {
            type: 'number',
            isRequired: true,
        },
        expireTs: {
            type: 'number',
            isRequired: true,
        },
        userHasRead: {
            type: 'boolean',
            isRequired: true,
        },
        content: {
            properties: {
            },
            isRequired: true,
        },
        additionalData: {
            properties: {
            },
            isNullable: true,
        },
        referenceId: {
            type: 'string',
        },
        isExpired: {
            type: 'boolean',
        },
        partitionId: {
            type: 'number',
        },
        partitionUserId: {
            type: 'number',
        },
    },
};