import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

type LabelDataGridColumnConfiguration<T> = Omit<
	DataGridColumnConfiguration<T>,
	'header'
> &
	FallbackHeaderDataGridColumnConfiguration<T>;

export const LabelDataGridColumn = <T = any,>({
	header = 'Label',
	size = 250,
	align = DataGridColumnAlignment.LEFT,
	...config
}: LabelDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		header,
		size,
		align,
		...config
	});
};
