import {
	CprRateSheetIndexResponse,
	CprRateSheetResultsProfileResponse
} from 'RtModels';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CostDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CostDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { Button } from 'react-bootstrap';

export const getCprRateSheetColumns = (): Array<
	DataGridColumn<CprRateSheetIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'cprRateSheetId'
	}),
	DefaultDataGridColumn({
		header: 'Key',
		getValue: ({ row }) => {
			const data = row.original as CprRateSheetIndexResponse;
			if (data.routingCacheTypeId !== 4) {
				return data.routingCacheKey;
			}

			return (
				<Button
					variant="link"
					className="p-0"
					style={{ fontSize: '12px' }}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						const path = `/rtLco/lcrTasks/${data.taskId}?tab=Profile`;
						ApplicationContainer.openNewTab(path);
					}}
				>
					{data.routingCacheKey} - LCO Task
				</Button>
			);
		}
	}),
	IntegerDataGridColumn({
		accessorKey: 'cprMatchQty',
		header: 'Match Qty'
	}),
	IntegerDataGridColumn({
		accessorKey: 'cprUnknownQty',
		header: 'Unknown Qty'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startedAt',
		header: 'Started At'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedAt',
		header: 'Updated At'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'completedAt',
		header: 'Completed At'
	})
];

export const getCprRateSheetResultsColumns = (
	rateKeyLabels: string[]
): Array<DataGridColumn<CprRateSheetResultsProfileResponse>> => {
	const rateKeysColumns = rateKeyLabels.map((label, index) =>
		DefaultDataGridColumn({
			accessorKey: `rateKey${index + 1}`,
			header: label
		})
	);

	return [
		DefaultDataGridColumn({
			accessorKey: 'rateKey',
			header: 'Rate Key'
		}),
		...(rateKeysColumns as Array<
			DataGridColumn<CprRateSheetResultsProfileResponse>
		>),
		DefaultDataGridColumn({
			accessorKey: 'ocnType',
			header: 'OCN Type'
		}),
		DefaultDataGridColumn({
			accessorKey: 'state',
			header: 'State'
		}),
		DefaultDataGridColumn({
			accessorKey: 'country',
			header: 'Country',
			isHidden: true
		}),
		DefaultDataGridColumn({
			accessorKey: 'cprRowIndex',
			header: 'CPR Row'
		}),
		DefaultDataGridColumn({
			accessorKey: 'cic',
			header: 'CIC'
		}),
		RateDataGridColumn({
			accessorKey: 'costPerMinute',
			header: 'Cost Per Minute'
		}),
		RateDataGridColumn({
			accessorKey: 'interRate',
			header: 'Inter Rate'
		}),
		RateDataGridColumn({
			accessorKey: 'intraRate',
			header: 'Intra Rate'
		}),
		RateDataGridColumn({
			accessorKey: 'indetRate',
			header: 'Indet Rate'
		}),
		CostDataGridColumn({
			accessorKey: 'interCost',
			header: 'Inter Cost',
			showFooter: true
		}),
		CostDataGridColumn({
			accessorKey: 'intraCost',
			header: 'Intra Cost',
			showFooter: true
		}),
		CostDataGridColumn({
			accessorKey: 'indetCost',
			header: 'Indet Cost',
			showFooter: true
		}),
		MinutesDataGridColumn({
			accessorKey: 'totalMins',
			header: 'Total Minutes',
			showFooter: true
		}),
		CostDataGridColumn({
			accessorKey: 'totalCost',
			header: 'Total Cost',
			showFooter: true
		})
	];
};
