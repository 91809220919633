/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceConfigurationCreateRequest = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        emailList: {
            properties: {
            },
            isNullable: true,
        },
        configuration: {
            type: 'AvoxiResourceConfiguration | BandwidthResourceConfiguration | BlacklistAllianceResourceConfiguration | BlitzResourceConfiguration | Call48ResourceConfiguration | DidWwResourceConfiguration | EndstreamResourceConfiguration | FlowrouteResourceConfiguration | FractelResourceConfiguration | InteliquentResourceConfigurationDid | InteliquentResourceConfigurationTollFree | LayeredResourceConfiguration | LingoResourceConfiguration | LumenApiResourceConfiguration | MyHealthAngelResourceConfiguration | NusoResourceConfiguration | PeerlessResourceConfiguration | PlivoResourceConfiguration | RouteTrustTaggingResourceConfiguration | SkyetelResourceConfiguration | TeleApiResourceConfiguration | TelnyxResourceConfiguration | ThinqResourceConfiguration | ThreeEightTwoResourceConfiguration | ThreeThreeResourceConfiguration | TwilioResourceConfiguration | V1VoipResourceConfiguration | VerizonResourceConfiguration | VitcomResourceConfiguration | VitelityResourceConfiguration | VoipInnovationsResourceConfiguration | VoipMsResourceConfiguration | VoxboneResourceConfiguration | WestResourceConfiguration',
            isRequired: true,
        },
    },
};