/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DownloadTypeId {
    TrafficProfile = 1,
    TollFrees = 2,
    ResourceNumbers = 3,
}