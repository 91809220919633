import { TaskProfileResponse } from 'RtModels';
import { TaskProfile } from 'RtUi/app/rt800/Tasks/lib/components/TaskProfile';
import { TaskWarning } from 'RtUi/app/rt800/Tasks/lib/components/TaskWarning';
import { TaskNumbersGrid } from 'RtUi/app/rt800/Tasks/lib/grids/TaskNumbersGrid';
import { useRefetchTask } from 'RtUi/app/rt800/Tasks/lib/hooks';
import {
	useGetTask,
	useGetTaskNumbers,
	useGetTaskType
} from 'RtUi/app/rt800/Tasks/lib/service';
import { renderDashboardStats } from 'RtUi/app/rt800/Tasks/lib/utils';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useEffect, useMemo, useState } from 'react';
import { TaskContainerTabs, TaskRouter } from './Task.router';
import { TaskAction } from './lib/components/TaskAction';
import { TaskErrorsGrid } from './lib/grids/TaskErrorsGrid';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';

export interface ITaskProfile extends TaskProfileResponse {
	hasActivations: boolean;
	totalNumbersActivated: number;
	hasNumbers: boolean;
}

export const TaskProfileContainer = () => {
	const [tabsList, setTabList] =
		useState<IProfileApplicationContainerTabs>(TaskContainerTabs);
	const taskId = Number(useIdParam());
	const { data: task, isLoading, refetch } = useGetTask({ taskId });
	const {
		data: taskNumbers,
		isLoading: isLoadingNumbers,
		refetch: refetchNumbers
	} = useGetTaskNumbers({ taskId }, { pageSize: RtxPageSizes.SoftAll });

	const { data: taskType } = useGetTaskType(
		{ taskTypeId: task?.taskTypeId ?? 0 },
		task !== undefined
	);
	const [tabs, activeTab, setActiveTab] = useTabs(tabsList);
	const actionTfns = useMemo(
		() =>
			taskNumbers?.data
				.filter((taskNumber) => taskNumber.tfn !== null)
				.map((taskNumber) => taskNumber.tfn) ?? [],
		[taskNumbers]
	);

	const taskProfile: ITaskProfile | undefined = useMemo(
		() =>
			task && {
				...task,
				totalNumbersActivated: (taskNumbers?.data ?? []).filter(
					(taskNumber) => taskNumber.activationTs !== null
				).length,
				hasActivations: taskType?.hasActivationTs === 1,
				hasNumbers: Boolean(taskType?.hasNumbers)
			},
		[task, taskNumbers, taskType]
	);

	useEffect(() => {
		if (taskProfile) {
			setTabList((currState) => {
				const newState = { ...currState };

				const actionTabs = TaskRouter.getActionTabs(taskProfile);

				for (const tab of actionTabs) {
					newState[tab.header] = tab;
				}

				return newState;
			});
		}
	}, [taskProfile]);

	useRefetchTask(taskProfile, () => {
		refetch();
		refetchNumbers();
	});

	if (isLoading || isLoadingNumbers) {
		return <Loading />;
	}

	if (!taskProfile) {
		return <p>Not Found!</p>;
	}

	const onUpdateTask = () => {
		refetch();
		refetchNumbers();
	};

	return (
		<TabbedLayout<TaskProfileResponse>
			router={TaskRouter}
			profile={task}
			actionsHeader="Next Task"
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
			appendToHeader={<TaskWarning profile={taskProfile} />}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				{renderDashboardStats(taskProfile, () =>
					setActiveTab(tabs.Alerts.header)
				)}
				<TaskProfile taskProfile={taskProfile} onUpdateTask={onUpdateTask} />
			</TabbedLayoutTab>
			{taskNumbers && (
				<TabbedLayoutTab {...tabs.Numbers}>
					{renderDashboardStats(taskProfile, () =>
						setActiveTab(tabs.Alerts.header)
					)}
					<TaskNumbersGrid
						task={taskProfile}
						taskNumbers={taskNumbers.data}
						totalCount={taskNumbers.totalCount}
					/>
				</TabbedLayoutTab>
			)}
			<TabbedLayoutTab {...tabs.Alerts}>
				<TaskErrorsGrid taskId={taskProfile.taskId} />
			</TabbedLayoutTab>
			{TaskRouter.getActionTabs(taskProfile).map((actionTab) => (
				<TabbedLayoutTab {...actionTab} key={actionTab.header}>
					{isLoadingNumbers && <Loading internal />}
					{!isLoadingNumbers && (
						<TaskAction
							type={actionTab.header}
							defaultTollFreeNumbers={actionTfns}
							defaultRespOrgId={taskProfile.respOrgId}
						/>
					)}
				</TabbedLayoutTab>
			))}
		</TabbedLayout>
	);
};

TaskRouter.setProfileRtUiFunctionalComponent(TaskProfileContainer);
