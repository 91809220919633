/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FirebaseCreateRequest = {
    properties: {
        messageType: {
            type: 'FirebaseMessageType',
            isRequired: true,
        },
        severity: {
            type: 'FirebaseMessageSeverity',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        content: {
            properties: {
            },
            isRequired: true,
        },
        additionalData: {
            properties: {
            },
            isNullable: true,
        },
        productId: {
            type: 'ProductId',
        },
        targetType: {
            type: 'FirebaseTargetType',
            isRequired: true,
        },
        targetTypeId: {
            type: 'number',
            isRequired: true,
        },
        setExpireMs: {
            type: 'number',
            isRequired: true,
        },
    },
};