// ********************************************************************
// When making changes, also change src/common/Constants.ts in rtx-core
// ********************************************************************
export enum RtxPageSizes {
	Default = 5_000,
	SoftAll = 100_000,
	ExcelMax = 1_000_000,
	HardAll = 2_000_000,
	AllAll = 10_000_000
}
