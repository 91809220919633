/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { FirebaseMessage } from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSystemNotifications = async () => {
	const data = (await handleGetRequest(
		RtAdmApiRoutes.Firebase.Index
	)) as unknown as FirebaseMessage[];
	if (data && Array.isArray(data)) {
		return data;
	}
	return [];
};

export const useGetSystemNotifications = () => {
	return useQuery<FirebaseMessage[], Error>('getSystemNotifications', () => {
		return fetchSystemNotifications() || [];
	});
};
