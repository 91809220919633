import { TextField, TextFieldProps } from '@mui/material';
import { noop } from 'lodash-es';

export type RtxTextInputProps = Omit<TextFieldProps, 'variant' | 'onChange'> & {
	displayMode?: boolean;
	onChange?: (value: string) => void;
};

export const RtxTextInput = ({
	sx,
	displayMode,
	disabled,
	onChange = noop,
	...props
}: RtxTextInputProps) => {
	return (
		<TextField
			{...props}
			onChange={(evt) => onChange(evt.target.value)}
			sx={{
				'width': '100%',
				'.MuiInputBase-root': {
					'marginTop': '0',
					'padding': '10px 14px 5px',
					'.MuiInputBase-input': {
						'padding': 0,
						'&.Mui-disabled': {
							fontWeight: 550,
							color: '#9c9c9c',
							WebkitTextFillColor: 'unset'
						}
					}
				},
				...sx
			}}
			InputLabelProps={{
				shrink: true
			}}
			disabled={disabled || displayMode}
			variant="outlined"
		/>
	);
};
