export type HttpMethodTypes = 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH';

export enum Permissions {
    RtxAdministrator = 1,
    PartitionAdministrator = 2,
    UserManager = 3,
    PartitionBusinessOperations = 4,
    PartitionBusinessProvisioning = 19,
    PartitionBusinessReporting = 10,
    PartitionNetworkOperations = 5,
    PartitionNetworkProvisioning = 7,
    Basic = 8,
    PartitionNetworkReporting = 6,
    PartitionGeneralOperations = 14,
    PartitionGeneralProvisioning = 18,
    PartitionGeneralReporting = 17,
    CustomerPortal = 50,
    CustomerPortalReports = 51,
    CustomerPortalFinance = 52,
    TollFreeAdministrator = 100,
    TollFreeOperator = 105,
    TollFreeEntityManager = 110,
    TollFreeEntityOperator = 111,
    TollFreeRespOrgManager = 115,
    TollFreeRespOrgOperator = 116,
    TollFreeTemplateManager = 120,
    TollFreeTemplateOperator = 121,
    TollFreeRoutingGroupManager = 125,
    TollFreeRoutingGroupOperator = 126,
    TollFreeNumberGroupManager = 130,
    TollFreeNumberGroupOperator = 131,
    TollFreeLcrManager = 135,
    TollFreeLcrOperator = 136,
    TollFreeNumberActivate = 140,
    TollFreeNumberDisconnect = 141,
    TollFreeNumberQuery = 142,
    TollFreeNumberReRoute = 143,
    TollFreeNumberReserve = 144,
    TollFreeNumberRespOrgChangeTask = 145,
    TollFreeNumberSearch = 146,
    TollFreeNumberSpare = 147,
    TollFreeNumberRespOrgChangeRoc = 148,
    TollFreeBulkActivate = 150,
    TollFreeBulkDisconnect = 151,
    TollFreeBulkQuery = 152,
    TollFreeBulkReRoute = 153,
    TollFreeBulkReserve = 154,
    TollFreeBulkRespOrgChange = 155,
    TollFreeBulkSearch = 156,
    TollFreeBulkSpare = 157,
    VueOperator = 160,
    VueManager = 161,
    VueAdministrator = 162,
    VueRatingOperator = 163,
    VueRatingManager = 164,
    VueRatingAdministrator = 165,
    VueTfEnhancedOperator = 166,
    VueTfEnhancedManager = 167,
    VueTfEnhancedAdministrator = 168,
    VueCustomMargin = 169,
    CarrierConnectAdministrator = 170,
    CarrierConnectManager = 171,
    CarrierConnectOperator = 172,
    DidAdministrator = 180,
    DidManager = 181,
    DidOperator = 182,
    GuardianAdministrator = 190,
    GuardianManager = 191,
    GuardianOperator = 192,
    VueReconciliationOperator = 193,
    VueReconciliationManager = 194,
    VueReconciliationAdministrator = 195,
    RtLcrAdministrator = 200,
    RtLcrManager = 201,
    RtLcrOperator = 202,
    WidgetReadAccess = 9001,
    WidgetCreateAccess = 9002,
    WidgetEditAccess = 9003
}

export interface IRtxApiRoute {
    apiExcludeEndpoint?: boolean;
    path: string;
    httpMethod: HttpMethodTypes;
    permissions: Permissions[];
    title: string;
    description: string;
    menuPermissions?: Permissions[];
}

export type RtApiVerbTypes = 'Create' | 'Delete' | 'Index' | 'Profile' | 'Update';

export interface IRtxApiRoutes {
    [moduleName: string]: {
        [V in RtApiVerbTypes]?: IRtxApiRoute;
    };
}

export interface IRtDemoApiRoutes extends IRtxApiRoutes {
    Demos: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
}

export const RtDemoApiRoutes: IRtDemoApiRoutes = {
    Demos: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtDemo/demos',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Demo Index',
            description: 'Retrieve a list of Demos for the specified search criteria.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtDemo/demos/:demoId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Demo Profile',
            description: 'Retrieve the specified Demo.'
        },
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtDemo/demos',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Creates Demo Profile',
            description: 'Create a Demo.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtDemo/demos/:demoId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Updates Demo Profile',
            description: 'Update the specified Demo.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtDemo/demos/:demoId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Deletes Demo Profile',
            description: 'Delete the specified Demo.'
        },
    },
}

export interface IRt800ApiRoutes extends IRtxApiRoutes {
    AuditTemplateCprs: {
        Index: IRtxApiRoute;
    };
    AuditTollFreeCprs: {
        Index: IRtxApiRoute;
    };
    BulkActivate: {
        Create: IRtxApiRoute;
    };
    BulkCprQuery: {
        Create: IRtxApiRoute;
    };
    BulkDisconnect: {
        Create: IRtxApiRoute;
    };
    BulkQuery: {
        Create: IRtxApiRoute;
    };
    BulkReRoute: {
        Create: IRtxApiRoute;
    };
    BulkReserveNumbers: {
        Create: IRtxApiRoute;
    };
    BulkReservePattern: {
        Create: IRtxApiRoute;
    };
    BulkRespOrgChange: {
        Create: IRtxApiRoute;
    };
    BulkSearchNumbers: {
        Create: IRtxApiRoute;
    };
    BulkSearchPattern: {
        Create: IRtxApiRoute;
    };
    BulkSpare: {
        Create: IRtxApiRoute;
    };
    CprNumbers: {
        Index: IRtxApiRoute;
    };
    CprRateSheets: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    CprRateSheetResults: {
        Profile: IRtxApiRoute;
    };
    Entities: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    EntityTotalsByNpa: {
        Index: IRtxApiRoute;
    };
    EntityReservationLimit: {
        Profile: IRtxApiRoute;
    };
    Cics: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    HealthCheck: {
        Profile: IRtxApiRoute;
    };
    LcrAnalysis: {
        Create: IRtxApiRoute;
    };
    LcrCarriers: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    LcrCarrierRates: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    LcrCarrierRatesLookup: {
        Index: IRtxApiRoute;
    };
    LcrCarrierRegions: {
        Update: IRtxApiRoute;
    };
    LcrCarrierBulk: {
        Update: IRtxApiRoute;
    };
    LcrCarrierStates: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    LcrMinutes: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    LcrMinutesData: {
        Index: IRtxApiRoute;
    };
    LcrMinutesDataExportCsv: {
        Index: IRtxApiRoute;
    };
    LcrScenarios: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    LcrTasks: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    LcrTaskCarrierResults: {
        Index: IRtxApiRoute;
    };
    LcrTaskCarrierResultsActivate: {
        Create: IRtxApiRoute;
    };
    LcrTaskResults: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    LcrTemplates: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    NanpRegions: {
        Index: IRtxApiRoute;
    };
    NanpStates: {
        Index: IRtxApiRoute;
    };
    NumberGroups: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    NumberGroupNumbers: {
        Index: IRtxApiRoute;
    };
    NumberGroupNumbersAdd: {
        Update: IRtxApiRoute;
    };
    NumberGroupNumbersRemove: {
        Update: IRtxApiRoute;
    };
    NumberGroupNumbersReplace: {
        Update: IRtxApiRoute;
    };
    RespOrgs: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RespOrgContact: {
        Index: IRtxApiRoute;
    };
    Resources: {
        Index: IRtxApiRoute;
    };
    RespOrgTotalsByNpa: {
        Index: IRtxApiRoute;
    };
    RespOrgTrouble: {
        Profile: IRtxApiRoute;
    };
    Roc: {
        Profile: IRtxApiRoute;
        Index: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    RocAction: {
        Index: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RocLoa: {
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    RocDocument: {
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    RocNumbers: {
        Index: IRtxApiRoute;
    };
    RoutingCache: {
        Profile: IRtxApiRoute;
    };
    RoutingGroups: {
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RoutingGroupTemplates: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RtCprs: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RtCprClone: {
        Update: IRtxApiRoute;
    };
    RtCprCopy: {
        Update: IRtxApiRoute;
    };
    RtDisconnect: {
        Update: IRtxApiRoute;
    };
    RtCprTransfers: {
        Update: IRtxApiRoute;
    };
    Tasks: {
        Profile: IRtxApiRoute;
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TaskErrors: {
        Index: IRtxApiRoute;
    };
    TaskNumbers: {
        Index: IRtxApiRoute;
    };
    TaskNumbersFutureCprs: {
        Index: IRtxApiRoute;
    };
    TaskTypes: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    Templates: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TemplateCprs: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TemplateCprClone: {
        Update: IRtxApiRoute;
    };
    TemplateCprCopy: {
        Update: IRtxApiRoute;
    };
    TemplateCprDisconnect: {
        Update: IRtxApiRoute;
    };
    TemplateCprTransfers: {
        Update: IRtxApiRoute;
    };
    TemplateIvr: {
        Profile: IRtxApiRoute;
    };
    TemplateLcrCarriers: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TemplateLcrCarriersAvailable: {
        Index: IRtxApiRoute;
    };
    TemplateLcrScenarios: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TemplateLcrScenariosAvailable: {
        Index: IRtxApiRoute;
    };
    TemplateNameIsValid: {
        Profile: IRtxApiRoute;
    };
    TemplateScps: {
        Index: IRtxApiRoute;
    };
    TemplateSimulator: {
        Index: IRtxApiRoute;
    };
    TollFrees: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    TollFreesCsv: {
        Index: IRtxApiRoute;
    };
    TollFreesExportCsv: {
        Index: IRtxApiRoute;
    };
    TollFreesDeprecated: {
        Index: IRtxApiRoute;
    };
    TollFreeActionActivate: {
        Create: IRtxApiRoute;
    };
    TollFreeActionDisconnect: {
        Create: IRtxApiRoute;
    };
    TollFreeActionReRoute: {
        Create: IRtxApiRoute;
    };
    TollFreeActionReserve: {
        Create: IRtxApiRoute;
    };
    TollFreeActionRespOrgChange: {
        Create: IRtxApiRoute;
    };
    TollFreeActionSpare: {
        Create: IRtxApiRoute;
    };
    TollFreeHistory: {
        Index: IRtxApiRoute;
    };
    TollFreeQuery: {
        Profile: IRtxApiRoute;
    };
    TollFreeCprs: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TollFreeCprClone: {
        Update: IRtxApiRoute;
    };
    TollFreeCprTransfers: {
        Update: IRtxApiRoute;
    };
    TollFreeCprCopy: {
        Update: IRtxApiRoute;
    };
    TollFreeScp: {
        Index: IRtxApiRoute;
    };
    TollFreeSearch: {
        Profile: IRtxApiRoute;
    };
    TollFreeSimulator: {
        Index: IRtxApiRoute;
    };
    TollFreeStatus: {
        Index: IRtxApiRoute;
    };
    TollFreeTrouble: {
        Profile: IRtxApiRoute;
    };
    CprLerg: {
        Index: IRtxApiRoute;
    };
}

export const Rt800ApiRoutes: IRt800ApiRoutes = {
    AuditTemplateCprs: {
        Index: {
            path: '/rt800/audit/templates/cprs',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeTemplateManager,
                Permissions.TollFreeTemplateOperator,
            ],
            title: 'Toll-Free Template CPR Routing Audit Index',
            description: "Retrieve a list of Template CPR Routing CPR's (Call Processing Records) that are in an undesirable status."
        },
    },
    AuditTollFreeCprs: {
        Index: {
            path: '/rt800/audit/tollFrees/cprs',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeTemplateManager,
                Permissions.TollFreeTemplateOperator,
            ],
            title: 'Toll-Free Number CPR Routing Audit Index',
            description: "Retrieve a list of Toll-Free Number Routing CPR's (Call Processing Records) that are in an undesirable status."
        },
    },
    BulkActivate: {
        Create: {
            path: '/rt800/bulk/activate',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkActivate],
            title: 'Create a Toll-Free Activation Task',
            description: 'Activate a list of Reserved or Assigned Toll-Free Numbers. Note: Toll-Free Numbers assigned to a Template or with existing routing must be moved using the Re-Route.'
        },
    },
    BulkCprQuery: {
        Create: {
            path: '/rt800/bulk/cprQuery',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Create a Toll-Free CPR Query Task',
            description: 'Query the current CPR (Routing) Index for a list Toll-Free Numbers.'
        },
    },
    BulkDisconnect: {
        Create: {
            path: '/rt800/bulk/disconnect',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkDisconnect],
            title: 'Create a Toll-Free Disconnect Task',
            description: 'Disconnect a list of Working Toll-Free Numbers.'
        },
    },
    BulkQuery: {
        Create: {
            path: '/rt800/bulk/query',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkQuery],
            title: 'Create a Toll-Free Query Task',
            description: 'Query the current status of Toll-Free Numbers. Note: A numbers API call can generally provide this information instantly.'
        },
    },
    BulkReRoute: {
        Create: {
            path: '/rt800/bulk/reRoute',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkReRoute],
            title: 'Create a Toll-Free Re-Route Task',
            description: 'Assign numbers to a new routing template.'
        },
    },
    BulkReserveNumbers: {
        Create: {
            path: '/rt800/bulk/reserve/numbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkReserve],
            title: 'Create a Toll-Free Reserve Task',
            description: 'Reserve available numbers from the Toll-Free Number spare pool.'
        },
    },
    BulkReservePattern: {
        Create: {
            path: '/rt800/bulk/reserve/pattern',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkReserve],
            title: 'Create a Toll-Free Reserve Task',
            description: 'Reserve available numbers from the Toll-Free Number spare pool.'
        },
    },
    BulkRespOrgChange: {
        Create: {
            path: '/rt800/bulk/respOrgChange',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkRespOrgChange],
            title: 'Create a Toll-Free RespOrg Change Task',
            description: 'Assign numbers to a new RespOrg (Responsible Organization).'
        },
    },
    BulkSearchNumbers: {
        Create: {
            path: '/rt800/bulk/search/numbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkSearch],
            title: 'Create a Toll-Free Search Task',
            description: 'Search for available numbers in to the Toll-Free Number spare pool.'
        },
    },
    BulkSearchPattern: {
        Create: {
            path: '/rt800/bulk/search/pattern',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkSearch],
            title: 'Create a Toll-Free Search Task',
            description: 'Search for available numbers in to the Toll-Free Number spare pool.'
        },
    },
    BulkSpare: {
        Create: {
            path: '/rt800/bulk/spare',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeBulkSpare],
            title: 'Create a Toll-Free Spare Task',
            description: 'Return numbers in Disconnect, Reserve or Transitional states to the spare pool. This will remove numbers from the specified RespOrg, making them available to other RespOrgs.'
        },
    },
    CprNumbers: {
        Index: {
            path: '/rt800/cprNumbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'CPR Number Index',
            description: 'Retrieve a list of Numbers based on CPR status',
        }
    },
    CprRateSheets: {
        Create: {
            path: '/rt800/cprRateSheets',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Create CPR Rate Sheet',
            description: 'Create new CPR Rate Sheet',
        },
        Delete: {
            path: '/rt800/cprRateSheets/:cprRateSheetId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Delete CPR Rate Sheet',
            description: 'Delete CPR Rate Sheet',
        },
        Index: {
            path: '/rt800/cprRateSheets',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'CPR Rate Sheet Index',
            description: 'Retrieve a list CPR Rate Sheets',
        },
        Profile: {
            path: '/rt800/cprRateSheets/:cprRateSheetId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'CPR Rate Sheet Profile',
            description: 'Retrieve specified CPR Rate Sheet',
        },
    },
    CprRateSheetResults: {
        Profile: {
            path: '/rt800/cprRateSheets/:cprRateSheetId/results',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'CPR Rate Sheet Results Index',
            description: 'Retrieve results from CPR Rate Sheet generation',
        }
    },
    Entities: {
        Create: {
            path: '/rt800/entities',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeAdministrator],
            title: 'Create a Toll-Free Entity',
            description: 'Create a Toll-Free Entity (Note: this will not create the Entity in the Somos system).'
        },
        Index: {
            path: '/rt800/entities',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            menuPermissions: [Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            title: 'Toll-Free Entity Index',
            description: 'Retrieve a list of Toll-Free Entities for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/entities/:entityId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            title: 'Toll-Free Entity Profile',
            description: 'Retrieve the specified Toll-Free Entity.'
        },
        Update: {
            path: '/rt800/entities/:entityId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            title: 'Toll-Free Entity Profile',
            description: 'Update the specified Toll-Free Entity.'
        },
    },
    EntityTotalsByNpa: {
        Index: {
            path: '/rt800/entities/:entityId/totalsByNpa',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            title: 'Entity Toll-Free Totals By NPA',
            description: 'Entity Toll-Free Totals By NPA.'
        },
    },
    EntityReservationLimit: {
        Profile: {
            path: '/rt800/entities/:entityId/reservationLimit',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeEntityOperator, Permissions.TollFreeEntityManager],
            title: 'Toll-Free Entity Reservation Limits',
            description: 'Retrieve Entity Reservation limit data.'
        },
    },
    Cics: {
        Index: {
            path: '/rt800/cics',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Carrier Index',
            description: 'Retrieve a list of Toll-Free Carriers for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/cics/:cic',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Carrier Profile',
            description: 'Retrieve a Toll-Free Carrier.'
        },
    },
    HealthCheck: {
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rt800/healthCheck',
            httpMethod: 'GET',
            permissions: [],
            title: 'Health check of rt800',
            description: 'Health check of rt800',
        }
    },
    LcrAnalysis: {
        Create: {
            path: '/rt800/lcr/costAnalysis',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Create a Cost Analysis',
            description: 'Create a Cost Analysis given a CPR'
        },
    },
    LcrCarriers: {
        Create: {
            path: '/rt800/lcr/carriers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Add a Carrier to a LCR Template.',
            description: 'Add a Carrier to a LCR Template.'
        },
        Delete: {
            path: '/rt800/lcr/carriers/:lcrCarrierId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Remove a Carrier from a LCR Template.',
            description: 'Remove a Carrier from a LCR Template.'
        },
        Index: {
            path: '/rt800/lcr/carriers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Carrier Index',
            description: 'Retrieve a list of Toll-Free LCR Carriers for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/carriers/:lcrCarrierId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Carrier Profile',
            description: 'Retrieve a Toll-Free LCR Carrier.'
        },
        Update: {
            path: '/rt800/lcr/carriers/:lcrCarrierId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Update a Carrier to on a LCR Template.',
            description: 'Update a Carrier on a LCR Template.'
        },
    },
    LcrCarrierRatesLookup: {
        Index: {
            path: '/rt800/lcr/carriersRates',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Carrier Rates Lookup Index',
            description: 'Retrieve a list of rates.'
        }
    },
    LcrCarrierRates: {
        Index: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/rates',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Carrier Rate Index',
            description: 'Retrieve a list of rates for a carrier.'
        },
        Update: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/rates',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Replace all rates for a carrier.',
            description: 'Replace all rates for a carrier.'
        },
    },
    LcrCarrierRegions: {
        Update: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/regions/:nanpRegionId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Replace all rates in a region for a carrier.',
            description: 'Replace all rates in a region for a carrier.'
        },
    },
    LcrCarrierBulk: {
        Update: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/bulk',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Replace all rates for a carrier.',
            description: 'Replace all rates for a carrier.'
        },
    },
    LcrCarrierStates: {
        Index: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/states',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Carrier States Index',
            description: 'Retrieve a list of State defaults for a carrier.'
        },
        Profile: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/states/:nanpStateId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Carrier Profile',
            description: 'Retrieve a Toll-Free LCR Carrier.'
        },
        Update: {
            path: '/rt800/lcr/carriers/:lcrCarrierId/states/:nanpStateId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Update a Carrier to on a LCR Template.',
            description: 'Update a Carrier on a LCR Template.'
        },
    },
    LcrMinutes: {
        Create: {
            path: '/rt800/lcr/minutes',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Minute Profile Create',
            description: 'Create a new Toll-Free LCR Minute Profile.'
        },
        Index: {
            path: '/rt800/lcr/minutes',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Minute Profile Index',
            description: 'Retrieve a list of Toll-Free LCR Minute Profiles for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/minutes/:minuteProfileId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Minute Profile',
            description: 'Retrieve specified Toll-Free LCR Minute Profile.'
        },
        Update: {
            path: '/rt800/lcr/minutes/:minuteProfileId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Update Toll-Free LCR Minute Profile',
            description: 'Update specified Toll-Free LCR Minute Profile.'
        },
    },
    LcrMinutesData: {
        Index: {
            path: '/rt800/lcr/minutes/:minuteProfileId/minutes',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Minute Data',
            description: 'Retrieve all Toll-Free LCR Minute Data.'
        },
    },
    LcrMinutesDataExportCsv: {
        Index: {
            path: '/rt800/lcr/minutes/:minuteProfileId/minutes/exportCsv',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Toll-Free LCR Minute Data CSV Export',
            description: 'Create CSV export of Toll-Free LCR Minute Data.'
        },
    },
    LcrScenarios: {
        Index: {
            path: '/rt800/lcr/scenarios',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Scenario Index',
            description: 'Retrieve a list of Toll-Free LCR Scenarios for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/scenarios/:lcrScenarioId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Scenario Profile',
            description: 'Retrieve a Toll-Free LCR Scenario.'
        },
    },
    LcrTasks: {
        Create: {
            path: '/rt800/lcr/tasks',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Create a Toll-Free LCR Task',
            description: 'Create a Toll-Free LCR Task.'
        },
        Index: {
            path: '/rt800/lcr/tasks',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Retrieve a Toll-Free LCR Task Index',
            description: 'Retrieve a list of Toll-Free LCR Tasks for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/tasks/:lcrTaskId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Retrieve a Toll-Free LCR Task',
            description: 'Retrieve a Toll-Free LCR Task.'
        },
        Update: {
            path: '/rt800/lcr/tasks/:lcrTaskId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Update a Toll-Free LCR Task',
            description: 'Update a Toll-Free LCR Task.'
        }
    },
    LcrTaskCarrierResults: {
        Index: {
            path: '/rt800/lcr/tasks/:lcrTaskId/results/:lcrTaskResultId/carriers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Task Results by Carrier Index',
            description: 'Retrieve a list of Toll-Free LCR Task Results by Carrier for the specified search criteria.'
        }
    },
    LcrTaskResults: {
        Index: {
            path: '/rt800/lcr/tasks/:lcrTaskId/results',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Task Result Index',
            description: 'Retrieve a list of Toll-Free LCR Task Results for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/tasks/:lcrTaskId/results/:lcrTaskResultId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Task Result Profile',
            description: 'Retrieve a Toll-Free LCR Task Result.'
        },
    },
    LcrTaskCarrierResultsActivate: {
        Create: {
            path: '/rt800/lcr/tasks/:lcrTaskId/results/:lcrTaskResultId/activate',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Activate a LCR Template Generation Task Result.',
            description: 'Clone the generated LCR CPR (Call Processing Record) onto a Toll-Free Number or Template.'
        },
    },
    LcrTemplates: {
        Index: {
            path: '/rt800/lcr/templates',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Template Index',
            description: 'Retrieve a list of Toll-Free LCR Templates for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/lcr/templates/:templateName',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeLcrOperator],
            title: 'Toll-Free LCR Template Profile',
            description: 'Retrieve a Toll-Free LCR Template.'
        },
        Update: {
            path: '/rt800/lcr/templates/:templateName',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeLcrManager],
            title: 'Update Toll-Free LCR Template Profile',
            description: 'Update Toll-Free LCR Template.'
        },
    },
    CprLerg: {
        Index: {
            path: '/rt800/cpr/lerg',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Cpr LERG Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) using Area Code, Exchange and Thousand Block Pool filters.'
        }
    },
    NanpRegions: {
        Index: {
            path: '/rt800/nanp/regions',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'NANP Region Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) Regions.'
        }
    },
    NanpStates: {
        Index: {
            path: '/rt800/nanp/states',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'NANP State Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) States.'
        }
    },
    NumberGroups: {
        Create: {
            path: '/rt800/numberGroups',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberGroupManager],
            title: 'Create a Toll-Free Number Group',
            description: 'Create a Toll-Free Number Group.'
        },
        Index: {
            path: '/rt800/numberGroups',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberGroupOperator],
            title: 'Toll-Free Number Group Index',
            description: 'Retrieve a list of Toll-Free Number Groups for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/numberGroups/:numberGroupId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberGroupOperator],
            title: 'Toll-Free Number Group Profile',
            description: 'Retrieve detailed information for the specified Toll-Free Number Group.'
        },
        Update: {
            path: '/rt800/numberGroups/:numberGroupId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeNumberGroupManager],
            title: 'Update a Toll-Free Number Group',
            description: 'Update a Toll-Free Number Group.'
        },
    },
    NumberGroupNumbers: {
        Index: {
            path: '/rt800/numberGroups/:numberGroupId/numbers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberGroupOperator],
            title: 'Toll-Free Number Group Numbers Index',
            description: 'Retrieve a list of Toll-Free Numbers assigned to the specified Toll-Free Number Group.'
        },
    },
    NumberGroupNumbersAdd: {
        Update: {
            path: '/rt800/numberGroups/:numberGroupId/numbers/add',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeNumberGroupManager],
            title: 'Add numbers to an existing Toll-Free Number Group',
            description: 'Add numbers to an existing a Toll-Free Number Group.'
        },
    },
    NumberGroupNumbersRemove: {
        Update: {
            path: '/rt800/numberGroups/:numberGroupId/numbers/remove',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeNumberGroupManager],
            title: 'Remove numbers from an existing Toll-Free Number Group',
            description: 'Remove numbers from an existing Toll-Free Number Group.'
        },
    },
    NumberGroupNumbersReplace: {
        Update: {
            path: '/rt800/numberGroups/:numberGroupId/numbers/replace',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeNumberGroupManager],
            title: 'Replace numbers from an existing Toll-Free Number Group',
            description: 'Replace numbers from an existing Toll-Free Number Group.'
        },
    },
    RespOrgs: {
        Create: {
            path: '/rt800/respOrgs',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeAdministrator],
            title: 'Create a Toll-Free RespOrg',
            description: 'Create a Toll-Free RespOrg (Responsible Organization) (Note: this will not create the RespOrg in the Somos system).'
        },
        Index: {
            path: '/rt800/respOrgs',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            menuPermissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free RespOrg Index',
            description: 'Retrieve a list of Toll-Free RespOrgs (Responsible Organizations) for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/respOrgs/:respOrgId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free RespOrg Profile',
            description: 'Retrieve detailed information for the specified Toll-Free RespOrg (Responsible Organization).'
        },
        Update: {
            path: '/rt800/respOrgs/:respOrgId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free RespOrg Profile',
            description: 'Update the specified Toll-Free RespOrg (Responsible Organization).'
        },
    },
    RespOrgContact: {
        Index: {
            path: '/rt800/respOrgs/:respOrgId/contacts',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'RespOrg Contact Information',
            description: 'RespOrg Contact information.'
        }
    },
    RespOrgTotalsByNpa: {
        Index: {
            path: '/rt800/respOrgs/:respOrgId/totalsByNpa',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'RespOrg Toll-Free Totals By NPA',
            description: 'RespOrg Toll-Free Totals By NPA.'
        },
    },
    RespOrgTrouble: {
        Profile: {
            path: '/rt800/respOrgs/:respOrgId/trouble',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free RespOrg Trouble Contact Profile',
            description: 'Retrieve the Trouble Contact Profile for the specified Toll-Free RespOrg (Responsible Organization).'
        },
    },
    Roc: {
        Create: {
            path: '/rt800/roc/requests',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Create a Toll-Free RespOrg Change (ROC) Request',
            description: 'Create a Toll-Free RespOrg Change Request for the specified Toll-Free Numbers.'
        },
        Index: {
            path: '/rt800/roc/requests',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Toll-Free RespOrg Change (ROC) Request Index',
            description: 'Retrieve a list of Toll-Free RespOrg Change Requests for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/roc/requests/:rocTxnId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Toll-Free RespOrg Change (ROC) Request Profile',
            description: 'Retrieve detailed information for the specified Toll-Free RespOrg Change Request.'
        },
        Delete: {
            path: '/rt800/roc/requests/:rocTxnId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Delete Toll-Free RespOrg Change (ROC) Request Profile',
            description: 'This operation allows a Resp Org to cancel a Resp Org Change request.'
        }
    },
    RocAction: {
        Index: {
            path: '/rt800/roc/action/',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Toll-Free RespOrg Change (ROC) Request Process Actions Index',
            description: 'A list of process actions for Toll-Free Numbers from the specified Toll-Free RespOrg Change Request.'
        },
        Update: {
            path: '/rt800/roc/action/:rocTxnId',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Toll-Free RespOrg Change (ROC) Request Process',
            description: 'Process Toll-Free Numbers from the specified Toll-Free RespOrg Change Request.'
        },
        Delete: {
            path: '/rt800/roc/action/:rocTxnId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Remove a number (or a set of) from a Resp Org Change request',
            description: 'Allows a submitting Resp Org within an Entity to remove a number (or a set of) from a Resp Org Change request as long as the numbers are still in a Pending or Overdue status.'
        },
    },
    RocLoa: {
        Create: {
            path: '/rt800/roc/letterOfAgency',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Create a Toll-Free ROC Request Letter of Agency',
            description: 'Create a Toll-Free Roc Request Letter of Agency (LOA) to be used when creating a RespOrg Change Request.'
        },
        Profile: {
            path: '/rt800/roc/letterOfAgency',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Get the Toll-Free ROC Request Letter of Agency',
            description: 'Get the Toll-Free Roc Request Letter of Agency (LOA) used when creating a RespOrg Change Request.'
        }
    },
    RocNumbers: {
        Index: {
            path: '/rt800/roc/numbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Toll-Free RespOrg Change (ROC) Numbers Index',
            description: 'A list of Toll-Free Numbers with current ROC data.'
        },
    },
    RocDocument: {
        Create: {
            path: '/rt800/roc/document',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Create a Toll-Free ROC Request Document',
            description: 'This operation allows a submitting Resp Org to add a document to a particular Resp Org Change request.'
        },
        Profile: {
            path: '/rt800/roc/document',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberRespOrgChangeRoc],
            title: 'Get a Toll-Free ROC Request Document',
            description: 'This operation allows a Resp Org to retrieve a document from a particular Resp Org Change request as long as they had participated in that transaction.'
        },
    },
    RoutingCache: {
        Profile: {
            path: '/rt800/routingCache',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeTemplateManager,
                Permissions.TollFreeTemplateOperator,
            ],
            title: 'Routing (CPR) Cache Profile',
            description: 'Retrieve a detailed CPR (Call Processing Record) for the specified values.'
        },
    },
    RoutingGroups: {
        Create: {
            path: '/rt800/routingGroups',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeRoutingGroupManager],
            title: 'Create a Toll-Free Routing Group',
            description: 'Create a Toll-Free Routing Group.'
        },
        Index: {
            path: '/rt800/routingGroups',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRoutingGroupOperator, Permissions.TollFreeRoutingGroupManager],
            title: 'Toll-Free Routing Group Index',
            description: 'Retrieve a list of Toll-Free Routing Groups for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/routingGroups/:routingGroupId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRoutingGroupOperator, Permissions.TollFreeRoutingGroupManager],
            title: 'Toll-Free Routing Group Profile',
            description: 'Retrieve detailed information for the specified Toll-Free Routing Group.'
        },
        Update: {
            path: '/rt800/routingGroups/:routingGroupId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeRoutingGroupManager],
            title: 'Update a Toll-Free Routing Group Profile',
            description: 'Update profile information for the specified Toll-Free Routing Group.'
        },
    },
    RoutingGroupTemplates: {
        Index: {
            path: '/rt800/routingGroups/:routingGroupId/templates',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRoutingGroupManager, Permissions.TollFreeRoutingGroupOperator],
            title: 'Toll-Free Routing Group Templates',
            description: 'List templates belonging to Routing Group.'
        },
        Update: {
            path: '/rt800/routingGroups/:routingGroupId/activate',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeRoutingGroupOperator],
            title: 'Toll-Free Routing Group Activate',
            description: 'Activate a Routing Scenario for a Toll-Free Routing Group.'
        },
    },
    RtCprs: {
        Create: {
            path: '/rt800/rtCprs/:routingCacheKey',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Create a CPR (Routing) Record.',
            description: 'Create a CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Delete: {
            path: '/rt800/rtCprs/:routingCacheKey',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Delete the specified CPR (Routing) record.',
            description: 'Delete the specified CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Index: {
            path: '/rt800/rtCprs/:routingCacheKey',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeTemplateOperator, Permissions.TollFreeTemplateManager],
            title: 'Template CPR (Routing) Index',
            description: 'Retrieve a list routing records (Call Processing Records) for the specified Template.'
        },
        Profile: {
            path: '/rt800/rtCprs/:routingCacheKey/routing/cprs',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeTemplateOperator, Permissions.TollFreeTemplateManager],
            title: 'Template Routing (CPR) Profile',
            description: 'Retrieve a detailed CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Update: {
            path: '/rt800/rtCprs/:routingCacheKey/routing/cprs',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Update the specified CPR (Routing) record.',
            description: 'Update the CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
    },
    RtCprClone: {
        Update: {
            path: '/rt800/rtCpr/:routingCacheKey/clone',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Clone a CPR (Routing) to a different Toll-Free Number or Template.',
            description: 'Clone the CPR (Call Processing Record) to a different Toll-Free Number or Template.'
        },
    },
    RtCprCopy: {
        Update: {
            path: '/rt800/rtCpr/:routingCacheKey/copy',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Copy (and update) the specified CPR (Routing) record.',
            description: 'Copy (and update) the specified CPR (Call Processing Record) to a new Effective Time on the current Template.'
        },
    },
    RtDisconnect: {
        Update: {
            path: '/rt800/rtCpr/:routingCacheKey/disconnect',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Disconnect the specified CPR (Routing) record.',
            description: 'Disconnect the specified CPR (Call Processing Record). This will delete the template at the effective timestamp.'
        },
    },
    RtCprTransfers: {
        Update: {
            path: '/rt800/rtCpr/:routingCacheKey/transfer',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Transfer a CPR (Call Processing Record) for the specified Template and Effective Time to a new Effective Time.',
            description: 'Transfer a CPR (Call Processing Record) for the specified Template and Effective Time to a new Effective Time.'
        },
    },
    Tasks: {
        Index: {
            path: '/rt800/tasks',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Index',
            description: 'Retrieve a list of Toll-Free Tasks for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/tasks/:taskId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Profile',
            description: 'Retrieve detailed information for the specified Toll-Free Task.'
        },
        Update: {
            path: '/rt800/tasks/:taskId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeAdministrator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Profile',
            description: 'Update a Toll-Free Task.'
        },
    },
    TaskErrors: {
        Index: {
            path: '/rt800/tasks/:taskId/errors',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Error Index',
            description: 'Retrieve a list of errors for the specified search criteria.'
        },
    },
    TaskNumbers: {
        Index: {
            path: '/rt800/tasks/:taskId/numbers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Number Index',
            description: 'Retrieve a list of Toll-Free Numbers for the specified search criteria.'
        },
    },
    TaskNumbersFutureCprs: {
        Index: {
            path: '/rt800/tasks/:taskId/numbers/futureCprs',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Task Number Future CPRs of Note Index',
            description: 'Retrieve a list of CPRs for the specified search criteria.'
        },
    },
    TaskTypes: {
        Index: {
            path: '/rt800/taskTypes',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Task Type Index',
            description: 'Retrieve a list of valid Toll-Free Task Type values.'
        },
        Profile: {
            path: '/rt800/taskTypes/:taskTypeId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Task Type Profile',
            description: 'Retrieve a valid Toll-Free Task Type value.'
        },
    },
    Templates: {
        Create: {
            path: '/rt800/templates',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Create a Toll-Free Template',
            description: 'Create a Toll-Free Template in the Somos system.'
        },
        Index: {
            path: '/rt800/templates',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeTemplateOperator,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Toll-Free Template Index',
            description: 'Retrieve a list of Toll-Free Templates for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/templates/:templateName',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeTemplateOperator,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Toll-Free Template Profile',
            description: 'Retrieve detailed information for the specified Template.'
        },
        Update: {
            path: '/rt800/templates/:templateName',
            httpMethod: 'PUT',
            permissions: [
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Toll-Free Template Profile',
            description: 'Update the specified Template.'
        },
    },
    TemplateCprs: {
        Create: {
            path: '/rt800/templates/:templateName/routing/cprs',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Create a CPR (Routing) Record.',
            description: 'Create a CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Delete: {
            path: '/rt800/templates/:templateName/routing/cprs',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Delete the specified CPR (Routing) record.',
            description: 'Delete the specified CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Index: {
            path: '/rt800/templates/:templateName/routing',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeTemplateOperator,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Template CPR (Routing) Index',
            description: 'Retrieve a list routing records (Call Processing Records) for the specified Template.'
        },
        Profile: {
            path: '/rt800/templates/:templateName/routing/cprs',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeTemplateOperator,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Template Routing (CPR) Profile',
            description: 'Retrieve a detailed CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
        Update: {
            path: '/rt800/templates/:templateName/routing/cprs',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Update the specified CPR (Routing) record.',
            description: 'Update the CPR (Call Processing Record) for the specified Template and Effective Time.'
        },
    },
    TemplateCprClone: {
        Update: {
            path: '/rt800/templates/:templateName/routing/cprs/clone',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Clone a CPR (Routing) to a different Toll-Free Number or Template.',
            description: 'Clone the CPR (Call Processing Record) to a different Toll-Free Number or Template.'
        },
    },
    TemplateCprCopy: {
        Update: {
            path: '/rt800/templates/:templateName/routing/cprs/copy',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Copy (and update) the specified CPR (Routing) record.',
            description: 'Copy (and update) the specified CPR (Call Processing Record) to a new Effective Time on the current Template.'
        },
    },
    TemplateCprDisconnect: {
        Update: {
            path: '/rt800/templates/:templateName/routing/cprs/disconnect',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Disconnect the specified CPR (Routing) record.',
            description: 'Disconnect the specified CPR (Call Processing Record). This will delete the template at the effective timestamp.'
        },
    },
    TemplateCprTransfers: {
        Update: {
            path: '/rt800/templates/:templateName/routing/cprs/transfer',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Transfer a CPR (Call Processing Record) for the specified Template and Effective Time to a new Effective Time.',
            description: 'Transfer a CPR (Call Processing Record) for the specified Template and Effective Time to a new Effective Time.'
        },
    },
    TemplateIvr: {
        Profile: {
            path: '/rt800/templates/:templateName/ivr',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeTemplateOperator,
                Permissions.TollFreeTemplateManager
            ],
            title: 'Retrieve IVR (Immediate Validation Results) for the specified Template and Effective Time.',
            description: 'Retrieve IVR (Immediate Validation Results) for the specified Template and Effective Time.'
        },
    },
    TemplateLcrCarriers: {
        Create: {
            path: '/rt800/templates/:templateName/lcr/carriers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Add a Least Cost Routing Carrier to a Template.',
            description: 'Add a Least Cost Routing Carrier to a Template.'
        },
        Delete: {
            path: '/rt800/templates/:templateName/lcr/carriers/:templateLcrCarrierId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Delete a Least Cost Routing Carrier from a Template.',
            description: 'Delete a Least Cost Routing Carrier from a Template.'
        },
        Index: {
            path: '/rt800/templates/:templateName/lcr/carriers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Retrieve the current list of Least Cost Routing Carrier for a Template.',
            description: 'Retrieve the current list of Least Cost Routing Carrier for a Template.'
        },
        Profile: {
            path: '/rt800/templates/:templateName/lcr/carriers/:templateLcrCarrierId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Retrieve the a Template Least Cost Carrier record.',
            description: 'Retrieve the a Template Least Cost Carrier record.'
        },
        Update: {
            path: '/rt800/templates/:templateName/lcr/carriers/:templateLcrCarrierId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Update the a Template Least Cost Carrier record.',
            description: 'Update the a Template Least Cost Carrier record.'
        },
    },
    TemplateLcrCarriersAvailable: {
        Index: {
            path: '/rt800/templates/:templateName/lcr/carriers/available',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Available Least Cost Routing Carriers for a Template.',
            description: 'Retrieve a list of Least Cost Routing Carriers that can be added to an existing template.'
        },
    },
    TemplateLcrScenarios: {
        Create: {
            path: '/rt800/templates/:templateName/lcr/scenarios',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Add a Least Cost Routing Scenario to a Template.',
            description: 'Add a Least Cost Routing Scenario to a Template.'
        },
        Delete: {
            path: '/rt800/templates/:templateName/lcr/scenarios/:templateLcrScenarioId',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Delete a Least Cost Routing Scenario from a Template.',
            description: 'Delete a Least Cost Routing Scenario from a Template.'
        },
        Index: {
            path: '/rt800/templates/:templateName/lcr/scenarios',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Retrieve the current list of Least Cost Routing Scenario for a Template.',
            description: 'Retrieve the current list of Least Cost Routing Scenario for a Template.'
        },
        Profile: {
            path: '/rt800/templates/:templateName/lcr/scenarios/:templateLcrScenarioId',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Retrieve the a Template Least Cost Scenario record.',
            description: 'Retrieve the a Template Least Cost Scenario record.'
        },
        Update: {
            path: '/rt800/templates/:templateName/lcr/scenarios/:templateLcrScenarioId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Update the a Template Least Cost Scenario record.',
            description: 'Update the a Template Least Cost Scenario record.'
        },
    },
    TemplateLcrScenariosAvailable: {
        Index: {
            path: '/rt800/templates/:templateName/lcr/scenarios/available',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Available Least Cost Routing Scenarios for a Template.',
            description: 'Retrieve a list of Least Cost Routing Scenarios that can be added to an existing template.'
        },
    },
    TemplateNameIsValid: {
        Profile: {
            path: '/rt800/templates/isValid',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateManager, Permissions.TollFreeTemplateOperator],
            title: 'Validate Template Name.',
            description: 'Check whether template name is valid.'
        }
    },
    TemplateScps: {
        Index: {
            path: '/rt800/templates/:templateName/routing/scps',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeTemplateOperator],
            title: 'Template Routing SCP Index',
            description: 'Retrieve a list of Service Country Point (SCP) status records for the specified Template and Effective Time.'
        }
    },
    TemplateSimulator: {
        Index: {
            path: '/rt800/templates/:templateName/simulator',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Template Call Simulator',
            description: 'Analyzes routing records to show carrier selection based on the calling party number.'
        }
    },
    TollFrees: {
        Index: {
            path: '/rt800/numbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number Index',
            description: 'Retrieve a list of Toll-Free Numbers for the specified search criteria.'
        },
        Profile: {
            path: '/rt800/numbers/:tfn',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number Profile',
            description: 'Retrieve detailed information for the specified Toll-Free Number.'
        },
    },
    TollFreesDeprecated: {
        Index: {
            path: '/rt800/numbers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number Index  (DEPRECATED)',
            description: 'This method is deprecated, due to a URL length limitation. Please use the POST version. Retrieve a list of Toll-Free Numbers for the specified search criteria.'
        },
    },
    TollFreesCsv: {
        Index: {
            path: '/rt800/numbers/csv',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number Index',
            description: 'Retrieve a list of Toll-Free Numbers for the specified search criteria.'
        },
    },
    TollFreesExportCsv: {
        Index: {
            path: '/rt800/numbers/exportCsv',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number CSV Export',
            description: 'Create CSV export of Toll-Free Numbers.'
        },
    },
    TollFreeActionActivate: {
        Create: {
            path: '/rt800/numbers/:tfn/activate',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberActivate],
            title: 'Activate a Toll-Free Number',
            description: 'Activate a Toll-Free Number by assigning it to a Template. Note: Toll-Free Numbers assigned to a Template or with existing routing must be moved using the Re-Route.'
        },
    },
    TollFreeActionDisconnect: {
        Create: {
            path: '/rt800/numbers/:tfn/disconnect',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberDisconnect],
            title: 'Disconnect a Toll-Free Number',
            description: 'Disconnect a Toll-Free Number.'
        },
    },
    TollFreeActionReRoute: {
        Create: {
            path: '/rt800/numbers/:tfn/reRoute',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberReRoute],
            title: 'Re-Route a Toll-Free Number',
            description: 'Assign a Toll-Free Number to a new Template.'
        },
    },
    TollFreeActionReserve: {
        Create: {
            path: '/rt800/numbers/:tfn/reserve',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberReserve],
            title: 'Reserve a Toll-Free Number',
            description: 'Reserve a Toll-Free Number.'
        },
    },
    TollFreeActionRespOrgChange: {
        Create: {
            path: '/rt800/numbers/:tfn/respOrgChange',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberRespOrgChangeTask],
            title: 'Change the RespOrg of a Toll-Free Number',
            description: 'Assign a Toll-Free Number to a new RespOrg (Responsible Organization).'
        },
    },
    TollFreeActionSpare: {
        Create: {
            path: '/rt800/numbers/:tfn/spare',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeNumberSpare],
            title: 'Spare (Release) a Toll-Free Number',
            description: 'Return a Toll-Free Numbers in Disconnect, Reserve or Transitional state to the spare pool. This will remove the Toll-Free Number from the specified RespOrg, making them available to other RespOrgs.'
        },
    },
    TollFreeHistory: {
        Index: {
            path: '/rt800/numbers/:tfn/history',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Query the Somos History of a Toll-Free Number',
            description: 'Query the Somos History of a Toll-Free Number.'
        },
    },
    TollFreeQuery: {
        Profile: {
            path: '/rt800/numbers/:tfn/query',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Query a Toll-Free Number',
            description: 'Query a Toll-Free Number.'
        },
    },
    TollFreeCprs: {
        Create: {
            path: '/rt800/numbers/:tfn/routing/cprs',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Create a CPR (Routing) record.',
            description: 'Create a CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time.'
        },
        Delete: {
            path: '/rt800/numbers/:tfn/routing/cprs',
            httpMethod: 'DELETE',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Delete the specified CPR (Routing) record.',
            description: 'Delete the CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time.'
        },
        Index: {
            path: '/rt800/numbers/:tfn/routing',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeOperator,
                Permissions.TollFreeTemplateManager,
                Permissions.TollFreeTemplateOperator,
            ],
            title: 'Toll-Free CPR (Routing) Index',
            description: "Retrieve a list CPR's (Call Processing Records) for the specified Toll-Free Number."
        },
        Profile: {
            path: '/rt800/numbers/:tfn/routing/cprs',
            httpMethod: 'GET',
            permissions: [
                Permissions.TollFreeRespOrgManager,
                Permissions.TollFreeRespOrgOperator,
                Permissions.TollFreeOperator,
                Permissions.TollFreeTemplateManager,
                Permissions.TollFreeTemplateOperator,
            ],
            title: 'Toll-Free CPR (Routing) Profile',
            description: 'Retrieve a detailed CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time.'
        },
        Update: {
            path: '/rt800/numbers/:tfn/routing/cprs',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Update the specified CPR (Routing) record.',
            description: 'Update the CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time.'
        },
    },
    TollFreeCprClone: {
        Update: {
            path: '/rt800/numbers/:tfn/routing/cprs/clone',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Clone a CPR (Routing) to a different Toll-Free Number or Template.',
            description: 'Clone the CPR (Call Processing Record) to a different Toll-Free Number or Template.'
        },
    },
    TollFreeCprCopy: {
        Update: {
            path: '/rt800/numbers/:tfn/routing/cprs/copy',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Copy (and update) the specified CPR (Routing) record.',
            description: 'Copy (and update) the specified CPR (Call Processing Record) to a new Effective Time on the current Toll-Free Number.'
        },
    },
    Resources: {
        Index: {
            apiExcludeEndpoint: false,
            path: '/rt800/resources',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Resource List',
            description: 'Retrieve a list of Resources'
        }
    },
    TollFreeCprTransfers: {
        Update: {
            path: '/rt800/numbers/:tfn/routing/cprs/transfer',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeTemplateManager],
            title: 'Transfer a CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time to a new Effective Time.',
            description: 'Transfer a CPR (Call Processing Record) for the specified Toll-Free Number and Effective Time to a new Effective Time.'
        },
    },
    TollFreeScp: {
        Index: {
            path: '/rt800/numbers/:tfn/routing/scps',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Number Routing SCP Index',
            description: 'Retrieve a list of Service Country Point (SCP) status records for the specified Toll-Free Number and Effective Time.'
        }
    },
    TollFreeSearch: {
        Profile: {
            path: '/rt800/search',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeNumberSearch],
            title: 'Toll-Free Search',
            description: 'Search Somos inventory for available Toll-Free Numbers.'
        },
    },
    TollFreeSimulator: {
        Index: {
            path: '/rt800/numbers/:tfn/simulator',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.TollFreeRespOrgOperator, Permissions.TollFreeRespOrgManager],
            title: 'Toll-Free Call Simulator',
            description: 'Analyzes routing records to show carrier selection based on the calling party number.'
        }
    },
    TollFreeStatus: {
        Index: {
            path: '/rt800/tollFreeStatus',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Status Index',
            description: 'Retrieve a list of valid Toll-Free Status values.'
        }
    },
    TollFreeTrouble: {
        Profile: {
            path: '/rt800/numbers/:tfn/trouble',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator],
            title: 'Toll-Free Number Trouble Contact Profile',
            description: 'Retrieve the Trouble Contact Profile for the specified Toll-Free Number.'
        },
    },
}

export interface IRtxDIdApiRoutes extends IRtxApiRoutes {
    Activate: {
        Create: IRtxApiRoute;
    };
    AuditSummary: {
        Index: IRtxApiRoute;
    };
    BandwidthPortOutRequest: {
        Create: IRtxApiRoute;
    };
    IdddSearch: {
        Index: IRtxApiRoute;
    };
    Iddd: {
        Create: IRtxApiRoute;
    };
    MetricsByNPA: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    MetricsByDay: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    MetricsByState: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    MetricsByResource: {
        Index: IRtxApiRoute;
    };
    Nanps: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    NanpSearch: {
        Index: IRtxApiRoute;
    };
    NanpAvailable: {
        Index: IRtxApiRoute;
    };
    NanpResources: {
        Index: IRtxApiRoute;
    };
    Numbers: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    NumbersCsv: {
        Index: IRtxApiRoute;
    };
    NumbersBilling: {
        Create: IRtxApiRoute;
    };
    NumbersSwitch: {
        Create: IRtxApiRoute;
    };
    Search: {
        Index: IRtxApiRoute;
    };
}

export const RtxDidApiRoutes: IRtxDIdApiRoutes = {
    Activate: {
        Create: {
            path: '/rtDid/beta/activate',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator, Permissions.DidManager, Permissions.DidOperator],
            title: 'Activate DID Numbers',
            description: 'Activate DID Numbers.'
        }
    },
    AuditSummary: {
        Index: {
            path: '/rtDid/beta/audit/numbers/summary',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Numbers Audit Summary',
            description: 'Retrieve Audit summary of DID numbers.'
        }
    },
    BandwidthPortOutRequest: {
        Create: {
            path: '/rtDid/bandwidth/portout/validate',
            httpMethod: 'POST',
            permissions: [],
            title: 'Validate Bandwidth Port Out Request',
            description: 'Validate Bandwidth Port Out Request'
        }
    },
    Iddd: {
        Create: {
            path: '/rtDid/iddd/:resourceId/:did',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator],
            title: 'Reserve Iddd number',
            description: 'Reserve an Iddd number using the specified vendor.'
        }
    },
    IdddSearch: {
        Index: {
            path: '/rtDid/iddd/search',
            httpMethod: 'GET',
            permissions: [Permissions.DidAdministrator, Permissions.DidManager, Permissions.DidOperator],
            title: 'Search Iddd numbers from specified resources',
            description: 'Search for available Iddd numbers using specified DID resources.'
        }
    },
    MetricsByNPA: {
        Index: {
            path: '/rtDid/beta/resources/metrics/byNpa',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Top NPAs',
            description: 'Retrieve List of top NPAs.'
        },
        Profile: {
            path: '/rtDid/beta/resources/:resourceId/metrics/byNpa',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Top NPAs by resource',
            description: 'Retrieve List of top NPAs by resources.'
        }
    },
    MetricsByDay: {
        Index: {
            path: '/rtDid/beta/resources/metrics/byDay',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Total By Day',
            description: 'Retrieve activity by day.'
        },
        Profile: {
            path: '/rtDid/beta/resources/:resourceId/metrics/byDay',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Total By Day',
            description: 'Retrieve activity by day.'
        }
    },
    MetricsByState: {
        Index: {
            path: '/rtDid/beta/resources/metrics/byState',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Top states',
            description: 'Retrieve list of top states.'
        },
        Profile: {
            path: '/rtDid/beta/resources/:resourceId/metrics/byState',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Top states',
            description: 'Retrieve list of top states.'
        }
    },
    MetricsByResource: {
        Index: {
            path: '/rtDid/beta/resource/metrics/byResource',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Top Resources',
            description: 'Retrieve list of top Resources.'
        }
    },
    NanpNumber: {
        Create: {
            path: '/rtDid/nanp/number/:vendorId/:nanpNumber',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator],
            title: 'Reserve NANP number',
            description: 'Reserve a NANP number using the specified vendor.'
        }
    },
    Nanps: {
        Create: {
            path: '/rtDid/nanp/:resourceId/:nanpNumber',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator],
            title: 'Reserve NANP number',
            description: 'Reserve a NANP number using the specified vendor.'
        },
        Index: {
            path: '/rtDid/nanp',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Search purchased numbers',
            description: 'List NANP numbers purchased from the specified vendor'
        },
        Profile: {
            path: '/rtDid/nanp/:resourceId/:nanpNumber',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Profile purchased number',
            description: 'Get information from the vendor about specified number'
        }
    },
    NanpSearch: {
        Index: {
            path: '/rtDid/nanp/search',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Search numbers from specified resources',
            description: 'Search for available NANP numbers using specified DID resources.'
        }
    },
    NanpAvailable: {
        Index: {
            path: '/rtDid/nanp/available',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.DidOperator],
            title: 'Search numbers from specified resources',
            description: 'Search for available NANP numbers using specified DID resources.'
        }
    },
    NanpResources: {
        Index: {
            path: '/rtDid/nanp/resources',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Nanp Vendor Index',
            description: 'Retrieve a list of RT/Did Resources.'
        }
    },
    NumbersBilling: {
        Create: {
            path: '/rtDid/beta/numbers/billing',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator],
            title: 'Create rtDid Numbers',
            description: 'Upload new DID billing numbers.'
        }
    },
    NumbersSwitch: {
        Create: {
            path: '/rtDid/beta/numbers/switch',
            httpMethod: 'POST',
            permissions: [Permissions.DidAdministrator],
            title: 'Create rtDid Numbers',
            description: 'Upload new DID switch numbers.'
        }
    },
    Numbers: {
        Index: {
            path: '/rtDid/beta/numbers',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator, Permissions.DidOperator, Permissions.DidManager, Permissions.DidAdministrator],
            title: 'Numbers Index',
            description: 'Retrieve numbers.',
            menuPermissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.DidOperator, Permissions.DidManager, Permissions.DidAdministrator]
        },
        Profile: {
            path: '/rtDid/beta/numbers/:numberId',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator, Permissions.CarrierConnectOperator],
            title: 'Numbers Profile',
            description: 'Retrieve Profile of specific number.'
        },
        Update: {
            path: '/rtDid/beta/numbers/:numberId',
            httpMethod: 'PUT',
            permissions: [Permissions.DidAdministrator],
            title: 'Update Number',
            description: 'Update the specified number.'
        }
    },
    NumbersCsv: {
        Index: {
            path: '/rtDid/beta/numbers/csv',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator],
            title: 'Numbers Index in csv format',
            description: 'Retrieve numbers in csv format.'
        }
    },
    Search: {
        Index: {
            path: '/rtDid/beta/search',
            httpMethod: 'GET',
            permissions: [Permissions.DidAdministrator, Permissions.DidManager, Permissions.DidOperator],
            title: 'Search numbers from specified resources',
            description: 'Search for available numbers using specified DID resources.'
        }
    }
}

export interface IRtAdmApiRoutes extends IRtxApiRoutes {
    EmailAccounts: {
        Index: IRtxApiRoute;
    };
    DoDroplets: {
        Index: IRtxApiRoute;
    };
    DoImages: {
        Index: IRtxApiRoute;
    };
    DoSshKeys: {
        Index: IRtxApiRoute;
    };
    DoSizes: {
        Index: IRtxApiRoute;
    };
    DoSnapshots: {
        Index: IRtxApiRoute;
    };
    DoTags: {
        Index: IRtxApiRoute;
    };
    DoVpcs: {
        Index: IRtxApiRoute;
    };
    Firebase: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    IntegrationErrorMessages: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    ManagedEntities: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    ManagedEntitiesRoc: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedEntitiesApi: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedEntitiesSql: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedEntitiesMgi: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedRespOrgs: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    ManagedRespOrgsMgi: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedRespOrgsRoc: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedRespOrgsApi: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedRespOrgsSql: {
        Delete: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ManagedRespOrgsPublic: {
        Profile: IRtxApiRoute;
    };
    Partitions: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    PartitionEntities: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    PartitionRespOrg: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    PartitionSipGateway: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Servers: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    ServerGroups: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ServerGroupMembers: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    ServerProcess: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    UrlPartitions: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    NagiosApi: {
        Index: IRtxApiRoute;
    };
}

export const RtAdmApiRoutes: IRtAdmApiRoutes = {
    NagiosApi: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/nagios/api',
            httpMethod: 'GET',
            permissions: [],
            title: 'Test API',
            description: 'Test API endpoint.'
        }
    },
    EmailAccounts: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/emailAccounts',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Email Accounts Index',
            description: 'Retrieve a list of Email Accounts.'
        }
    },
    DoDroplets: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/droplets',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Droplet Index',
            description: 'Retrieve a list of Droplets.'
        }
    },
    DoImages: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/images',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Image Index',
            description: 'Retrieve a list of Images.'
        }
    },
    DoSizes: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/sizes',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Size Index',
            description: 'Retrieve a list of Sizes.'
        }
    },
    DoSnapshots: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/snapshots',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Snapshot Index',
            description: 'Retrieve a list of Snapshots.'
        }
    },
    DoSshKeys: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/sshKeys',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'SSH Key Index',
            description: 'Retrieve a list of SSH Keys.'
        }
    },
    DoTags: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/tags',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'SSH Key Index',
            description: 'Retrieve a list of SSH Keys.'
        }
    },
    DoVpcs: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/do/vpcs',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Vpc Index',
            description: 'Retrieve a list of Vpcs.'
        }
    },
    Firebase: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/firebase',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Add message to Firebase',
            description: 'Add new message to Firebase for UI alerting.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/firebase',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'List all Firebase messages',
            description: 'List all Firebase messages.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            httpMethod: 'DELETE',
            path: '/rtAdm/firebase/:firebaseMessageId',
            permissions: [Permissions.RtxAdministrator],
            title: 'Delete Firebase message',
            description: 'Delete Firebase message.'
        }
    },
    IntegrationErrorMessages: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/integrationErrorMessages',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Integration Error Message Index',
            description: 'Retrieve a list of Integration Error Messages for the specified search criteria.'
        },
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/integrationErrorMessages',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create Integration Error Message',
            description: 'Create the specified Integration Error Message.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/integrationErrorMessages/:integrationErrorMessageId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Integration Error Message',
            description: 'Update the specified Integration Error Message.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/integrationErrorMessages/:integrationErrorMessageId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Integration Error Message Profile',
            description: 'Retrieve the specified Integration Error Message.'
        }
    },
    ManagedEntities: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Index',
            description: 'Retrieve a list of Toll-Free Managed Entities for the specified search criteria.'
        },
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Create the specified Toll-Free Managed Entity.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Update the specified Toll-Free Managed Entity.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Retrieve the specified Toll-Free Managed Entity.'
        }
    },
    ManagedEntitiesRoc: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/roc',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: "Delete the specified Toll-Free Managed Entity's ROC Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/roc',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Update the specified Toll-Free Managed Entity.'
        }
    },
    ManagedEntitiesSql: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/sql',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: "Delete the specified Toll-Free Managed Entity's SQL Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/sql',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Update the specified Toll-Free Managed Entity.'
        }
    },
    ManagedEntitiesApi: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/api',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: "Delete the specified Toll-Free Managed Entity's API Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/api',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Update the specified Toll-Free Managed Entity.'
        }
    },
    ManagedEntitiesMgi: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/mgi',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: "Delete the specified Toll-Free Managed Entity's MGI Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedEntities/:entityId/mgi',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed Entity Profile',
            description: 'Update the specified Toll-Free Managed Entity.'
        }
    },
    ManagedRespOrgs: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Index',
            description: 'Retrieve a list of Toll-Free Managed RespOrgs for the specified search criteria.'
        },
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Create the specified Toll-Free Managed RespOrg.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Update the specified Toll-Free Managed RespOrg.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Retrieve the specified Toll-Free Managed RespOrg.'
        }
    },
    ManagedRespOrgsMgi: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/mgi',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: "Delete the specified Toll-Free Managed RespOrg's MGI Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/mgi',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: "Update the specified Toll-Free Managed RespOrg's MGI Credentials."
        }
    },
    ManagedRespOrgsRoc: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/roc',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: "Delete the specified Toll-Free Managed RespOrg's ROC Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/roc',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Update the specified Toll-Free Managed RespOrg.'
        }
    },
    ManagedRespOrgsSql: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/sql',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: "Delete the specified Toll-Free Managed RespOrg's SQL Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/sql',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Update the specified Toll-Free Managed RespOrg.'
        }
    },
    ManagedRespOrgsPublic: {
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/public',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeRespOrgManager],
            title: 'The Public Toll-Free Managed RespOrg Profile',
            description: 'Retrieve the specified public view of Toll-Free Managed RespOrg.'
        }
    },
    ManagedRespOrgsApi: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/api',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: "Delete the specified Toll-Free Managed RespOrg's API Credentials."
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/managedRespOrgs/:respOrgId/api',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Toll-Free Managed RespOrg Profile',
            description: 'Update the specified Toll-Free Managed RespOrg.'
        }
    },
    Partitions: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition Create',
            description: 'Create new Partition.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition Index',
            description: 'Retrieve a list of Partitions.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition Profile',
            description: 'Retrieve profile of specified Partition.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Partition',
            description: 'Update Partition Profile.'
        }
    },
    PartitionEntities: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/entities',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Partition Entities',
            description: 'Create new Partition Entity.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitionEntities',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition Entities Index',
            description: 'Retrieve a list of Partition Entities.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/entities/:partitionEntityId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition Entity Profile',
            description: 'Retrieve profile of specified Partition Entity.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/entities/:partitionEntityId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Partition Entity',
            description: 'Update Partition Entity Profile.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/entities/:partitionEntityId',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Delete Partition Entity',
            description: 'Delete specified Partition Entity.'
        }
    },
    PartitionRespOrg: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/respOrgs',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Partition RespOrg',
            description: 'Create new Partition RespOrg.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/respOrgs',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition RespOrg Index',
            description: 'Retrieve a list of Partition RespOrgs.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/respOrgs/:partitionRespOrgId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition RespOrg Profile',
            description: 'Retrieve profile of specified Partition RespOrg.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/respOrgs/:partitionRespOrgId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Partition RespOrg',
            description: 'Update Partition RespOrg Profile.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/respOrgs/:partitionRespOrgId',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Delete Partition RespOrg',
            description: 'Delete specified Partition RespOrg.'
        }
    },
    PartitionSipGateway: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/gateways',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Partition SIP Gateway',
            description: 'Create new Partition SIP Gateway.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/gateways/:partitionSipGatewayId',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Delete Partition SIP Gateway',
            description: 'Delete specified Partition SIP Gateway.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitionSipGateways',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition SIP Gateway Index',
            description: 'Retrieve a list of Partition SIP Gateways.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/gateways/:partitionSipGatewayId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Partition SIP Gateway Profile',
            description: 'Retrieve profile of specified Partition SIP Gateway.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/gateways/:partitionSipGatewayId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Partition SIP Gateway',
            description: 'Update Partition SIP Gateway Profile.'
        }
    },
    Servers: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/servers',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Server',
            description: 'Create new Server.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/servers',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Index',
            description: 'Retrieve a list of Servers.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/servers/:serverId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Profile',
            description: 'Retrieve profile of specified Server.'
        }
    },
    ServerGroups: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroups',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Server Group',
            description: 'Create new Server Group.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroups',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Group Index',
            description: 'Retrieve a list of Server Groups.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroups/:serverGroupId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Group Profile',
            description: 'Retrieve profile of specified Server Group.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroups/:serverGroupId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Group Update',
            description: 'Update specified Server Group.'
        }
    },
    ServerGroupMembers: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroupMembers',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create new Server Group',
            description: 'Create new Server Group.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroupMembers',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Group Member Index',
            description: 'Retrieve a list of Server Group Members.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroupMembers/:serverGroupMemberId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Group Members Profile',
            description: 'Retrieve profile of specified Server Group Member.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroupMembers/:serverGroupMemberId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Server Group Member',
            description: 'Update specified Server Group Member.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/serverGroupMembers/:serverGroupMemberId',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Delete Server Group Member',
            description: 'Delete specified Server Group Member.'
        }
    },
    ServerProcess: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/serverProcess',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Process Index',
            description: 'Retrieve List of all running server process.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/serverProcess/:serverProcessId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Server Process Profile',
            description: 'Retrieve Profile of specific server process.'
        }
    },
    UrlPartitions: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/urls',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create URL Partition Index',
            description: 'Create a new Url Partition.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/urls/:urlPartitionId',
            httpMethod: 'DELETE',
            permissions: [Permissions.RtxAdministrator],
            title: 'Url Partition Delete',
            description: 'Delete profile of specified URL Partition.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/urlpartitions',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'URL Partition Index',
            description: 'Retrieve a list of Url Partitions.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/urls/:urlPartitionId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Url Partition Profile',
            description: 'Retrieve profile of specified URL Partition.'
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/rtAdm/beta/partitions/:partitionId/urls/:urlPartitionId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update URL Partition',
            description: 'Update URL Partition Profile.'
        }
    }
}

export interface IRtxSipApiRoutes extends IRtxApiRoutes {
    Accounts: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    AccountConnections: {
        Index: IRtxApiRoute;
    };
    AccountContacts: {
        Index: IRtxApiRoute;
    };
    AccountCreditLimits: {
        Index: IRtxApiRoute;
    };
    AccountInvoices: {
        Index: IRtxApiRoute;
    };
    AccountPortLimits: {
        Index: IRtxApiRoute;
    };
    AccountSubscriptions: {
        Index: IRtxApiRoute;
    };
    ActivePorts: {
        Index: IRtxApiRoute;
    };
    ActivePortsAll: {
        Index: IRtxApiRoute;
    };
    ActivePortsAccounts: {
        Index: IRtxApiRoute;
    };
    ActivePortsCountries: {
        Index: IRtxApiRoute;
    };
    AsrSummary: {
        Index: IRtxApiRoute;
    };
    BlockTypes: {
        Index: IRtxApiRoute;
    };
    BlockedNumbers: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    BlockedNumbersBulk: {
        Create: IRtxApiRoute;
    };
    CallProcessingResults: {
        Index: IRtxApiRoute;
    };
    CdrSearch: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    Connections: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Contacts: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    CreditLimits: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    CustomerBalances: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    DirectConnect: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    DirectConnectResetPassword: {
        Update: IRtxApiRoute;
    };
    DirectConnectActivate: {
        Update: IRtxApiRoute;
    };
    FullSummary: {
        Index: IRtxApiRoute;
    };
    Gateways: {
        Index: IRtxApiRoute;
    };
    Invoices: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    InvoiceCycleGroups: {
        Index: IRtxApiRoute;
    };
    InvoiceDownload: {
        Profile: IRtxApiRoute;
    };
    InvoiceRerun: {
        Create: IRtxApiRoute;
    };
    InvoiceSubscriptions: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    MatchModes: {
        Index: IRtxApiRoute;
    };
    MarginSummary: {
        Index: IRtxApiRoute;
    };
    ScenarioActions: {
        Index: IRtxApiRoute;
    };
    CdrExport: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    CdrExportControl: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    CdrExportIntervals: {
        Index: IRtxApiRoute;
    };
    CdrExportRetentions: {
        Index: IRtxApiRoute;
    };
    CdrExportServices: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    CdrExportSourceTypes: {
        Index: IRtxApiRoute;
    };
    CdrExportTypes: {
        Index: IRtxApiRoute;
    };
    CdrExportDestinations: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    FixedRating: {
        Index: IRtxApiRoute;
    };
    MarginPlans: {
        Index: IRtxApiRoute;
    };
    NetworkEfficiency: {
        Index: IRtxApiRoute;
    };
    PortLimits: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Prompts: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    PromptFiles: {
        Profile: IRtxApiRoute;
    };
    PromptBulk: {
        Create: IRtxApiRoute;
    };
    QosManagement: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RateCenters: {
        Index: IRtxApiRoute;
    };
    ReleaseCodes: {
        Index: IRtxApiRoute;
    };
    RouteDepth: {
        Index: IRtxApiRoute;
    };
    RoutePlans: {
        Index: IRtxApiRoute;
    };
    RoutingSummary: {
        Index: IRtxApiRoute;
    };
    RoutingRules: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    RoutingRulesActionModes: {
        Index: IRtxApiRoute;
    };
    RoutingRulesMatchTypes: {
        Index: IRtxApiRoute;
    };
    Schedules: {
        Index: IRtxApiRoute;
    };
    ServiceNumbers: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ServiceNumbersBulk: {
        Create: IRtxApiRoute;
    };
    ServiceNumberTag: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Services: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    SipCallSimulator: {
        Profile: IRtxApiRoute;
    };
    SipTrace: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    SipTraceExportPcap: {
        Profile: IRtxApiRoute;
    };
    SipTraceExportText: {
        Profile: IRtxApiRoute;
    };
    Scenarios: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Subscriptions: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionValidate: {
        Index: IRtxApiRoute;
    };
    SubscriptionNanp: {
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionPeering: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionQos: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionBilling: {
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionRouting: {
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionSchedules: {
        Delete: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SubscriptionTags: {
        Index: IRtxApiRoute;
    };
    SubscriptionsTag: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Tags: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    TagAssignment: {
        Index: IRtxApiRoute;
    };
    TagMatching: {
        Index: IRtxApiRoute;
    };
    TrackingGroups: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TrendAnalysis: {
        Index: IRtxApiRoute;
    };
    Transactions: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    TransactionReversal: {
        Create: IRtxApiRoute;
    };
}

export const RtxSipApiRoutes: IRtxSipApiRoutes = {
    Accounts: {
        Index: {
            path: '/rtSip/beta/accounts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Index',
            description: 'Retrieve a list of RT/SIP Accounts.'
        },
        Profile: {
            path: '/rtSip/beta/accounts/:accountId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Profile',
            description: 'Retrieve an RT/SIP Account Profile record.'
        },
        Create: {
            path: '/rtSip/beta/accounts',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Create',
            description: 'Create an RT/SIP Account Profile record.'
        },
        Update: {
            path: '/rtSip/beta/accounts/:accountId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Update',
            description: 'Update an RT/SIP Account Profile record.'
        }
    },
    AccountConnections: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/connections',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Connection Index',
            description: 'Retrieve a list of RT/SIP Account Connections.'
        }
    },
    AccountContacts: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/contacts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Contacts Index',
            description: 'Retrieve a list of RT/SIP Account Contacts.'
        }
    },
    AccountCreditLimits: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/creditLimits',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Credit Limit Index',
            description: 'Retrieve a list of RT/SIP Account Credit Limits.'
        }
    },
    AccountInvoices: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/invoices',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Invoice Index',
            description: 'Retrieve a list of RT/SIP Account Invoices.'
        }
    },
    AccountPortLimits: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/portLimits',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Port Limit Index',
            description: 'Retrieve a list of RT/SIP Account Port Limits.'
        }
    },
    AccountSubscriptions: {
        Index: {
            path: '/rtSip/beta/accounts/:accountId/subscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Account Subscription Index',
            description: 'Retrieve a list of RT/SIP Account Subscriptions.'
        }
    },
    ActivePorts: {
        Index: {
            path: '/rtSip/beta/activePorts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Active Ports Detail Index',
            description: 'Retrieve a list of RT/SIP Active Port Detail.'
        }
    },
    ActivePortsAll: {
        Index: {
            path: '/rtSip/beta/activePorts/all',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'All Partitions Active Ports Index',
            description: 'Retrieve a list of RT/SIP Active Ports for all partitions.'
        }
    },
    ActivePortsAccounts: {
        Index: {
            path: '/rtSip/beta/activePorts/accounts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Active Ports by Account Index',
            description: 'Retrieve a list of RT/SIP Active Ports by Account.'
        }
    },
    ActivePortsCountries: {
        Index: {
            path: '/rtSip/beta/activePorts/countries',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Active Ports Country Index',
            description: 'Retrieve a list of RT/SIP Active Ports by Country.'
        }
    },
    AsrSummary: {
        Index: {
            path: '/rtSip/beta/asrSummary',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Asr Summary',
            description: 'Asr Summary Report.'
        }
    },
    BlockTypes: {
        Index: {
            path: '/rtSip/beta/blockTypes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Block Types Index',
            description: 'Retrieve a list of Block Types (Call Processing Blocks).'
        }
    },
    BlockedNumbers: {
        Index: {
            path: '/rtSip/beta/blockedNumbers',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Blocked Numbers Index',
            description: 'Retrieve a list of Blocked Numbers.'
        },
        Create: {
            path: '/rtSip/beta/blockedNumbers',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Create Blocked Number',
            description: 'Create a new Blocked Number'
        },
        Delete: {
            path: '/rtSip/beta/blockedNumbers/:blockedNumberId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Delete Blocked Number',
            description: 'Delete existing Blocked Number Record.'
        },
        Profile: {
            path: '/rtSip/beta/blockedNumbers/:blockedNumberId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Blocked Number Profile',
            description: 'Retrieve a Blocked Number profile.'
        },
        Update: {
            path: '/rtSip/beta/blockedNumbers/:blockedNumberId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Blocked Number Update',
            description: 'Update a Blocked Number profile.'
        }
    },
    BlockedNumbersBulk: {
        Create: {
            path: '/rtSip/beta/blockedNumbers/bulk',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Bulk Create Blocked Numbers',
            description: 'Bulk Create new Blocked Numbers.'
        }
    },
    CallProcessingResults: {
        Index: {
            path: '/rtSip/beta/callProcessingResults/',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Call Processing Result Index',
            description: 'Retrieve a list of RT/SIP Call Processing Result.'
        }
    },
    CdrSearch: {
        Index: {
            path: '/rtSip/beta/cdrs',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.PartitionNetworkOperations],
            title: 'CDR Search Index',
            description: 'Retrieve CDR Search Report.'
        },
        Profile: {
            path: '/rtSip/beta/cdrs/:cdrId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.PartitionNetworkOperations],
            title: 'CDR Search Profile',
            description: 'Retrieve a CDR Profile.'
        }
    },
    Connections: {
        Index: {
            path: '/rtSip/beta/connections',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Invoice Index',
            description: 'Retrieve a list of connections.'
        },
        Create: {
            path: '/rtSip/beta/connections',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create Connection',
            description: 'Create a new Connection'
        },
        Profile: {
            path: '/rtSip/beta/connections/:connectionId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Connection Profile',
            description: 'Retrieve a Connection profile.'
        },
        Update: {
            path: '/rtSip/beta/connections/:connectionId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Connection Update',
            description: 'Update a Connection profile.'
        }
    },
    Contacts: {
        Create: {
            path: '/rtSip/beta/contacts',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Contact Record',
            description: 'Create a new Contact Record.'
        },
        Delete: {
            path: '/rtSip/beta/contacts/:contactId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete Contact Record',
            description: 'Delete and existing Contact Record.'
        },
        Index: {
            path: '/rtSip/beta/contacts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Contact Index',
            description: 'Retrieve a list of RT/SIP Contacts.'
        },
        Profile: {
            path: '/rtSip/beta/contacts/:contactId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Contact Profile',
            description: 'Retrieve an RT/SIP Contact Profile record.'
        },
        Update: {
            path: '/rtSip/beta/contacts/:contactId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Update Contact record',
            description: 'Update Existing Contact record.'
        }
    },
    CreditLimits: {
        Index: {
            path: '/rtSip/beta/creditLimits',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve Credit Limits',
            description: 'Retrieve All Account Credit Limits.'
        },
        Profile: {
            path: '/rtSip/beta/creditLimits/:accountId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve Account Credit Limits',
            description: 'Retrieve existing Account Credit Limit.'
        },
        Update: {
            path: '/rtSip/beta/creditLimits/:accountId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update Account Credit Limits',
            description: 'Update existing Account Credit Limit.'
        }
    },
    CustomerBalances: {
        Index: {
            path: '/rtSip/beta/customerBalances',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Customer Balances Index',
            description: 'Retrieve a list of RT/SIP Customer Balances.'
        },
        Profile: {
            path: '/rtSip/beta/accounts/:accountId/customerBalances',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Customer Balances Profile',
            description: 'Retrieve RT/SIP Customer Balances Profile.'
        }
    },
    DirectConnect: {
        Create: {
            path: '/rtSip/beta/directConnect',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Create',
            description: 'Create a Direct Connect.'
        },
        Index: {
            path: '/rtSip/beta/directConnect',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Index',
            description: 'Retrieve a list of RT/SIP Direct Connect Entries.'
        },
        Profile: {
            path: '/rtSip/beta/directConnect/:directConnectId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Profile',
            description: 'Retrieve a RT/SIP Direct Connect Record.'
        },
        Update: {
            path: '/rtSip/beta/directConnect/:directConnectId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Update',
            description: 'Update a RT/SIP Direct Connect Record.'
        }
    },
    DirectConnectResetPassword: {
        Update: {
            path: '/rtSip/beta/directConnect/:directConnectId/resetPassword',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Update',
            description: 'Update a RT/SIP Direct Connect Record.'
        }
    },
    DirectConnectActivate: {
        Update: {
            path: '/rtSip/beta/directConnect/:directConnectId/activate',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Direct Connect Update',
            description: 'Update a RT/SIP Direct Connect Record.'
        }
    },
    FixedRating: {
        Index: {
            path: '/rtSip/beta/fixedRating',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Fixed Rating Summary Index',
            description: 'Retrieve All Fixed Rating Summary Records.'
        }
    },
    FullSummary: {
        Index: {
            path: '/rtSip/beta/fullSummary',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Full Summary',
            description: 'Full Summary Report.'
        }
    },
    Gateways: {
        Index: {
            path: '/rtSip/beta/gateways',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve Gateways',
            description: 'Retrieve All Gateways.'
        }
    },
    Invoices: {
        Index: {
            path: '/rtSip/beta/invoices',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Invoice Index',
            description: 'Retrieve a list of Invoices.'
        },
        Profile: {
            path: '/rtSip/beta/invoices/:invoiceId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Invoice Profile',
            description: 'Retrieve an Invoice Profile.'
        }
    },
    InvoiceCycleGroups: {
        Index: {
            path: '/rtSip/beta/invoices/cyclegroups',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting, Permissions.VueRatingOperator],
            title: 'Invoice Cycle Groups Index',
            description: 'Retrieve a list of Invoice cycle groups.'
        }
    },
    InvoiceDownload: {
        Profile: {
            path: '/rtSip/beta/invoices/:invoiceId/download',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting, Permissions.VueRatingOperator],
            title: 'Invoice Download',
            description: 'Download an Invoice'
        }
    },
    InvoiceRerun: {
        Create: {
            path: '/rtSip/beta/invoices/:invoiceId/rerun',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Invoice Re-Run',
            description: 'Re-Run a Invoice.'
        }
    },
    InvoiceSubscriptions: {
        Index: {
            path: '/rtSip/beta/invoices/:invoiceId/subscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Invoice Subscription Index',
            description: 'Retrieve a list of Invoice subscriptions.'
        },
        Profile: {
            path: '/rtSip/beta/invoices/:invoiceId/subscriptions/:subscriptionId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Invoice Subscription Profile',
            description: 'Retrieve an Invoice Subscription Profile.'
        }
    },
    MatchModes: {
        Index: {
            path: '/rtSip/beta/matchModes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Match Mode Index',
            description: 'Retrieve a list of Match Modes.'
        }
    },
    MarginSummary: {
        Index: {
            path: '/rtSip/beta/marginSummary',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralReporting, Permissions.PartitionBusinessReporting],
            title: 'Margin Summary Index',
            description: 'Retrieve an Summary Index.'
        }
    },
    ScenarioActions: {
        Index: {
            path: '/rtSip/beta/scenarioActions',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralOperations,
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting
            ],
            title: 'Scenario Action Index',
            description: 'Retrieve a list of RT/SIP Scenario Actions.'
        }
    },
    CdrExport: {
        Create: {
            path: '/rtSip/beta/cdrExport',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create Call Detail Export Record',
            description: 'Create a new Call Detail Export Records.'
        },
        Index: {
            path: '/rtSip/beta/cdrExport',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Record Index',
            description: 'Retrieve a list of Call Detail Records.'
        },
        Profile: {
            path: '/rtSip/beta/cdrExport/:cdrExportId',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Record Export',
            description: 'Retrieve a Call Detail Record url for download usage.'
        }
    },
    CdrExportControl: {
        Create: {
            path: '/rtSip/cdrExportControl',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Create CDR Export Control Record',
            description: 'Create new Detail Export Control Record.'
        },
        Index: {
            path: '/rtSip/cdrExportControl',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'CDR Export Control Index',
            description: 'Retrieve a list of Call Detail Export Control Records.'
        },
        Profile: {
            path: '/rtSip/cdrExportControl/:cdrExportControlId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'CDR Export Control Record',
            description: 'Retrieve specific Call Detail Export Control Record'
        },
        Update: {
            path: '/rtSip/cdrExportControl/:cdrExportControlId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Update CDR Export Control Record',
            description: 'Update existing Call Detail Export Control Record.'
        }
    },
    CdrExportIntervals: {
        Index: {
            path: '/rtSip/cdrExport/intervals',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Export Interval Records',
            description: 'Retrieve a list of Call Detail Export Interval Records.'
        }
    },
    CdrExportRetentions: {
        Index: {
            path: '/rtSip/cdrExport/retentions',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Export Retentions Records',
            description: 'Retrieve a list of Call Detail Export Retentions period Records.'
        }
    },
    CdrExportServices: {
        Index: {
            path: '/rtSip/cdrExport/services',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Export Services Records',
            description: 'Retrieve a list of Call Detail Export Services Records.'
        },
        Profile: {
            path: '/rtSip/cdrExport/services/:cdrExportServiceId',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Export Service Record',
            description: 'Retrieve specific Call Detail Export Service Record.'
        }
    },
    CdrExportSourceTypes: {
        Index: {
            path: '/rtSip/cdrExport/sourceTypes',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Report Export Source Types',
            description: 'Retrieve a list of Call Detail Report Export Source Types.'
        }
    },
    CdrExportTypes: {
        Index: {
            path: '/rtSip/cdrExport/types',
            httpMethod: 'GET',
            permissions: [
                Permissions.PartitionGeneralReporting,
                Permissions.PartitionBusinessReporting,
                Permissions.PartitionNetworkReporting,
                Permissions.VueOperator
            ],
            title: 'Call Detail Export Type Records',
            description: 'Retrieve a list of Call Detail Export Type Records.'
        }
    },
    CdrExportDestinations: {
        Create: {
            path: '/rtSip/cdrExportDestinations',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Create CDR Export Destinations Record',
            description: 'Create new Cdr Export Destination Record.'
        },
        Delete: {
            path: '/rtSip/cdrExportDestinations',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Delete Cdr Export Destination',
            description: 'Delete an existing Cdr Export Destination Record.'
        },
        Index: {
            path: '/rtSip/cdrExportDestinations',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Call Cdr Export Destination Records',
            description: 'Retrieve a list of Cdr Export Destination Records.'
        },
        Profile: {
            path: '/rtSip/cdrExportDestinations/:cdrExportDestinationId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Cdr Export Destination Profile',
            description: 'Retrieve specific Cdr Export Destination Profile'
        },
        Update: {
            path: '/rtSip/cdrExportDestinations/:cdrExportDestinationId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Update Cdr Export Destination Record',
            description: 'Update existing Cdr Export Destination Record.'
        }
    },
    MarginPlans: {
        Index: {
            path: '/rtSip/beta/marginPlans',
            httpMethod: 'GET',
            permissions: [],
            title: 'Margin Plan Index',
            description: 'Retrieve a list of Margin Plans.'
        }
    },
    NetworkEfficiency: {
        Index: {
            path: '/rtSip/beta/networkEfficiency',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Network Efficiency Index',
            description: 'Retrieve a list Network Efficiency Report.'
        }
    },
    PortLimits: {
        Create: {
            path: '/rtSip/beta/portLimits',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.VueOperator],
            title: 'Create new Port Limit',
            description: 'Create new Port Limit Record.'
        },
        Delete: {
            path: '/rtSip/beta/portLimits/:portLimitId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionAdministrator, Permissions.VueOperator],
            title: 'Delete Port Limit',
            description: 'Delete an existing Port Limit Record.'
        },
        Index: {
            path: '/rtSip/beta/portLimits',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.VueOperator],
            title: 'Port Limit Index',
            description: 'Retrieve a list of Port Limits.'
        },
        Profile: {
            path: '/rtSip/beta/portLimits/:portLimitId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.VueOperator],
            title: 'Port Limit Profile',
            description: 'Retrieve specific Port Limit Record'
        },
        Update: {
            path: '/rtSip/beta/portLimits/:portLimitId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator, Permissions.VueOperator],
            title: 'Update Port Limit Record',
            description: 'Update existing Port Limit Record.'
        }
    },
    Prompts: {
        Create: {
            path: '/rtSip/beta/prompts',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create Prompt Profile',
            description: 'Create an RT/SIP Prompt Profile record.'
        },
        Index: {
            path: '/rtSip/beta/prompts',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Prompt Index',
            description: 'Retrieve a list of RT/SIP Prompts.'
        },
        Profile: {
            path: '/rtSip/beta/prompts/:promptId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Prompt Profile',
            description: 'Retrieve an RT/SIP Prompt Profile record.'
        },
        Update: {
            path: '/rtSip/beta/prompts/:promptId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update Prompt Profile',
            description: 'Update an RT/SIP Prompt Profile record.'
        }
    },
    PromptFiles: {
        Profile: {
            path: '/rtSip/beta/prompts/:promptId/file',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Prompt Profile Audio File',
            description: 'Retrieve an RT/SIP Prompt Audio File.'
        }
    },
    PromptBulk: {
        Create: {
            path: '/rtSip/beta/prompts/bulk',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Bulk Create Prompt Profile',
            description: 'Retrieve an RT/SIP Prompt Audio File.'
        }
    },
    ReleaseCodes: {
        Index: {
            path: '/rtSip/beta/releaseCodes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Release Code Index',
            description: 'Retrieve a list of Release Codes. Release Codes from 1 to 127 are reserved for ISDN codes, from 200 to 699 are reserved for SIP codes. All other codes are RouteTrust specific.'
        }
    },
    RouteDepth: {
        Index: {
            path: '/rtSip/beta/routeDepth',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve a list of RT/SIP RouteDepths',
            description: 'Retrieve a list of RT/SIP RouteDepths.'
        }
    },
    QosManagement: {
        Index: {
            path: '/rtSip/beta/qos',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionNetworkReporting, Permissions.PartitionGeneralOperations],
            title: 'QoS Management: Bulk Search',
            description: 'Retrieve statistics on QoS rules that match the specified criteria.'
        },
        Update: {
            path: '/rtSip/beta/qos',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'QoS Management: Bulk Update',
            description: 'Update QoS rules that match the specified criteria.'
        }
    },
    RateCenters: {
        Index: {
            path: '/rtSip/beta/rateCenters',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'RT/SIP Rate Center Index',
            description: 'Retrieve a list of RT/SIP Rate Centers.'
        }
    },
    RoutePlans: {
        Index: {
            path: '/rtSip/beta/routePlans/',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve a list of RT/SIP Route Plans',
            description: 'Retrieve a list of RT/SIP Route Plans.'
        }
    },
    RoutingSummary: {
        Index: {
            path: '/rtSip/beta/routingSummary/',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionNetworkReporting],
            title: 'RT/SIP Routing summary',
            description: 'Retrieve RT/SIP Routing summary Index.'
        }
    },
    RoutingRules: {
        Create: {
            path: '/rtSip/beta/routingRules',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Routing Rule Create',
            description: 'Create an RT/SIP Routing Rule record.'
        },
        Index: {
            path: '/rtSip/beta/routingRules',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Retrieve a list of RT/SIP Routing Rules',
            description: 'Retrieve a list of RT/SIP Routing Rules.'
        },
        Profile: {
            path: '/rtSip/beta/routingRules/:routingRuleId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Routing Rule Profile',
            description: 'Retrieve an RT/SIP Routing Rule Profile record.'
        },
        Update: {
            path: '/rtSip/beta/routingRules/:routingRuleId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update Routing Rule',
            description: 'Update an RT/SIP Routing Rule record.'
        },
        Delete: {
            path: '/rtSip/beta/routingRules/:routingRuleId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Delete Routing Rule',
            description: 'Delete an existing Routing Rule Record.'
        }
    },
    RoutingRulesActionModes: {
        Index: {
            path: '/rtSip/beta/routingRuleActionModes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionNetworkReporting],
            title: 'RT/SIP Routing Rules Action Modes Index',
            description: 'Retrieve a list of Routing Rules Action Modes.'
        }
    },
    RoutingRulesMatchTypes: {
        Index: {
            path: '/rtSip/beta/routingRuleMatchTypes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionNetworkReporting],
            title: 'RT/SIP Routing Rules Match Types Index',
            description: 'Retrieve a list of Routing Rules Match Types.'
        }
    },
    Schedules: {
        Index: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/schedules',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Schedule Index',
            description: 'Retrieve a list of RT/SIP Subscription Schedules.'
        }
    },
    ServiceNumbers: {
        Create: {
            path: '/rtSip/beta/serviceNumbers',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Create a Service Number',
            description: 'Create a new RT/SIP Service Number.'
        },
        Index: {
            path: '/rtSip/beta/serviceNumbers',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Service Number Index',
            description: 'Retrieve a list of Service Numbers (DID or Toll-Free) numbers.'
        },
        Profile: {
            path: '/rtSip/beta/serviceNumbers/:serviceNumberId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Service Number Profile',
            description: 'Retrieve an RT/SIP Service Number Profile record.'
        },
        Update: {
            path: '/rtSip/beta/serviceNumbers/:serviceNumberId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Update Service Number record',
            description: 'Update an RT/SIP Service Number record.'
        },
        Delete: {
            path: '/rtSip/beta/serviceNumbers/:serviceNumberId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Delete Service Number',
            description: 'Delete an RT/SIP Service Number record.'
        }
    },
    ServiceNumbersBulk: {
        Create: {
            path: '/rtSip/beta/serviceNumbers/bulk',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Bulk Create Service Numbers',
            description: 'Bulk Create new RT/SIP Service Numbers.'
        }
    },
    ServiceNumberTag: {
        Create: {
            path: '/rtSip/beta/serviceNumberTag',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Create a Service Number Tag',
            description: 'Create a new RT/SIP Service Tag.'
        },
        Index: {
            path: '/rtSip/beta/serviceNumberTag',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Service Number Tags Index',
            description: 'Retrieve a list of Service Numbers Tags.'
        },
        Profile: {
            path: '/rtSip/beta/serviceNumberTag/:serviceNumberTagId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Service Number tag Profile',
            description: 'Retrieve an RT/SIP Service Number tag Profile record.'
        },
        Update: {
            path: '/rtSip/beta/serviceNumberTag/:serviceNumberTagId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Update Service Number tag record',
            description: 'Update an RT/SIP Service Number tag record.'
        },
        Delete: {
            path: '/rtSip/beta/serviceNumberTag/:serviceNumberTagId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Delete Service Number Tag',
            description: 'Delete an RT/SIP Service tag record.'
        }
    },
    Services: {
        Index: {
            path: '/rtSip/beta/services',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Services Index',
            description: 'Retrieve a list of Services.'
        },
        Profile: {
            path: '/rtSip/beta/services/:serviceId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Service Profile',
            description: 'Retrieve a Service Record.'
        }
    },
    SipCallSimulator: {
        Profile: {
            path: '/rtSip/beta/callSimulator',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionNetworkOperations],
            title: 'Call Simulator',
            description: 'Simulate a call, retrieve routing results.'
        }
    },
    SipTrace: {
        Index: {
            path: '/rtSip/beta/sipTraces/',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionNetworkOperations],
            title: 'Sip Trace Index',
            description: 'Retrieve a list of Sip Traces.'
        },
        Profile: {
            path: '/rtSip/beta/sipTraces/profile',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionNetworkOperations],
            title: 'Sip Trace Profile',
            description: 'Retrieve an RT/SIP Sip Trace Profile record.'
        }
    },
    SipTraceExportPcap: {
        Profile: {
            path: '/rtSip/beta/sipTraces/export/pcap',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionNetworkOperations],
            title: 'Sip Trace Export to PCAP',
            description: 'Export Sip Trace to .pcap file.'
        }
    },
    SipTraceExportText: {
        Profile: {
            path: '/rtSip/beta/sipTraces/export/text',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionNetworkOperations],
            title: 'Sip Trace Export to Text',
            description: 'Export Sip Tract to .txt file.'
        }
    },
    Scenarios: {
        Create: {
            path: '/rtSip/beta/scenarios',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create a Scenario',
            description: 'Create a new RT/SIP Scenario.'
        },
        Index: {
            path: '/rtSip/beta/scenarios',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Scenario Index',
            description: 'Retrieve a list of RT/SIP Scenarios.'
        },
        Profile: {
            path: '/rtSip/beta/scenarios/:scenarioId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Scenario Profile',
            description: 'Retrieve an RT/SIP Scenario Profile record.'
        },
        Update: {
            path: '/rtSip/beta/scenarios/:scenarioId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update Scenario',
            description: 'Update an RT/SIP Scenario.'
        }
    },
    Subscriptions: {
        Create: {
            path: '/rtSip/beta/subscriptions',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Subscription Create',
            description: 'Create a list of RT/SIP Subscriptions.'
        },
        Index: {
            path: '/rtSip/beta/subscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Subscription Index',
            description: 'Retrieve a list of RT/SIP Subscriptions.'
        },
        Profile: {
            path: '/rtSip/beta/subscriptions/:subscriptionId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Subscription Profile',
            description: 'Retrieve an RT/SIP Subscription Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptions/:subscriptionId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Subscription Update',
            description: 'Update a RT/SIP Subscription Profile record.'
        }
    },
    SubscriptionValidate: {
        Index: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/validate',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Subcription Validation',
            description: 'Retrieve a list of RT/SIP Subscription validation errors.'
        },
    },
    SubscriptionQos: {
        Index: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/qos',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription QoS Settings Index',
            description: 'Retrieve a list of RT/SIP Subscription QoS Settings.'
        },
        Profile: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/qos/:rateCenterId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription QoS Settings Profile',
            description: 'Retrieve an RT/SIP Subscription Subscription QoS Settings record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/qos/:rateCenterId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription QoS Settings record',
            description: 'Update Existing RT/SIP Subscription QoS Settings record.'
        }
    },
    SubscriptionBilling: {
        Profile: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/billing',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Billing',
            description: 'Retrieve an RT/SIP Subscription Billing Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/billing',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription Billing record',
            description: 'Update Existing RT/SIP Subscription Billing record.'
        }
    },
    SubscriptionRouting: {
        Profile: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/routing',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Routing',
            description: 'Retrieve an RT/SIP Subscription Routing Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/routing',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription Routing record',
            description: 'Update Existing RT/SIP Subscription Routing record.'
        }
    },
    SubscriptionNanp: {
        Profile: {
            path: '/rtSip/beta/subscriptionNanp/:subscriptionId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Nanp SubscriptionNanp',
            description: 'Retrieve an RT/SIP Subscription Nanp Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptionNanp/:subscriptionId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription Nanp record',
            description: 'Update Existing RT/SIP Subscription Nanp record.'
        }
    },
    SubscriptionPeering: {
        Index: {
            path: '/rtSip/beta/subscriptionPeering',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'SubscriptionPeering Index',
            description: 'Retrieve a list of RT/SIP SubscriptionPeering.'
        },
        Create: {
            path: '/rtSip/beta/subscriptionPeering',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create Subscription Peering',
            description: 'Create an RT/SIP Subscription Peering.'
        },
        Delete: {
            path: '/rtSip/beta/subscriptionPeering/:subscriptionPeeringId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Delete Subscription Peering Record',
            description: 'Delete an RT/SIP Subscription Peering.'
        },
        Profile: {
            path: '/rtSip/beta/subscriptionPeering/:subscriptionPeeringId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Peering Profile',
            description: 'Retrieve an RT/SIP Subscription Peering Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptionPeering/:subscriptionPeeringId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription Peering record',
            description: 'Update Existing RT/SIP Subscription Peering record.'
        }
    },
    SubscriptionSchedules: {
        Create: {
            path: '/rtSip/beta/subscriptionSchedules',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create Subscription Schedule Record',
            description: 'Create an RT/SIP Subscription Schedule Record.'
        },
        Delete: {
            path: '/rtSip/beta/subscriptionSchedules/:scheduleId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Delete Subscription Schedule Record',
            description: 'Delete an RT/SIP Subscription Schedule Record.'
        },
        Profile: {
            path: '/rtSip/beta/subscriptionSchedules/:scheduleId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Subscription Schedule Profile',
            description: 'Retrieve an RT/SIP Subscription Schedule Profile record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptionSchedules/:scheduleId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update RT/SIP Subscription Schedule record',
            description: 'Update Existing RT/SIP Subscription Schedule record.'
        }
    },
    SubscriptionTags: {
        Index: {
            path: '/rtSip/beta/subscriptions/:subscriptionId/subscriptionTags',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations],
            title: 'SubscriptionTags Index',
            description: 'Retrieve a list of RT/SIP SubscriptionTags.'
        }
    },
    SubscriptionsTag: {
        Create: {
            path: '/rtSip/beta/subscriptionTags',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionBusinessOperations],
            title: 'Create SubscriptionTag Profile',
            description: 'Create an RT/SIP SubscriptionTag Profile record.'
        },
        Delete: {
            path: '/rtSip/beta/subscriptionTags/:subscriptionTagId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Delete SubscriptionTag Profile',
            description: 'Delete an RT/SIP SubscriptionsTag Profile record.'
        },
        Index: {
            path: '/rtSip/beta/subscriptionTags',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations],
            title: 'SubscriptionTag Index',
            description: 'Retrieve a list of RT/SIP SubscriptionsTag.'
        },
        Profile: {
            path: '/rtSip/beta/subscriptionTags/:subscriptionTagId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'SubscriptionTag Profile',
            description: 'Retrieve an RT/SIP SubscriptionsTag record.'
        },
        Update: {
            path: '/rtSip/beta/subscriptionTags/:subscriptionTagId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Update SubscriptionTag Profile',
            description: 'Update an RT/SIP SubscriptionsTag Profile record.'
        }
    },
    Tags: {
        Index: {
            path: '/rtSip/beta/tagging/tags',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Tags Index',
            description: 'Retrieve a list of RT/SIP Tags.'
        },
        Profile: {
            path: '/rtSip/beta/tagging/tags/:tag',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Tags Profile',
            description: 'Retrieve an RT/SIP Tag Profile record.'
        }
    },
    TagAssignment: {
        Index: {
            path: '/rtSip/beta/tagging/tagAssignment',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Tag Assignment Index',
            description: 'Retrieve a list of Tag Assignment records.'
        }
    },
    TagMatching: {
        Index: {
            path: '/rtSip/beta/tagging/tagMatching',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralProvisioning],
            title: 'Tag Matching Index',
            description: 'Retrieve a list of Tag Matching records.'
        }
    },
    TrackingGroups: {
        Index: {
            path: '/rtSip/beta/trackingGroups',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Tracking Group Index',
            description: 'Retrieve a list of RT/SIP Tracking Groups.'
        },
        Profile: {
            path: '/rtSip/beta/trackingGroups/:trackingGroupId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Tracking Group Profile',
            description: 'Retrieve an RT/SIP Tracking Group Profile record.'
        },
        Create: {
            path: '/rtSip/beta/trackingGroups',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Create Tracking Group Profile',
            description: 'Create an RT/SIP Tracking Group Profile record.'
        },
        Update: {
            path: '/rtSip/beta/trackingGroups/:trackingGroupId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Update Tracking Group Profile',
            description: 'Update an RT/SIP Tracking Group Profile record.'
        }
    },
    TrendAnalysis: {
        Index: {
            path: '/rtSip/beta/trendAnalysis',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Trend Analysis Index',
            description: 'Retrieve Trend Analysis Report.'
        }
    },
    Transactions: {
        Index: {
            path: '/rtSip/beta/transactions',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations],
            title: 'Transactions Index',
            description: 'Retrieve a list of RT/SIP Transactions.'
        },
        Profile: {
            path: '/rtSip/beta/transactions/:transactionId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations],
            title: 'Transactions Profile',
            description: 'Retrieve an RT/SIP Transaction Profile record.'
        },
        Create: {
            path: '/rtSip/beta/transactions',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionBusinessProvisioning],
            title: 'Create Transaction Profile',
            description: 'Create an RT/SIP Transaction Profile record.'
        }
    },
    TransactionReversal: {
        Create: {
            path: '/rtSip/beta/transactions/:transactionId/reverse',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionBusinessProvisioning],
            title: 'Reverse a Transaction',
            description: 'Reverse a Transaction record.'
        }
    }
}

export interface IRtCommonApiRoutes extends IRtxApiRoutes {
    BillingEntity: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Countries: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    CountryCodes: {
        Index: IRtxApiRoute;
    };
    CountryDialCodes: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    Dashboard: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    DialCodes: {
        Index: IRtxApiRoute;
    };
    Downloads: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    EmailAlias: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    FileProcess: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    FileProcessFile: {
        Profile: IRtxApiRoute;
    };
    FileProcessTypes: {
        Index: IRtxApiRoute;
    };
    FileShare: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    FileShareFile: {
        Profile: IRtxApiRoute;
    };
    GuardianSwitchFileStream: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    NotificationTypes: {
        Index: IRtxApiRoute;
    };
    NanpLata: {
        Index: IRtxApiRoute;
    };
    NanpNpa: {
        Index: IRtxApiRoute;
    };
    Ocn: {
        Index: IRtxApiRoute;
    };
    OcnType: {
        Index: IRtxApiRoute;
    };
    OcnCarrierCommonNames: {
        Index: IRtxApiRoute;
    };
    NanpLerg: {
        Index: IRtxApiRoute;
    };
    NanpLerg6: {
        Index: IRtxApiRoute;
    };
    NanpLerg6ToNpaNxx: {
        Create: IRtxApiRoute;
    };
    NanpNpac: {
        Profile: IRtxApiRoute;
    };
    PeeringTypes: {
        Index: IRtxApiRoute;
    };
    PortingStatus: {
        Index: IRtxApiRoute;
    };
    PortRequest: {
        Index: IRtxApiRoute;
    };
    Products: {
        Index: IRtxApiRoute;
    };
    RatePlan: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    RatePlanDefaults: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    RatePlanRates: {
        Index: IRtxApiRoute;
    };
    RateLoads: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    RateLoadAmendment: {
        Profile: IRtxApiRoute;
    };
    RateLoadApprove: {
        Update: IRtxApiRoute;
    };
    RateLoadApproveExceptions: {
        Update: IRtxApiRoute;
    };
    RateLoadErrors: {
        Index: IRtxApiRoute;
    };
    RateLoadFile: {
        Profile: IRtxApiRoute;
    };
    RateLoadNewRates: {
        Index: IRtxApiRoute;
    };
    RateLoadOldRates: {
        Index: IRtxApiRoute;
    };
    RateLoadSummary: {
        Index: IRtxApiRoute;
    };
    Reports: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    Schedules: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ServiceNumbers: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    SipAgents: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    SipAgentConfigs: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    SipAgentDefaultConfig: {
        Profile: IRtxApiRoute;
    };
    SipAgentAddresses: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    SipAgentStatistics: {
        Index: IRtxApiRoute;
    };
    Switches: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    SwitchTypes: {
        Index: IRtxApiRoute;
    };
    SwitchAddresses: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    Timezone: {
        Index: IRtxApiRoute;
    };
    UserPageConfiguration: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
}

export const RtCommonApiRoutes: IRtCommonApiRoutes = {
    Countries: {
        Index: {
            path: '/rtSip/beta/countries',
            httpMethod: 'GET',
            permissions: [],
            title: 'Country Index',
            description: 'Retrieve a list of Country Codes.'
        },
        Profile: {
            path: '/rtSip/beta/countries/:iso3166Alpha2',
            httpMethod: 'GET',
            permissions: [],
            title: 'Country Profile',
            description: 'Retrieve a Country Profile record.'
        }
    },
    CountryCodes: {
        Index: {
            path: '/rtCommon/beta/countrycodes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Country Code Index',
            description: 'Retrieve a list of Country Codes.'
        }
    },
    CountryDialCodes: {
        Index: {
            path: '/rtCommon/beta/countryDialCodes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Country and Dial Code Index',
            description: 'Retrieve a list of Countries with dial codes.'
        },
        Profile: {
            path: '/rtCommon/beta/countryDialCodes/:iso3166Alpha3',
            httpMethod: 'GET',
            permissions: [],
            title: 'Dial codes for country',
            description: 'Retrieve a list of Dial Codes by country.'
        }
    },
    Dashboard: {
        Index: {
            path: '/rtCommon/dashboard',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Dashboard items',
            description: 'Dashboard items'
        },
        Profile: {
            path: '/rtCommon/dashboard/:dashboardTaskId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Get Dashboard item results',
            description: 'Get Dashboard item results'
        }
    },
    DialCodes: {
        Index: {
            path: '/rtCommon/beta/dialcodes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Dial Code Index',
            description: 'Retrieve a list of Dial Codes.'
        }
    },
    Downloads: {
        Index: {
            path: '/rtCommon/downloads',
            httpMethod: 'GET',
            permissions: [],
            title: 'Downloads Index',
            description: 'Retrieve a list of available file downloads.'
        },
        Profile: {
            path: '/rtCommon/downloads/:downloadId',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get Download Url',
            description: 'Retrieve a url for downloading the download file.'
        }
    },
    EmailAlias: {
        Create: {
            path: '/rtCommon/beta/emailAlias',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Create Email Alias',
            description: 'Create new Email Alias.'
        },
        Index: {
            path: '/rtCommon/beta/emailAlias',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Get Email Aliases',
            description: 'Get List of all email aliases.'
        },
        Profile: {
            path: '/rtCommon/beta/emailAlias/:aliasId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Get Email Alias',
            description: 'Get specific email alias.'
        },
        Update: {
            path: '/rtCommon/beta/emailAlias/:aliasId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Update Email Alias',
            description: 'Update the specified Email Alias.'
        }
    },
    NanpLata: {
        Index: {
            path: '/rtCommon/beta/nanp/lata',
            httpMethod: 'GET',
            permissions: [],
            title: 'Lata look up.',
            description: 'Look up LATAs from the database.'
        }
    },
    FileProcess: {
        Create: {
            path: '/rtCommon/beta/fileProcess',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Create File Process Record',
            description: 'Create new File Process Record.'
        },
        Index: {
            path: '/rtCommon/beta/fileProcess',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Get File Process Records',
            description: 'Get List of all file process records.'
        },
        Profile: {
            path: '/rtCommon/beta/fileProcess/:fileProcessId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Get FIle Process',
            description: 'Get specific File Process.'
        }
    },
    FileProcessFile: {
        Profile: {
            path: '/rtCommon/beta/fileProcess/:fileProcessId/file',
            httpMethod: 'GET',
            permissions: [],
            title: 'Process output file',
            description: 'Retrieve processed file.'
        }
    },
    FileProcessTypes: {
        Index: {
            path: '/rtCommon/beta/fileProcessTypes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get available File Process Types',
            description: 'Retrieve a list of File Process Types.'
        }
    },
    FileShare: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/rtCommon/fileShare',
            httpMethod: 'POST',
            permissions: [Permissions.Basic],
            title: 'Upload file to share',
            description: 'Upload new file to share.'
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/rtCommon/fileShare',
            httpMethod: 'GET',
            permissions: [Permissions.Basic],
            title: 'Get shared file records',
            description: 'Get list of all shared files.'
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtCommon/fileShare/:fileShareId',
            httpMethod: 'GET',
            permissions: [Permissions.Basic],
            title: 'Download shared file',
            description: 'Download specific shared file.'
        },
        Delete: {
            apiExcludeEndpoint: true,
            path: '/rtCommon/fileShare/:fileShareId',
            httpMethod: 'DELETE',
            permissions: [Permissions.Basic],
            title: 'Delete shared file',
            description: 'Delete specific shared file.'
        }
    },
    FileShareFile: {
        Profile: {
            apiExcludeEndpoint: true,
            path: '/rtCommon/fileShare/:fileShareId/file',
            httpMethod: 'GET',
            permissions: [Permissions.Basic],
            title: 'Download shared files',
            description: 'Retrieve shared file.'
        }
    },
    GuardianSwitchFileStream: {
        Index: {
            path: '/rtCommon/beta/guardianSwitchFileStreams',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Queue',
            description: 'Retrieve a list of Guardian Switch File Stream indexes.'
        },
        Profile: {
            path: '/rtCommon/beta/guardianSwitchFileStreams/:guardianSwitchFileStreamId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Profile',
            description: 'Retrieve Profile for a Guardian Switch File Stream.'
        },
        Create: {
            path: '/rtCommon/beta/guardianSwitchFileStreams',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Switch Profile',
            description: 'Create Profile for a Guardian Switch File Stream.'
        },
        Update: {
            path: '/rtCommon/beta/guardianSwitchFileStreams/:guardianSwitchFileStreamId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Switch',
            description: 'Update the specified Guardian Switch File Stream.'
        },
        Delete: {
            path: '/rtCommon/beta/guardianSwitchFileStreams/:guardianSwitchFileStreamId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Switch',
            description: 'Delete specified Guardian Switch File Stream.'
        }
    },
    NanpNpa: {
        Index: {
            path: '/rtCommon/beta/nanp/npas',
            httpMethod: 'GET',
            permissions: [],
            title: 'NANP LERG Area Codes',
            description: "Retrieve a list of valid North American Numbering Plan (NANP) Area Codes (NPA's)."
        }
    },
    NotificationTypes: {
        Index: {
            path: '/rtCommon/beta/notificationTypes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Available Notifications',
            description: 'Retrieve a list of all available notifications.'
        }
    },
    Ocn: {
        Index: {
            path: '/rtCommon/beta/nanp/ocn',
            httpMethod: 'GET',
            permissions: [],
            title: 'OCN',
            description: 'Retrieve a list of OCN'
        }
    },
    OcnType: {
        Index: {
            path: '/rtCommon/beta/nanp/ocnType',
            httpMethod: 'GET',
            permissions: [],
            title: 'OCN Type',
            description: 'Retrieve a list of OCN Types'
        }
    },
    OcnCarrierCommonNames: {
        Index: {
            path: '/rtCommon/beta/nanp/carrierCommonNames',
            httpMethod: 'GET',
            permissions: [],
            title: 'Carrier Common Names',
            description: 'Retrieve a list of Carrier Common Names'
        }
    },
    NanpLerg: {
        Index: {
            path: '/rtCommon/beta/nanp/lerg',
            httpMethod: 'GET',
            permissions: [],
            title: 'NANP LERG Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) using Area Code, Exchange and Thousand Block Pool filters.'
        }
    },
    NanpLerg6: {
        Index: {
            path: '/rtCommon/beta/nanp/lerg6',
            httpMethod: 'GET',
            permissions: [],
            title: 'NANP6 LERG Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) using Area Code, Exchange and Thousand Block Pool filters.'
        }
    },
    NanpLerg6ToNpaNxx: {
        Create: {
            path: '/rtCommon/beta/nanp/lerg6/toNpaNxx',
            httpMethod: 'POST',
            permissions: [],
            title: 'NANP6 LERG Index',
            description: 'Retrieve a list of valid North American Numbering Plan (NANP) using Area Code, Exchange and Thousand Block Pool filters.'
        }
    },
    NanpNpac: {
        Profile: {
            path: '/rtCommon/beta/nanp/lrn/:nanpNumber',
            httpMethod: 'GET',
            permissions: [],
            title: 'NANP LRN Profile',
            description: 'Retrieve LRN (Local Routing Number) information for a NANP number (if available).'
        }
    },
    PeeringTypes: {
        Index: {
            path: '/rtCommon/beta/peeringTypes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Peering Types Index',
            description: 'Retrieve a list of peering types.'
        }
    },
    PortingStatus: {
        Index: {
            path: '/rtCommon/beta/portingStatus',
            httpMethod: 'GET',
            permissions: [],
            title: 'Porting Status Index',
            description: 'Retrieve a list of porting statuses.'
        }
    },
    PortRequest: {
        Index: {
            path: '/rtCommon/beta/portRequests',
            httpMethod: 'GET',
            permissions: [],
            title: 'Port Requests Index',
            description: 'Retrieve a list of port requests.'
        }
    },
    Products: {
        Index: {
            path: '/rtCommon/beta/products',
            httpMethod: 'GET',
            permissions: [],
            title: 'Product Index',
            description: 'Retrieve a list of products.'
        }
    },
    RatePlan: {
        Index: {
            path: '/rtCommon/beta/ratePlans',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Rate Plans Index',
            description: 'Retrieve a list of rate plans.'
        },
        Profile: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Rate Plan Profile',
            description: 'Retrieve Profile for a rate plans.'
        },
        Create: {
            path: '/rtCommon/beta/ratePlans',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Rate Plan Profile',
            description: 'Create Rate Plan.'
        },
        Update: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Update Rate Plan Profile',
            description: 'Update Rate Plan.'
        }
    },
    RatePlanDefaults: {
        Index: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/defaults',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Rate Plan Defaults Index',
            description: 'Retrieve a list of rate plans defaults.'
        },
        Create: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/defaults',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Rate Plan Default',
            description: 'Create Rate Plan.'
        },
        Profile: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/defaults/:ratePlanDefaultId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Rate Plan Default Profile',
            description: 'Retrieve default rating for a rate plan.'
        },
        Update: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/defaults/:ratePlanDefaultId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Update Rate Plan Default',
            description: 'Update Rate Plan.'
        },
        Delete: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/defaults/:ratePlanDefaultId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete Rate Plan Default',
            description: 'Update Rate Plan.'
        }
    },
    RatePlanRates: {
        Index: {
            path: '/rtCommon/beta/ratePlans/:ratePlanId/rates',
            httpMethod: 'GET',
            permissions: [],
            title: 'Rate Plan Rates',
            description: 'Retrieve rates for the specified Rate Plan.'
        }
    },
    RateLoads: {
        Index: {
            path: '/rtCommon/beta/rateLoads',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator, Permissions.VueRatingOperator],
            title: 'Rate Load Queue',
            description: 'Retrieve a list of Rate Load indexes.'
        },
        Profile: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator, Permissions.VueRatingOperator],
            title: 'Rate Load Profile',
            description: 'Retrieve Profile for a Rate Load.'
        },
        Create: {
            path: '/rtCommon/beta/rateLoads',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator, Permissions.VueRatingOperator],
            title: 'Create Rate Load Profile',
            description: 'Create Profile for a Rate Load.'
        },
        Delete: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator, Permissions.VueRatingOperator],
            title: 'Delete specified Rate Load',
            description: 'Delete specified Rate Load.'
        }
    },
    RateLoadAmendment: {
        Profile: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/amendment',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Amendment File',
            description: 'Retrieve Amendment File for a Rate Load.'
        }
    },
    RateLoadApprove: {
        Update: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/approve',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Approval',
            description: 'Rate Load Approval'
        }
    },
    RateLoadApproveExceptions: {
        Update: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/approveExceptions',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Approve Exceptions',
            description: 'Approve Rate Load Exceptions'
        }
    },
    RateLoadErrors: {
        Index: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/errors',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Errors',
            description: 'Retrieve a list of Rate Load Errors.'
        }
    },
    RateLoadFile: {
        Profile: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/file',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Source File',
            description: 'Retrieve Source File for a Rate Load.'
        }
    },
    RateLoadNewRates: {
        Index: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/rates/new',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Rates',
            description: 'Retrieve a list of Rate Load Rates.'
        }
    },
    RateLoadOldRates: {
        Index: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/rates/old',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Rates',
            description: 'Retrieve a list of Rate Load Rates.'
        }
    },
    RateLoadSummary: {
        Index: {
            path: '/rtCommon/beta/rateLoads/:rateLoadId/summary',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionBusinessOperations, Permissions.VueRatingOperator],
            title: 'Rate Load Summary',
            description: 'Retrieve Summary of Rate Load.'
        }
    },
    Reports: {
        Index: {
            path: '/rtCommon/reports',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Reports Index',
            description: 'Retrieve a list of available reports.'
        },
        Profile: {
            path: '/rtCommon/reports/:productId/:source/:reportId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Report Profile',
            description: 'Retrieve full information on specific report.'
        },
        Delete: {
            path: '/rtCommon/reports/:productId/:source/:reportId',
            httpMethod: 'DELETE',
            permissions: [],
            title: 'Delete Report',
            description: 'Delete indicated report.'
        },
    },
    Schedules: {
        Index: {
            path: '/rtCommon/schedules',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Schedule Index',
            description: 'Retrieve a list of schedules.'
        },
        Profile: {
            path: '/rtCommon/schedules/:scheduleId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Schedule Profile',
            description: 'Retrieve a profile of indicated schedule.'
        },
        Create: {
            path: '/rtCommon/schedules',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Create New Schedule',
            description: 'Create a new schedule.'
        },
        Update: {
            path: '/rtCommon/schedules/:scheduleId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator, Permissions.VueManager, Permissions.VueAdministrator],
            title: 'Update existing schedule',
            description: 'Update existing schedule.'
        },
    },
    Timezone: {
        Index: {
            path: '/rtCommon/beta/timeZones',
            httpMethod: 'GET',
            permissions: [],
            title: 'Time Zones Index',
            description: 'Retrieve a list of Time Zones.'
        }
    },
    BillingEntity: {
        Index: {
            path: '/rtCommon/beta/billingEntities',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations],
            title: 'Billing Entities Index',
            description: 'Retrieve a list of Billing Entities.'
        },
        Create: {
            path: '/rtCommon/beta/billingEntities',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Create Billing Entity',
            description: 'Create new Billing Entity.'
        },
        Profile: {
            path: '/rtCommon/beta/billingEntities/:billingEntityId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Get Billing Entity',
            description: 'Get specific Billing Entity.'
        },
        Update: {
            path: '/rtCommon/beta/billingEntities/:billingEntityId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Update Billing Entity',
            description: 'Update the specified Billing Entity.'
        }
    },
    ServiceNumbers: {
        Create: {
            path: '/rtCommon/beta/serviceNumbers',
            httpMethod: 'POST',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Create Service Number',
            description: 'Create new Service Number.'
        },
        Index: {
            path: '/rtCommon/beta/serviceNumbers',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Global Service Numbers Index',
            description: 'Retrieve a list of Global Service Numbers.'
        },
        Profile: {
            path: '/rtCommon/beta/serviceNumbers/:serviceNumber',
            httpMethod: 'GET',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Get Service Number',
            description: 'Get specific Service Number.'
        },
        Update: {
            path: '/rtCommon/beta/serviceNumbers/:serviceNumber',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Service Number',
            description: 'Update the specified Service Number.'
        }
    },
    SipAgents: {
        Index: {
            path: '/rtCommon/beta/sipAgents',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Queue',
            description: 'Retrieve a list of Sip Agents.'
        },
        Profile: {
            path: '/rtCommon/beta/sipAgents/:sipAgentId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Profile',
            description: 'Retrieve Profile for a Sip Agent.'
        },
        Create: {
            path: '/rtCommon/beta/sipAgents',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Profile',
            description: 'Create Profile for a Sip Agent.'
        },
        Update: {
            path: '/rtCommon/beta/sipAgents/:sipAgentId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Sip Agent',
            description: 'Update the specified Sip Agent.'
        },
        Delete: {
            path: '/rtCommon/beta/sipAgents/:sipAgentId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Sip Agent',
            description: 'Delete specified Sip Agent.'
        }
    },
    SipAgentConfigs: {
        Index: {
            path: '/rtCommon/beta/sipAgentConfigs',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Queue',
            description: 'Retrieve a list of Sip Agent Configs.'
        },
        Profile: {
            path: '/rtCommon/beta/sipAgentConfigs/:sipAgentConfigId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Config Profile',
            description: 'Retrieve Profile for a Sip Agent Config.'
        },
        Create: {
            path: '/rtCommon/beta/sipAgentConfigs',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Config Profile',
            description: 'Create Profile for a Sip Agent Config.'
        },
        Update: {
            path: '/rtCommon/beta/sipAgentConfigs/:sipAgentConfigId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Sip Agent',
            description: 'Update the specified Sip Agent Config.'
        },
        Delete: {
            path: '/rtCommon/beta/sipAgentConfigs/:sipAgentConfigId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Sip Agent',
            description: 'Delete specified Sip Agent Config.'
        }
    },
    SipAgentDefaultConfig: {
        Profile: {
            path: '/rtCommon/beta/sipAgentDefaultConfig',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Default Config Profile',
            description: 'Retrieve Profile for Sip Agent Default Config.'
        }
    },
    SipAgentAddresses: {
        Index: {
            path: '/rtCommon/beta/sipAgentAddresses',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Addresses Queue',
            description: 'Retrieve a list of Sip Agent Addresses.'
        },
        Profile: {
            path: '/rtCommon/beta/sipAgentAddresses/:sipAgentAddressId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Address Profile',
            description: 'Retrieve Profile for a Sip Agent Address.'
        },
        Create: {
            path: '/rtCommon/beta/sipAgentAddresses',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Address Profile',
            description: 'Create Profile for a Sip Agent Address.'
        },
        Update: {
            path: '/rtCommon/beta/sipAgentAddresses/:sipAgentAddressId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Sip Agent Address',
            description: 'Update the specified Sip Agent Address.'
        },
        Delete: {
            path: '/rtCommon/beta/sipAgentAddresses/:sipAgentAddressId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Sip Agent Address',
            description: 'Delete specified Sip Agent Address.'
        }
    },
    SipAgentStatistics: {
        Index: {
            path: '/rtCommon/beta/sipAgentStatistics',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Sip Agent Statistics',
            description: 'Retrieve a list of Sip Statistics.'
        }
    },
    Switches: {
        Index: {
            path: '/rtCommon/beta/switches',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Queue',
            description: 'Retrieve a list of Switch indexes.'
        },
        Profile: {
            path: '/rtCommon/beta/switches/:switchId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Profile',
            description: 'Retrieve Profile for a Switch.'
        },
        Create: {
            path: '/rtCommon/beta/switches',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Switch Profile',
            description: 'Create Profile for a Switch.'
        },
        Update: {
            path: '/rtCommon/beta/switches/:switchId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Switch',
            description: 'Update the specified Switch.'
        },
        Delete: {
            path: '/rtCommon/beta/switches/:switchId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Switch',
            description: 'Delete specified Switch.'
        }
    },
    SwitchAddresses: {
        Index: {
            path: '/rtCommon/beta/switchAddresses',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Address Queue',
            description: 'Retrieve a list of Switch indexes.'
        },
        Profile: {
            path: '/rtCommon/beta/switchAddresses/:switchAddressId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Address Profile',
            description: 'Retrieve Profile for a Switch Address.'
        },
        Create: {
            path: '/rtCommon/beta/switchAddresses',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Create Switch Profile',
            description: 'Create Profile for a Switch Address.'
        },
        Update: {
            path: '/rtCommon/beta/switchAddresses/:switchAddressId',
            httpMethod: 'PUT',
            permissions: [Permissions.TollFreeOperator, Permissions.VueOperator],
            title: 'Update Switch',
            description: 'Update the specified Switch Address.'
        },
        Delete: {
            path: '/rtCommon/beta/switchAddresses/:switchAddressId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Delete specified Switch',
            description: 'Delete specified Switch Address.'
        }
    },
    SwitchTypes: {
        Index: {
            path: '/rtCommon/beta/switchTypes',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.VueOperator],
            title: 'Switch Type Queue',
            description: 'Retrieve a list of Switch Type indexes.'
        }
    },
    UserPageConfiguration: {
        Index: {
            path: '/rtCommon/beta/userPageConfiguration/:pageName',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get user configuration for a page',
            description: 'Retrieve configurations for a specific page.'
        },
        Update: {
            path: '/rtCommon/beta/userPageConfiguration/:pageName',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Create Configuration for a page',
            description: 'Create configuration or change it.'
        }
    }
}

export interface IRtVueApiRoutes extends IRtxApiRoutes {
    IsdnFilter: {
        Index: IRtxApiRoute;
    };
    SipFilter: {
        Index: IRtxApiRoute;
    };
    SubscriptionFilter: {
        Index: IRtxApiRoute;
    };
    RateCenterFilter: {
        Index: IRtxApiRoute;
    };
    RespOrgFilter: {
        Index: IRtxApiRoute;
    };
    EntityFilter: {
        Index: IRtxApiRoute;
    };
    OcnFilter: {
        Index: IRtxApiRoute;
    };
    OcnTypeFilter: {
        Index: IRtxApiRoute;
    };
    CarrierCommonNameFilter: {
        Index: IRtxApiRoute;
    };
    TemplateFilter: {
        Index: IRtxApiRoute;
    };
    AccountFilter: {
        Index: IRtxApiRoute;
    };
    CountryFilter: {
        Index: IRtxApiRoute;
    };
    CallTypeFilter: {
        Index: IRtxApiRoute;
    };
    ReportMetadata: {
        Profile: IRtxApiRoute;
    };
    GuardianAlertHistory: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    GuardianAlertHistoryDashboard: {
        Index: IRtxApiRoute;
    };
    GuardianAlertRules: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianAlerts: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianConnectionSubscriptions: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianConnectionSubscriptionsCount: {
        Index: IRtxApiRoute;
    };
    GuardianServiceNumberSubscriptions: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianServiceNumberSubscriptionsBulk: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianSubscriptionCic: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianAlertHistoryNotes: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    GuardianAlertTypes: {
        Index: IRtxApiRoute;
    };
    GuardianDoNotOriginate: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianDoNotOriginateBulk: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianAlertsSchedules: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianAlertsRecipients: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianFraudAlertsRecipients: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianUserQueries: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianUserQueriesSchedule: {
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianCannedQueries: {
        Index: IRtxApiRoute;
    };
    GuardianRerateControl: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianRatingSimulator: {
        Profile: IRtxApiRoute;
    };
    GuardianFraudSimulator: {
        Profile: IRtxApiRoute;
    };
    GuardianSwitches: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationTypes: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationNotes: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationBulk: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    FileLogs: {
        Index: IRtxApiRoute;
    };
    FileLogTotals: {
        Index: IRtxApiRoute;
    };
    FileLogsReDownload: {
        Create: IRtxApiRoute;
    };
    FileStreams: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    FileStreamsByCdr: {
        Index: IRtxApiRoute;
    };
    FileTypes: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Schedules: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    Connections: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliations: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationMappings: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationDisputeCount: {
        Index: IRtxApiRoute;
    };
    GuardianReconciliationDisputeAmount: {
        Index: IRtxApiRoute;
    };
    GuardianReconciliationTopOpenItems: {
        Index: IRtxApiRoute;
    };
    GuardianReconciliationMappingFilters: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianReconciliationChargeDetail: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationChargeDetailBulk: {
        Create: IRtxApiRoute;
    };
    GuardianReconciliationUsageDetails: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationUsageDetailsSummary: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    GuardianReconciliationUsageDialCodeSummary: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    GuardianReconciliationAttachments: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianFraudSystemConfig: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    GuardianFraudList: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    RoboscoreParameters: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ViewAsr: {
        Index: IRtxApiRoute;
    };
    ViewCps: {
        Index: IRtxApiRoute;
    };
    ViewCustomMargin: {
        Index: IRtxApiRoute;
    };
    ViewPorts: {
        Index: IRtxApiRoute;
    };
    ViewNumbers: {
        Index: IRtxApiRoute;
    };
    ViewNumbersLite: {
        Index: IRtxApiRoute;
    };
    ViewFinancial: {
        Index: IRtxApiRoute;
    };
    ViewFourteenDay: {
        Index: IRtxApiRoute;
    };
    ViewMonthlyTrending: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    ViewAsrLite: {
        Index: IRtxApiRoute;
    };
    ViewBirdsEye: {
        Index: IRtxApiRoute;
    };
    ViewFullSummary: {
        Index: IRtxApiRoute;
    };
    ViewCdrSearch: {
        Index: IRtxApiRoute;
    };
    ViewMessagesDetail: {
        Index: IRtxApiRoute;
    };
    ViewMessagesSummary: {
        Index: IRtxApiRoute;
    };
    ViewReconciliationUnmatchedCdrs: {
        Profile: IRtxApiRoute;
    };
    ViewReconciliation: {
        Index: IRtxApiRoute;
    };
    ViewReconciliationSummary: {
        Index: IRtxApiRoute;
    };
    ViewRejectSummary: {
        Index: IRtxApiRoute;
    };
    ViewRejectDetailsRevenue: {
        Index: IRtxApiRoute;
    };
    ViewRejectDetailsCost: {
        Index: IRtxApiRoute;
    };
    ViewRoboscore: {
        Index: IRtxApiRoute;
    };
    ViewLcoSummary: {
        Index: IRtxApiRoute;
    };
    ViewVendorReconciliation: {
        Index: IRtxApiRoute;
    };
    ViewTermSummary: {
        Index: IRtxApiRoute;
    };
    ViewTollfreeEnhancedSummary: {
        Index: IRtxApiRoute;
    };
    ViewTollfreeEnhancedDetail: {
        Index: IRtxApiRoute;
    };
    ViewUsageAuditByDay: {
        Index: IRtxApiRoute;
    };
}

export const RtVueApiRoutes: IRtVueApiRoutes = {
    IsdnFilter: {
        Index: {
            path: '/rtVue/alpha/filters/isdn',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on ISDN codes',
            description: 'Get an RtVue filter on ISDN codes.',
        },
    },
    SipFilter: {
        Index: {
            path: '/rtVue/alpha/filters/sip',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on SIP codes',
            description: 'Get an RtVue filter on SIP codes.',
        },
    },
    SubscriptionFilter: {
        Index: {
            path: '/rtVue/alpha/filters/subscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on subscriptions',
            description: 'Get an RtVue filter on subscriptions.',
        },
    },
    RateCenterFilter: {
        Index: {
            path: '/rtVue/alpha/filters/rateCenter',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator, Permissions.PartitionGeneralOperations],
            title: 'Get an RtVue filter on rate centers',
            description: 'Get an RtVue filter on rate centers.',
        },
    },
    RespOrgFilter: {
        Index: {
            path: '/rtVue/alpha/filters/respOrg',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on respOrgs',
            description: 'Get an RtVue filter on respOrgs.',
        },
    },
    EntityFilter: {
        Index: {
            path: '/rtVue/alpha/filters/entity',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on entities',
            description: 'Get an RtVue filter on entities.',
        },
    },
    OcnFilter: {
        Index: {
            path: '/rtVue/alpha/filters/ocn',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on ocn',
            description: 'Get an RtVue filter on ocn.',
        },
    },
    OcnTypeFilter: {
        Index: {
            path: '/rtVue/alpha/filters/ocnType',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on ocn type',
            description: 'Get an RtVue filter on ocn type.',
        },
    },
    CarrierCommonNameFilter: {
        Index: {
            path: '/rtVue/alpha/filters/carrierCommonName',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on carrier common names',
            description: 'Get an RtVue filter on carrier common names.',
        },
    },
    TemplateFilter: {
        Index: {
            path: '/rtVue/alpha/filters/template',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on templates',
            description: 'Get an RtVue filter on templates.',
        },
    },
    AccountFilter: {
        Index: {
            path: '/rtVue/alpha/filters/account',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on accounts',
            description: 'Get an RtVue filter on accounts.',
        },
    },
    CountryFilter: {
        Index: {
            path: '/rtVue/alpha/filters/country',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on countries',
            description: 'Get an RtVue filter on countries.',
        },
    },
    CallTypeFilter: {
        Index: {
            path: '/rtVue/alpha/filters/callType',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get an RtVue filter on call types',
            description: 'Get an RtVue filter on call types.',
        },
    },
    ReportMetadata: {
        Profile: {
            path: '/rtVue/alpha/reports/:reportId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Get a report metadata',
            description: 'Get a report metadata such as data sources and search fields',
        },
    },
    ViewAsr: {
        Index: {
            path: '/rtVue/alpha/views/asr',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Asr Index',
            description: 'GET ASR Data',
        },
    },
    ViewCps: {
        Index: {
            path: '/rtVue/alpha/views/cps',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Cps',
            description: 'Retrieve Calls Per Second.',
        },
    },
    ViewCustomMargin: {
        Index: {
            path: '/rtVue/alpha/views/custommargin',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Custom Margin',
            description: 'Custom Margin Report',
        },
    },
    ViewPorts: {
        Index: {
            path: '/rtVue/alpha/views/ports',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Ports Index',
            description: 'Retrieve Port Utilization.',
        },
    },
    ViewNumbers: {
        Index: {
            path: '/rtVue/alpha/views/numbers',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Numbers Index',
            description: 'Retrieve Data by Service Number.',
        },
    },
    ViewFinancial: {
        Index: {
            path: '/rtVue/alpha/views/financial',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Financial Summary',
            description: 'Retrieve Financial Summary Data',
        },
    },
    ViewFourteenDay: {
        Index: {
            path: '/rtVue/alpha/views/fourteenday',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: '14 day report',
            description: 'Retrieve 14 day report Data',
        },
    },
    ViewMonthlyTrending: {
        Index: {
            path: '/rtVue/alpha/views/monthlytrending',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Monthly Financial Trending',
            description: 'Trending report for month over month comparisions',
        },
        Profile: {
            path: '/rtVue/alpha/views/monthlytrending',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Monthly Financial Trending',
            description: 'Trending report for month over month comparisions',
        },
    },
    ViewAsrLite: {
        Index: {
            path: '/rtVue/alpha/views/asrlite',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'ASR Lite Report',
            description: 'Daily ASR snapshots',
        },
    },
    ViewNumbersLite: {
        Index: {
            path: '/rtVue/alpha/views/numberslite',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Numbers Lite Report',
            description: 'Daily snapshots for TF and DID numbers',
        },
    },
    ViewRoboscore: {
        Index: {
            path: '/rtVue/alpha/views/roboscore',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Roboscore Report',
            description: 'Roboscore snapshots',
        },
    },
    ViewBirdsEye: {
        Index: {
            path: '/rtVue/alpha/views/birdseyeview',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: '14 day report',
            description: 'Retrieve 14 day report Data',
        },
    },
    ViewFullSummary: {
        Index: {
            path: '/rtVue/alpha/views/fullsummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Full Summary Index',
            description: 'Full Summary Query',
        },
    },
    ViewCdrSearch: {
        Index: {
            path: '/rtVue/alpha/views/cdrsearch',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'CDR Search',
            description: 'Where to search for Call Detail Records',
        },
    },
    ViewMessagesSummary: {
        Index: {
            path: '/rtVue/alpha/views/messagesSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Messages Summary',
            description: 'SMS/MMS Summary Report',
        },
    },
    ViewMessagesDetail: {
        Index: {
            path: '/rtVue/alpha/views/messagesDetail',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Messages Detail',
            description: 'SMS/MMS Details',
        },
    },
    ViewReconciliationUnmatchedCdrs: {
        Profile: {
            path: '/rtVue/alpha/views/unmatchedCdrs/:reconciliationId',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Unmatched CDR for target or source',
            description: 'Where to find unmatched CDR for target or source',
        },
    },
    ViewReconciliation: {
        Index: {
            path: '/rtVue/alpha/views/reconciliation/:reconciliationId',
            httpMethod: 'POST',
            permissions: [Permissions.VueReconciliationOperator],
            title: 'Reconciliation Details',
            description: 'Get details given a reconciliation',
        },
    },
    ViewReconciliationSummary: {
        Index: {
            path: '/rtVue/alpha/views/reconciliationSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueReconciliationOperator],
            title: 'Reconciliation Summary',
            description: 'Where all CDRs across all streams are summarized',
        },
    },
    ViewUsageAuditByDay: {
        Index: {
            path: '/rtVue/alpha/views/usageAuditByDay',
            httpMethod: 'POST',
            permissions: [Permissions.VueReconciliationOperator],
            title: 'Usage Audit By Day',
            description: 'Take recon',
        },
    },
    ViewRejectSummary: {
        Index: {
            path: '/rtVue/alpha/views/rejects',
            httpMethod: 'POST',
            permissions: [Permissions.VueRatingOperator],
            title: 'Rejects Summary',
            description: 'A summary of calls that were not processed or not rated correctly.',
        },
    },
    ViewRejectDetailsRevenue: {
        Index: {
            path: '/rtVue/alpha/views/rejectDetailsRevenue',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Rejects details by revenue',
            description: 'A summary of calls that were not processed or not rated correctly.',
        },
    },
    ViewRejectDetailsCost: {
        Index: {
            path: '/rtVue/alpha/views/rejectDetailsCost',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Rejects details by cost',
            description: 'A summary of calls that were not processed or not rated correctly.',
        },
    },
    ViewLcoSummary: {
        Index: {
            path: '/rtVue/alpha/views/lcoSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator, Permissions.TollFreeLcrOperator],
            title: 'LCO Summary',
            description: 'Produces an LCO summary off of CDRs imported into Vue',
        },
    },
    ViewTermSummary: {
        Index: {
            path: '/rtVue/alpha/views/termSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Term Summary',
            description: 'Produces an Term summary report off of RATED CDRs ',
        },
    },
    ViewVendorReconciliation: {
        Index: {
            path: '/rtVue/alpha/views/vendorReconciliation',
            httpMethod: 'POST',
            permissions: [Permissions.VueReconciliationOperator],
            title: 'Vendor Reconciliation',
            description: 'Produces an Vendor Reconciliation of teo fileStreams',
        },
    },
    ViewTollfreeEnhancedSummary: {
        Index: {
            path: '/rtVue/alpha/views/tfEnhancedSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueTfEnhancedOperator],
            title: 'Tollfree Enhanced Summary',
            description: 'Produces an Tollfree Enhanced Summary of CDRs imported into Vue',
        },
    },
    ViewTollfreeEnhancedDetail: {
        Index: {
            path: '/rtVue/alpha/views/tfEnhancedDetail',
            httpMethod: 'POST',
            permissions: [Permissions.VueTfEnhancedOperator],
            title: 'Tollfree Enhanced Detail',
            description: 'Produces an Tollfree Enhanced Details of CDRs imported into Vue',
        },
    },
    FileStreams: {
        Index: {
            path: '/rtVue/alpha/filestreams',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileStreams Index',
            description: 'Retrieve a list of FileStreams.',
        },
        Create: {
            path: '/rtVue/alpha/filestreams',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create FileStream Profile',
            description: 'Create FileStream profile.',
        },
        Profile: {
            path: '/rtVue/alpha/filestreams/:fileStreamId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileStream Profile',
            description: 'Retrieve FileStream profile.',
        },
        Update: {
            path: '/rtVue/filestreams/:fileStreamId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update FileStream Profile',
            description: 'Update FileStream profile.',
        },
    },
    FileStreamsByCdr: {
        Index: {
            path: '/rtVue/alpha/filestreamsByCdr',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileStreams Index by CDR',
            description: 'Retrieve a list of FileStreams for CDR record.',
        },
    },
    GuardianAlertsSchedules: {
        Index: {
            path: '/rtVue/alpha/guardian/:guardianAlertId/schedules',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Schedules Index',
            description: 'Retrieve a list of Guardian Alerts Schedules.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/schedules',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Schedules Create',
            description: 'Create a Guardian Alerts Schedule.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/schedules/:guardianAlertScheduleId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Schedules Profile',
            description: 'Retrieve Guardian Alerts Schedules Record.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/schedules/:guardianAlertScheduleId',
            httpMethod: 'PUT',
            permissions: [Permissions.GuardianOperator],
            title: 'Update Guardian Alerts Schedules',
            description: 'Update Guardian Alerts Schedules.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/schedules/:guardianAlertScheduleId',
            httpMethod: 'DELETE',
            permissions: [Permissions.GuardianOperator],
            title: 'Delete Guardian Alerts Schedules',
            description: 'Delete Guardian Alerts Schedules.',
        },
    },
    GuardianAlertsRecipients: {
        Index: {
            path: '/rtVue/alpha/guardian/recipients',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Recipients Index',
            description: 'Retrieve a list of Guardian Alerts Recipients.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/recipients',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Recipients Create',
            description: 'Create a Guardian Alerts Recipients.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/recipients/:guardianAlertRecipientId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Recipients Profile',
            description: 'Retrieve Guardian Alerts Recipients Record.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/recipients/:guardianAlertRecipientId',
            httpMethod: 'PUT',
            permissions: [Permissions.GuardianOperator],
            title: 'Update Guardian Alerts Recipients',
            description: 'Update Guardian Alerts Recipients.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/recipients/:guardianAlertRecipientId',
            httpMethod: 'DELETE',
            permissions: [Permissions.GuardianOperator],
            title: 'Delete Guardian Alerts Recipients',
            description: 'Delete Guardian Alerts Recipients.',
        },
    },
    GuardianFraudAlertsRecipients: {
        Index: {
            path: '/rtVue/alpha/guardian/fraudRecipients',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Fraud Alerts Recipients Index',
            description: 'Retrieve a list of Guardian Fraud Alerts Recipients.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/fraudRecipients',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Fraud Alerts Recipients Create',
            description: 'Create a Guardian Fraud Alerts Recipients.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/fraudRecipients/:guardianAlertRecipientId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Fraud Alerts Recipients Profile',
            description: 'Retrieve Guardian Fraud Alerts Recipients Record.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/fraudRecipients/:userId',
            httpMethod: 'PUT',
            permissions: [Permissions.GuardianOperator],
            title: 'Update Guardian Fraud Alerts Recipients',
            description: 'Update Guardian Fraud Alerts Recipients.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/fraudRecipients/:userId',
            httpMethod: 'DELETE',
            permissions: [Permissions.GuardianOperator],
            title: 'Delete Guardian Fraud Alerts Recipients',
            description: 'Delete Guardian Fraud Alerts Recipients.',
        },
    },
    GuardianConnectionSubscriptions: {
        Index: {
            path: '/rtVue/alpha/guardian/connectionSubscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Connection Subscriptions Index',
            description: 'Retrieve a list of Connection Subscriptions.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/connectionSubscriptions',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Connection Subscriptions Profile',
            description: 'Create Connection Subscriptions Profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/connectionSubscriptions/:guardianConnectionSubscriptionId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Connection Subscription Profile',
            description: 'Retrieve Connection Subscription profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/connectionSubscriptions/:guardianConnectionSubscriptionId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Connection Subscription Profile',
            description: 'Update Connection Subscription profile.',
        },
    },
    GuardianConnectionSubscriptionsCount: {
        Index: {
            path: '/rtVue/alpha/guardian/connectionSubscriptionsCount',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Count Connection Subscriptions Index',
            description: 'Counts Connections for updating',
        },
    },
    GuardianServiceNumberSubscriptions: {
        Index: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptions',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Service Number Subscriptions Index',
            description: 'Retrieve a list of Service Number Subscriptions.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptions',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Service Number Subscription Profile',
            description: 'Create Service Number Subscription profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptions/:guardianServiceNumberSubscriptionId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Service Number Subscription Profile',
            description: 'Retrieve Service Number Subscription profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptions/:guardianServiceNumberSubscriptionId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Service Number Subscription Profile',
            description: 'Update Service Number Subscription profile.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptions/:guardianServiceNumberSubscriptionId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Cancels a Service Number Subscription',
            description: 'Cancels a Service Number Subscription.',
        },
    },
    GuardianServiceNumberSubscriptionsBulk: {
        Index: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptionsBulk',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Service Number Subscription indexes for Bulk Cancel',
            description: 'Retrieve Service Number Subscription indexes for Bulk Cancel.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptionsBulk',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Create Service Number Subscription Profile bulk',
            description: 'Create Service Number Subscription profile bulk.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/serviceNumberSubscriptionsBulk',
            httpMethod: 'PATCH',
            permissions: [Permissions.VueOperator],
            title: 'Update Service Number Subscription Profile bulk',
            description: 'Update Service Number Subscription profile bulk.',
        },
    },
    GuardianReconciliationBulk: {
        Index: {
            path: '/rtVue/alpha/guardian/reconciliationsBulk',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Index bulk',
            description: 'Guardian Reconciliation Index bulk.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/reconciliationsBulk',
            httpMethod: 'PATCH',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Profile bulk',
            description: 'Update Guardian Reconciliation Profile bulk.',
        },
    },
    GuardianSubscriptionCic: {
        Index: {
            path: '/rtVue/alpha/guardian/subscriptionCicMapping',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Subscription CIC Index',
            description: 'Retrieve a list of Guardian Subscription CIC.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/subscriptionCicMapping',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Subscription CIC Profile',
            description: 'Create SGuardian Subscription CIC profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/subscriptionCicMapping/:guardianSubscriptionCicId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Subscription CIC Profile',
            description: 'Retrieve Guardian Subscription CIC profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/subscriptionCicMapping/:guardianSubscriptionCicId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Subscription CIC Update',
            description: 'Update Guardian Subscription CIC profile.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/subscriptionCicMapping/:guardianSubscriptionCicId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Subscription CIC',
            description: 'Delete Guardian Subscription CIC.',
        },
    },
    GuardianAlerts: {
        Index: {
            path: '/rtVue/alpha/guardian/alerts',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Index',
            description: 'Retrieve a list of Guardian Alerts.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/alerts',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Create',
            description: 'Create a Guardian Alerts.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/alerts/:guardianAlertId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alerts Profile',
            description: 'Retrieve Guardian Alerts Record.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/alerts/:guardianAlertId',
            httpMethod: 'PUT',
            permissions: [Permissions.GuardianOperator],
            title: 'Update Guardian Alert',
            description: 'Update Guardian Alert.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/alerts/:guardianAlertId',
            httpMethod: 'DELETE',
            permissions: [Permissions.GuardianOperator],
            title: 'Delete Guardian Alert',
            description: 'Delete Guardian Alert.',
        },
    },
    GuardianAlertHistory: {
        Index: {
            path: '/rtVue/alpha/guardian/alertHistory',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Index',
            description: 'Retrieve a list of Guardian Alert History.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/alertHistory/:guardianAlertHistoryId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Profile',
            description: 'Retrieve Guardian Alert History Record.',
        },
    },
    GuardianAlertHistoryDashboard: {
        Index: {
            path: '/rtVue/alpha/guardian/alertHistoryDashboard',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Dashboard Index',
            description: 'Retrieve a list of Guardian Alert History Dashboard Data.',
        },
    },
    GuardianAlertRules: {
        Create: {
            path: '/rtVue/alpha/guardian/alertRules',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert Rules Create',
            description: 'Create a Guardian Alert Rule.',
        },
        Index: {
            path: '/rtVue/alpha/guardian/alertRules',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert Rules Index',
            description: 'Retrieve a list of Guardian Alert Rules.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/alertRules/:guardianAlertRuleId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert Rule Profile',
            description: 'Retrieve Guardian Alert Rule profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/alertRules/:guardianAlertRuleId',
            httpMethod: 'PUT',
            permissions: [Permissions.GuardianOperator],
            title: 'Update Guardian Alert Rule',
            description: 'Update Guardian Alert Rule.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/alertRules/:guardianAlertRuleId',
            httpMethod: 'DELETE',
            permissions: [Permissions.GuardianOperator],
            title: 'Delete Guardian Alert Rule',
            description: 'Delete Guardian Alert Rule.',
        },
    },
    GuardianAlertHistoryNotes: {
        Create: {
            path: '/rtVue/alpha/guardian/alertHistory/notes',
            httpMethod: 'POST',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Notes Create',
            description: 'Create a Guardian Alert History Notea.',
        },
        Index: {
            path: '/rtVue/alpha/guardian/alertHistory/:guardianAlertHistoryId/notes',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Notes Index',
            description: 'Retrieve a list of Guardian Alert History Notes.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/alertHistory/notes/:guardianAlertHistoryNoteId',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert History Note Profile',
            description: 'Retrieve Guardian Alert History Note Record.',
        },
    },
    GuardianAlertTypes: {
        Index: {
            path: '/rtVue/alpha/guardian/alertTypes',
            httpMethod: 'GET',
            permissions: [Permissions.GuardianOperator],
            title: 'Guardian Alert Types Index',
            description: 'Retrieve a list of Guardian Alert Types.',
        },
    },
    GuardianDoNotOriginate: {
        Index: {
            path: '/rtVue/alpha/guardian/doNotOriginate',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Do Not Originate Numbers Index',
            description: 'Retrieve a list of Guardian Do Not Originate Numbers.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/doNotOriginate',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Do Not Originate Number Create',
            description: 'Create a Guardian Do Not Originate Number.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/doNotOriginate/:ani',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Do Not Originate Number Profile',
            description: 'Retrieve Guardian Do Not Originate Number Record.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/doNotOriginate/:ani',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Do Not Originate Number',
            description: 'Update Guardian Do Not Originate Number.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/doNotOriginate/:ani',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Do Not Originate Number',
            description: 'Delete Guardian Do Not Originate Number.',
        },
    },
    GuardianDoNotOriginateBulk: {
        Create: {
            path: '/rtVue/alpha/guardian/doNotOriginateBulk',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Do Not Originate Number Bulk Create',
            description: 'Create multiple Guardian Do Not Originate Numbers.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/doNotOriginateBulk',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Do Not Originate Number Bulk Delete',
            description: 'Delete multiple Guardian Do Not Originate Numbers.',
        },
    },
    GuardianUserQueries: {
        Index: {
            path: '/rtVue/alpha/guardian/userQueries',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian User Query Index',
            description: 'Retrieve a list of Guardian User Query.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/userQueries',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian User Query Profile',
            description: 'Create Guardian User Query profile for a report.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian User Query Profile',
            description: 'Retrieve Guardian User Query profile for a report.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian User Query',
            description: 'Update Guardian User Query.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian User Query',
            description: 'Delete Guardian User Query.',
        },
    },
    GuardianUserQueriesSchedule: {
        Create: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId/schedule/:isGlobal',
            httpMethod: 'POST',
            permissions: [],
            title: 'Create Schedule for User Query',
            description: 'Create Guardian User Query schedule.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId/schedule/:isGlobal',
            httpMethod: 'GET',
            permissions: [],
            title: 'Guardian User Query Schedule Profile',
            description: 'Retrieve Guardian User Query Schedule profile for a report.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId/schedule/:isGlobal',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Update Guardian User Query schedule',
            description: 'Update Guardian User Query schedule.',
        },
        Delete: {
            path: '/rtVue/alpha/guardian/userQueries/:guardianUserQueryId/schedule/:isGlobal',
            httpMethod: 'DELETE',
            permissions: [],
            title: 'Delete Guardian User Query schedule',
            description: 'Delete Guardian User Query schedule.',
        },
    },
    GuardianCannedQueries: {
        Index: {
            path: '/rtVue/alpha/guardian/userCannedQueries/',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian User Canned Queries Index',
            description: 'Retrieve a list of Guardian User Query.',
        },
    },
    GuardianRerateControl: {
        Index: {
            path: '/rtVue/beta/reratecontrol',
            httpMethod: 'GET',
            permissions: [Permissions.VueRatingOperator],
            title: 'Guardian Re-rate Control Query Index',
            description: 'Retrieve a list of Guardian Rate Control items.',
        },
        Create: {
            path: '/rtVue/beta/reratecontrol',
            httpMethod: 'POST',
            permissions: [Permissions.VueRatingOperator],
            title: 'Create Guardian Re-rate Control Profile',
            description: 'Create Guardian Re-rate Control profile.',
        },
        Profile: {
            path: '/rtVue/beta/reratecontrol/:jobId',
            httpMethod: 'GET',
            permissions: [Permissions.VueRatingOperator],
            title: 'Guardian Guardian Re-rate Profile',
            description: 'Retrieve Guardian Guardian Re-rate profile.',
        },
        Update: {
            path: '/rtVue/beta/reratecontrol/:jobId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueRatingOperator],
            title: 'Update Guardian Re-rate',
            description: 'Update Guardian Re-rate.',
        },
    },
    GuardianRatingSimulator: {
        Profile: {
            path: '/rtVue/beta/ratingSimulator',
            httpMethod: 'POST',
            permissions: [Permissions.VueRatingOperator],
            title: 'Rating Simulator Profile',
            description: 'Rating Simulator profile.',
        },
    },
    GuardianFraudSimulator: {
        Profile: {
            path: '/rtVue/beta/fraudSimulator',
            httpMethod: 'POST',
            permissions: [Permissions.VueRatingOperator],
            title: 'Fraud Simulator Profile',
            description: 'Fraud Simulator profile.',
        },
    },
    GuardianSwitches: {
        Index: {
            path: '/rtVue/alpha/guardian/switches',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Switches Query Index',
            description: 'Retrieve a list of Guardian Switches items.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/switches',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Switch Profile',
            description: 'Create Guardian Switch profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/switches/:switchId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Switch Profile',
            description: 'Retrieve Guardian Switch profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/switches/:switchId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Switch',
            description: 'Update Guardian Switch.',
        },
    },
    GuardianReconciliations: {
        Index: {
            path: '/rtVue/alpha/reconciliations',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Index',
            description: 'Retrieve a list of Guardian Reconciliation Data.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliations',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Profile',
            description: 'Create a Guardian Reconciliation profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliations/:guardianReconciliationId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Profile',
            description: 'Retrieve Guardian Reconciliation profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliations/:guardianReconciliationId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Profile',
            description: 'Update Guardian Reconciliation profile.',
        },
    },
    GuardianReconciliationMappings: {
        Index: {
            path: '/rtVue/alpha/reconciliationMappings',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Mapping Index',
            description: 'Retrieve a list of Guardian Reconciliation Mapping Data.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationMappings',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Mapping Profile',
            description: 'Create a Guardian Reconciliation Mapping profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationMappings/:guardianReconciliationMappingId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Mapping Profile',
            description: 'Retrieve Guardian Reconciliation Mapping profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliationMappings/:guardianReconciliationMappingId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Mapping Profile',
            description: 'Update Guardian Reconciliation Mapping profile.',
        },
    },
    GuardianReconciliationMappingFilters: {
        Index: {
            path: '/rtVue/alpha/reconciliationMappingFilters',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Mapping Filter Index',
            description: 'Retrieve a list of Guardian Reconciliation Mapping Filter Data.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationMappingFilters',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Mapping Filter Profile',
            description: 'Create a Guardian Reconciliation Mapping Filter profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationMappingFilters/:guardianReconciliationMappingFilterId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Mapping Filter Profile',
            description: 'Retrieve Guardian Reconciliation Mapping Filter profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliationMappingFilters/:guardianReconciliationMappingFilterId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Mapping Filter Profile',
            description: 'Update Guardian Reconciliation Mapping Filter profile.',
        },
        Delete: {
            path: '/rtVue/alpha/reconciliationMappingFilters/:guardianReconciliationMappingFilterId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Reconciliation Mapping Filter Profile',
            description: 'Delete Guardian Reconciliation Mapping Filter profile.',
        },
    },
    GuardianReconciliationChargeDetail: {
        Index: {
            path: '/rtVue/alpha/reconciliationChargeDetail',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Charge Details',
            description: 'Retrieve a list of Guardian Reconciliation Charge Details.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationChargeDetail',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Charge Details',
            description: 'Create a Guardian Reconciliation Charge Detail profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationChargeDetail/:guardianReconciliationChargeDetailId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Guardian Reconciliation Charge Details Profile',
            description: 'Retrieve Guardian Reconciliation Charge Details profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliationChargeDetail/:guardianReconciliationChargeDetailId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Charge Details Profile',
            description: 'Update Guardian Reconciliation Charge Details profile.',
        },
    },
    GuardianReconciliationChargeDetailBulk: {
        Create: {
            path: '/rtVue/alpha/reconciliationChargeDetailBulk',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create multiple Guardian Reconciliation Charge Details',
            description: 'Create multiple Guardian Reconciliation Charge Detail profiles.',
        },
    },
    GuardianReconciliationUsageDetails: {
        Index: {
            path: '/rtVue/alpha/reconciliationUsageDetails',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Usage Details',
            description: 'Retrieve a list of Guardian Reconciliation Usage Details.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationUsageDetails',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Usage Details',
            description: 'Create a Guardian Reconciliation Usage Detail profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationUsageDetails/:guardianReconciliationUsageDetailId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Guardian Reconciliation Usage Details Profile',
            description: 'Retrieve Guardian Reconciliation Usage Details profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliationUsageDetails/:guardianReconciliationUsageDetailId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Usage Details Profile',
            description: 'Update Guardian Reconciliation Usage Details profile.',
        },
    },
    GuardianReconciliationUsageDetailsSummary: {
        Index: {
            path: '/rtVue/alpha/reconciliationUsageDetailsSummary',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Usage Details Summary',
            description: 'Retrieve a list of Guardian Reconciliation Usage Details Summary.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationUsageDetailsSummary',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Usage Details Summary',
            description: 'Create a Guardian Reconciliation Usage Detail Summary profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationUsageDetailsSummary/:guardianReconciliationUsageDetailSummaryId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Guardian Reconciliation Usage Details Summary Profile',
            description: 'Retrieve Guardian Reconciliation Usage Details Summary profile.',
        },
        Update: {
            path: '/rtVue/alpha/reconciliationUsageDetailsSummary/:guardianReconciliationUsageDetailSummaryId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Usage Details Summary Profile',
            description: 'Update Guardian Reconciliation Usage Details Summary profile.',
        },
    },
    GuardianReconciliationUsageDialCodeSummary: {
        Index: {
            path: '/rtVue/alpha/reconciliationUsageDialCodeSummary',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Usage Dial Code Summary',
            description: 'Retrieve a list of Guardian Reconciliation Usage Dial Code Summary.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationUsageDetailsSummary/:guardianReconciliationUsageDialCodeSummaryId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Guardian Reconciliation Usage Dial Code Summary Profile',
            description: 'Retrieve Guardian Reconciliation Usage Dial Code Summary profile.',
        },
    },
    GuardianReconciliationDisputeCount: {
        Index: {
            path: '/rtVue/alpha/reconciliationDisputeCount',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Dispute Count',
            description: 'Retrieve a count of Guardian Reconciliation Disputes.',
        },
    },
    GuardianReconciliationDisputeAmount: {
        Index: {
            path: '/rtVue/alpha/reconciliationDisputeAmount',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Dispute Amount',
            description: 'Retrieve an amount of Guardian Reconciliation Disputes.',
        },
    },
    GuardianReconciliationTopOpenItems: {
        Index: {
            path: '/rtVue/alpha/reconciliationTopOpenByPerson',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Top Open Items by person',
            description: 'Retrieve a list of Top Open Guardian Reconciliations Items by person.',
        },
    },
    GuardianReconciliationAttachments: {
        Index: {
            path: '/rtVue/alpha/reconciliationAttachments',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Attachment Index',
            description: 'Retrieve a list of Guardian Reconciliation Attachment Data.',
        },
        Create: {
            path: '/rtVue/alpha/reconciliationAttachments',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Attachment Profile',
            description: 'Create a Guardian Reconciliation Attachment profile.',
        },
        Profile: {
            path: '/rtVue/alpha/reconciliationAttachments/:guardianReconciliationAttachmentId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Attachment Profile',
            description: 'Retrieve Guardian Reconciliation Attachment profile.',
        },
        Delete: {
            path: '/rtVue/alpha/reconciliationAttachments/:guardianReconciliationAttachmentId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Reconciliation Attachment',
            description: 'Delete Guardian Reconciliation Attachments.',
        },
    },
    GuardianFraudSystemConfig: {
        Index: {
            path: '/rtVue/alpha/guardianFraudSystemConfig',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Fraud System Config Index',
            description: 'Retrieve a list of Guardian Fraud System Configs.',
        },
        Create: {
            path: '/rtVue/alpha/guardianFraudSystemConfig',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Fraud System Config',
            description: 'Create Guardian Fraud system config.',
        },
        Profile: {
            path: '/rtVue/alpha/guardianFraudSystemConfig/:guardianFraudSystemConfigId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Fraud System Config Profile',
            description: 'Retrieve Guardian Reconciliation Mapping Filter profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardianFraudSystemConfig/:guardianFraudSystemConfigId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Fraud System Config Profile',
            description: 'Update Fraud System Config profile.',
        },
        Delete: {
            path: '/rtVue/alpha/guardianFraudSystemConfig/:guardianFraudSystemConfigId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Fraud System Config Profile',
            description: 'Delete Guardian Fraud System Config Profile.',
        },
    },
    GuardianFraudList: {
        Index: {
            path: '/rtVue/alpha/guardianFraudList',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Fraud list Index',
            description: 'Retrieve a list of Guardian Fraud lists.',
        },
        Create: {
            path: '/rtVue/alpha/guardianFraudList',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Fraud List',
            description: 'Create Guardian Fraud system config.',
        },
        Profile: {
            path: '/rtVue/alpha/guardianFraudList/:guardianFraudListId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Fraud List Profile',
            description: 'Retrieve Guardian Fraud List profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardianFraudList/:guardianFraudListId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Fraud List Profile',
            description: 'Update Fraud Fraud List profile.',
        },
        Delete: {
            path: '/rtVue/alpha/guardianFraudList/:guardianFraudListId',
            httpMethod: 'DELETE',
            permissions: [Permissions.VueOperator],
            title: 'Delete Guardian Fraud List',
            description: 'Delete Guardian Fraud List.',
        },
    },
    GuardianReconciliationTypes: {
        Index: {
            path: '/rtVue/alpha/guardian/reconciliationTypes',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Types Query Index',
            description: 'Retrieve a list of Guardian Reconciliation types.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/reconciliationTypes',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Type Profile',
            description: 'Create Guardian Reconciliation Type profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/reconciliationTypes/:guardianReconciliationTypeId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Type Profile',
            description: 'Retrieve Guardian Reconciliation Type profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/reconciliationTypes/:guardianReconciliationTypeId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Type',
            description: 'Update Guardian Reconciliation Type.',
        },
    },
    GuardianReconciliationNotes: {
        Index: {
            path: '/rtVue/alpha/guardian/reconciliationNotes',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Notes Query Index',
            description: 'Retrieve a list of Guardian Reconciliation Notes.',
        },
        Create: {
            path: '/rtVue/alpha/guardian/reconciliationNotes',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Guardian Reconciliation Type Profile',
            description: 'Create Guardian Reconciliation Note profile.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/reconciliationNotes/:guardianReconciliationNoteId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Guardian Reconciliation Note Profile',
            description: 'Retrieve Guardian Reconciliation Note profile.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/reconciliationNotes/:guardianReconciliationNoteId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Guardian Reconciliation Note',
            description: 'Update Guardian Reconciliation Note.',
        },
    },
    FileLogs: {
        Index: {
            path: '/rtVue/alpha/fileLogs',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileLogs Index',
            description: 'Retrieve a list of FileLogs.',
        },
    },
    FileLogTotals: {
        Index: {
            path: '/rtVue/alpha/fileLogTotals',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileLogTotals Index',
            description: 'Retrieve a FileLogTotals.',
        },
    },
    FileLogsReDownload: {
        Create: {
            path: '/rtVue/alpha/guardian/fileLogsReDownload',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Mark file logs for re-download.',
            description: 'Mark file logs for re-download.',
            apiExcludeEndpoint: true,
        },
    },
    FileTypes: {
        Index: {
            path: '/rtVue/alpha/fileTypes',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileTypes Index',
            description: 'Retrieve a list of FileTypes.',
        },
        Profile: {
            path: '/rtVue/alpha/fileTypes/:fileStreamId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'FileType Profile',
            description: 'Retrieve FileType profile.',
        },
        Update: {
            path: '/rtVue/alpha/fileTypes/:fileTypeId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update FileType Profile',
            description: 'Update FileType profile.',
        },
    },
    RoboscoreParameters: {
        Index: {
            path: '/rtVue/alpha/guardian/roboscoreParameters',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Roboscore Parameters Index',
            description: 'Retrieve a list of parameters for Roboscoring.',
        },
        Profile: {
            path: '/rtVue/alpha/guardian/roboscoreParameters/:paramId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Roboscore Parameters Profile',
            description: 'Retrieve one Roboscore Parameter.',
        },
        Update: {
            path: '/rtVue/alpha/guardian/roboscoreParameters/:paramId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Roboscore Parameter',
            description: 'Update One Roboscore Parameter',
        },
    },
    Schedules: {
        Index: {
            path: '/rtVue/alpha/schedules',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Schedules Index',
            description: 'Retrieve a list of Schedules.',
        },
        Create: {
            path: '/rtVue/alpha/schedules',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Schedule Profile',
            description: 'Create Schedule profile.',
        },
        Profile: {
            path: '/rtVue/alpha/schedules/:scheduleId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Schedule Profile',
            description: 'Retrieve Schedule profile.',
        },
        Update: {
            path: '/rtVue/alpha/schedules/:scheduleId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Schedule Profile',
            description: 'Update Schedule profile.',
        },
    },
    Connections: {
        Index: {
            path: '/rtVue/alpha/connections',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Connections Index',
            description: 'Retrieve a list of Connections.',
        },
        Create: {
            path: '/rtVue/alpha/connections',
            httpMethod: 'POST',
            permissions: [Permissions.VueOperator],
            title: 'Create Connection Profile',
            description: 'Create Connection Profile.',
        },
        Profile: {
            path: '/rtVue/alpha/connections/:connectionId',
            httpMethod: 'GET',
            permissions: [Permissions.VueOperator],
            title: 'Connection Profile',
            description: 'Retrieve Connection profile.',
        },
        Update: {
            path: '/rtVue/alpha/connections/:connectionId',
            httpMethod: 'PUT',
            permissions: [Permissions.VueOperator],
            title: 'Update Connection Profile',
            description: 'Update Connection profile.',
        },
    },
}

export interface IRtxApiRoutesInstance extends IRtxApiRoutes {
    AuthorizationLogin: {
        Index: IRtxApiRoute;
    };
    AuthorizationTotp: {
        Index: IRtxApiRoute;
    };
    AuthorizationVerifyEmail: {
        Index: IRtxApiRoute;
    };
    AuthorizationResetPassword: {
        Create: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Search: {
        Index: IRtxApiRoute;
    };
    Branding: {
        Index: IRtxApiRoute;
    };
    MyProfileFavorites: {
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    MyProfileNotifications: {
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    MyProfile: {
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    MyProfile2FA: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    MyProfilePassword: {
        Update: IRtxApiRoute;
    };
    UserAssignablePermissions: {
        Index: IRtxApiRoute;
    };
    UserAudit: {
        Index: IRtxApiRoute;
    };
    User2FA: {
        Delete: IRtxApiRoute;
    };
    UserForceResetPassword: {
        Create: IRtxApiRoute;
    };
    UserPermissions: {
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    UserWelcomeEmail: {
        Profile: IRtxApiRoute;
    };
    Users: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
}

export const RtxApiRoutes: IRtxApiRoutesInstance = {
    AuthorizationLogin: {
        Index: {
            path: '/authorization/login',
            httpMethod: 'POST',
            permissions: [],
            title: 'Core API Login',
            description: 'Generate a JSON Web Token (JWT) to access the API. The JWT value (rtxJwt) must be sent in all authorized requests as an "Authorization" header with the value  "Bearer rtxJwt".'
        }
    },
    AuthorizationTotp: {
        Index: {
            path: '/authorization/totp',
            httpMethod: 'POST',
            permissions: [],
            title: 'Core API Login',
            description: 'Generate a JSON Web Token (JWT) to access the API. The JWT value (rtxJwt) must be sent in all authorized requests as an "Authorization" header with the value  "Bearer rtxJwt".'
        }
    },
    AuthorizationVerifyEmail: {
        Index: {
            path: '/authorization/verifyEmail',
            httpMethod: 'POST',
            permissions: [],
            title: 'Email Verification',
            description: 'Verify a User\'s Email'
        }
    },
    AuthorizationResetPassword: {
        Create: {
            path: '/authorization/resetPassword',
            httpMethod: 'POST',
            permissions: [],
            title: 'Reset Password',
            description: 'Reset Users\'s Password'
        },
        Update: {
            path: '/authorization/resetPassword',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Reset Password',
            description: 'Reset a User\'s Password'
        }
    },
    Search: {
        Index: {
            path: '/global/search',
            httpMethod: 'GET',
            permissions: [],
            title: 'Execute a Global Search',
            description: 'Execute a Global Search across multiple RouteTrust API modules.'
        }
    },
    Branding: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/public/branding',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get partition Branding Data',
            description: 'Retrieve Partition Branding Data, used by UI.'
        }
    },
    MyProfileFavorites: {
        Delete: {
            path: '/myProfile/favorites/:userFavoriteId',
            httpMethod: 'DELETE',
            permissions: [],
            title: 'Delete specified User favorite',
            description: 'Delete current user specified favorite.'
        },
        Index: {
            path: '/myProfile/favorites',
            httpMethod: 'GET',
            permissions: [],
            title: 'User favorites',
            description: 'Retrieve current user favorites configuration.'
        },
        Create: {
            path: '/myProfile/favorites',
            httpMethod: 'POST',
            permissions: [],
            title: 'Create User favorite',
            description: 'Create new user favorite.'
        },
    },
    MyProfileNotifications: {
        Delete: {
            path: '/myProfile/notifications/:userNotificationId',
            httpMethod: 'DELETE',
            permissions: [],
            title: 'Delete specified User notifications',
            description: 'Delete current user specified notification.'
        },
        Index: {
            path: '/myProfile/notifications',
            httpMethod: 'GET',
            permissions: [],
            title: 'User notifications',
            description: 'Retrieve current user notification configuration.'
        },
        Create: {
            path: '/myProfile/notifications',
            httpMethod: 'POST',
            permissions: [],
            title: 'Create User notifications',
            description: 'Create new user notification.'
        },
    },
    MyProfile: {
        Profile: {
            path: '/myProfile',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get Your User Profile',
            description: 'Get Your user profile.',
        },
        Update: {
            path: '/myProfile',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Update Your User Profile',
            description: 'Get Your user profile.',
        },
    },
    MyProfile2FA: {
        Create: {
            path: '/myProfile/2FA',
            httpMethod: 'POST',
            permissions: [],
            title: 'Add Two Factor Authentication',
            description: 'Update your profile, adding Two Factor Authentication.',
        },
        Delete: {
            path: '/myProfile/2FA',
            httpMethod: 'DELETE',
            permissions: [],
            title: 'Remove Two Factor Auth',
            description: 'Update your profile, removing Two Factor Authentication.',
        },
    },
    MyProfilePassword: {
        Update: {
            path: '/myProfile/password',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Update Your Profile\'s Password',
            description: 'Update your profile\'s password.',
        },
    },
    UserAssignablePermissions: {
        Index: {
            path: '/authorization/users/:userId/assignablePermissions',
            httpMethod: 'GET',
            permissions: [Permissions.UserManager],
            title: 'User Assignable Permissions Index',
            description: 'Retrieve a list of Permissions the specified User is allowed to assign.',
        },
    },
    UserAudit: {
        Index: {
            apiExcludeEndpoint: true,
            path: '/user/audit',
            httpMethod: 'GET',
            permissions: [],
            title: 'User audit Index',
            description: 'Retrieve a list of User Configuration changes.',
        },
    },
    UserForceResetPassword: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId/resetPassword',
            httpMethod: 'POST',
            permissions: [Permissions.UserManager],
            title: 'Force User to change password',
            description: 'Reset password for the specified user.',
        },
    },
    User2FA: {
        Delete: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId/2FA',
            httpMethod: 'DELETE',
            permissions: [Permissions.UserManager],
            title: 'Remove a User\'s 2FA',
            description: 'Remove a User\'s two-factor authentication configuration.',
        },
    },
    UserPermissions: {
        Profile: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId/permissions',
            httpMethod: 'GET',
            permissions: [Permissions.UserManager],
            title: 'User Permissions Profile',
            description: 'Retrieve a list of Permissions and attributes for the specified User.',
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId/permissions',
            httpMethod: 'PUT',
            permissions: [Permissions.UserManager],
            title: 'Update User Permissions',
            description: 'Update the specified users permissions. Note: Users cannot grant other users permissions they do not already have.',
        },
    },
    UserWelcomeEmail: {
        Profile: {
            path: '/authorization/user/:userId/resendWelcomeEmail',
            httpMethod: 'GET',
            permissions: [],
            title: 'Resend user welcome email',
            description: 'Resend user welcome email.',
        },
    },
    Users: {
        Create: {
            apiExcludeEndpoint: true,
            path: '/authorization/users',
            httpMethod: 'POST',
            permissions: [Permissions.UserManager],
            title: 'User Create',
            description: 'Create new user.',
        },
        Index: {
            apiExcludeEndpoint: true,
            path: '/authorization/users',
            httpMethod: 'GET',
            permissions: [Permissions.UserManager],
            title: 'User Index',
            description: 'Retrieve a list of Users.',
        },
        Profile: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId',
            httpMethod: 'GET',
            permissions: [Permissions.UserManager],
            title: 'User Profile',
            description: 'Retrieve a User Profile record.',
        },
        Update: {
            apiExcludeEndpoint: true,
            path: '/authorization/users/:userId',
            httpMethod: 'PUT',
            permissions: [Permissions.UserManager],
            title: 'Update User Profile',
            description: 'Update a User Profile record.',
        },
    },
}

export interface IRtxFlowApiRoutes extends IRtxApiRoutes {
    Flows: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Create: IRtxApiRoute;
    };
    FlowSteps: {
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    Job: {
        Update: IRtxApiRoute;
    };
    Jobs: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
    LumenDid: {
        Create: IRtxApiRoute;
    };
    Tasks: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TaskRequeue: {
        Create: IRtxApiRoute;
    };
    TaskRequests: {
        Profile: IRtxApiRoute;
    };
    TollFree: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
}

export const RtxFlowApiRoutes: IRtxFlowApiRoutes = {
    Flows: {
        Index: {
            path: '/rtFlow/alpha/flows',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Flows Index',
            description: 'Retrieve a list of Flows.'
        },
        Create: {
            path: '/rtFlow/alpha/flows',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Create Flow Profile',
            description: 'Create Flow profile.'
        },
        Profile: {
            path: '/rtFlow/alpha/flows/:flowId',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Flow Profile',
            description: 'Retrieve Flow profile.'
        },
        Update: {
            path: '/rtFlow/alpha/flows/:flowId',
            httpMethod: 'PUT',
            permissions: [Permissions.RtxAdministrator],
            title: 'Update Flow Profile',
            description: 'Update Flow profile.'
        },
    },
    FlowSteps: {
        Index: {
            path: '/rtFlow/alpha/flows/:flowId/steps',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Flow Steps Index',
            description: 'Retrieve a list of Flow Steps.'
        },
        Update: {
            path: '/rtFlow/alpha/flows/:flowId/steps',
            httpMethod: 'POST',
            permissions: [Permissions.RtxAdministrator],
            title: 'Bulk update Flow Steps',
            description: 'Bulk update Flow Steps.'
        },
    },
    Jobs: {
        Create: {
            path: '/rtFlow/alpha/jobs/:flowId',
            httpMethod: 'POST',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager],
            title: 'Create new Job',
            description: 'Create new job from flowId.'
        },
        Index: {
            path: '/rtFlow/alpha/jobs',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Jobs list',
            description: 'Show list of all jobs.'
        },
        Profile: {
            path: '/rtFlow/alpha/jobs/:jobId',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Job details',
            description: 'Show details of selected job.'
        },
        Update: {
            path: '/rtFlow/alpha/jobs',
            httpMethod: 'POST',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager],
            title: 'Bulk update Jobs',
            description: 'Bulk update Jobs.'
        },
        Delete: {
            path: '/rtFlow/alpha/jobs/:jobId',
            httpMethod: 'DELETE',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Job cancel',
            description: 'Cancel selected job.'
        },
    },
    Job: {
        Update: {
            path: '/rtFlow/alpha/jobs/:jobId',
            httpMethod: 'PUT',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Update Job',
            description: 'Update Job.'
        },
    },
    LumenDid: {
        Create: {
            path: '/carrierConnect/lumenDid/searchAndAssign',
            httpMethod: 'POST',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Create New Lumen Did Search/Assign job',
            description: 'Create New Lumen Did Search/Assign job'
        }
    },
    LumenRateCenters: {
        Index: {
            path: '/carrierConnect/lumenDid/rateCenters',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Get a list of Lumen DID Rate centers',
            description: 'Get a list of Lumen DID rate centers, but state'
        }
    },
    Tasks: {
        Index: {
            path: '/rtFlow/alpha/tasks/:jobStepId/:resourceId',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Show tasks associated with a jobStep resource',
            description: 'Show all tasks associated with jobStep resource'
        },
        Profile: {
            path: '/rtFlow/alpha/tasks/:jobTaskId',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Show task detail',
            description: 'Show details of task'
        },
        Update: {
            path: '/rtFlow/alpha/tasks/:jobStepId/:resourceId',
            httpMethod: 'PUT',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Update task',
            description: 'Update task'
        }
    },
    TaskRequeue: {
        Create: {
            path: '/rtFlow/alpha/tasks/:jobStepResourceId/requeue',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager],
            title: 'Requeue failed reference for indicated jobStepResource',
            description: 'Requeue failed reference for indicated jobStepResource',
        },
    },
    TaskRequests: {
        Profile: {
            path: '/rtFlow/taskRequests/:jobTaskId',
            httpMethod: 'GET',
            permissions: [],
            title: 'Show task request object',
            description: 'Show details of task integration request',
            apiExcludeEndpoint: true
        }
    },
    TollFree: {
        Create: {
            path: '/rtFlow/alpha/tollfree',
            httpMethod: 'POST',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Toll Free number activation',
            description: 'Activate Toll Free numbers.'
        },
        Delete: {
            path: '/rtFlow/alpha/tollfree',
            httpMethod: 'DELETE',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Toll Free number deletion',
            description: 'Delete Toll Free numbers.'
        },
        Index: {
            path: '/rtFlow/alpha/tollfree/status',
            httpMethod: 'POST',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Toll Free number query',
            description: 'Retrieve status of Toll Free numbers.'
        },
        Update: {
            path: '/rtFlow/alpha/tollfree',
            httpMethod: 'PUT',
            permissions: [Permissions.CarrierConnectAdministrator, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator],
            title: 'Toll Free number update',
            description: 'Update Toll Free numbers.'
        },
    },
}

export interface IIntegrationsApiRoutes extends IRtxApiRoutes {
    Integrations: {
        Index: IRtxApiRoute;
    };
    IntegrationTaskTypes: {
        Index: IRtxApiRoute;
    };
    IntegrationTaskTypeXref: {
        Index: IRtxApiRoute;
    };
    PortOutRequest: {
        Create: IRtxApiRoute;
    };
    Resources: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ResourcesNew: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ResourceConfigurations: {
        Create: IRtxApiRoute;
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    ResourceConfigurationsIndexed: {
        Index: IRtxApiRoute;
    };
    ResourceClone: {
        Create: IRtxApiRoute;
    };
    ResourceTypes: {
        Index: IRtxApiRoute;
    };
    ResourceNanp: {
        Index: IRtxApiRoute;
    };
    ResourceNanpAvailable: {
        Index: IRtxApiRoute;
    };
    ResourceUsers: {
        Index: IRtxApiRoute;
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
    };
}

export const IntegrationsApiRoutes: IIntegrationsApiRoutes = {
    Integrations: {
        Index: {
            path: '/integrations',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Integration Index',
            description: 'Retrieve a list of available Integrations.'
        }
    },
    IntegrationTaskTypes: {
        Index: {
            path: '/integrations/integrationTaskTypes',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Integration Task Types Index',
            description: 'Retrieve a list of Integration Task Types.'
        }
    },
    IntegrationTaskTypeXref: {
        Index: {
            path: '/integrations/integrationTaskTypeXref',
            httpMethod: 'GET',
            permissions: [Permissions.RtxAdministrator],
            title: 'Integration Task Types Index',
            description: 'Retrieve a list of Integration Task Types.'
        }
    },
    PortOutRequest: {
        Create: {
            path: '/integrations/:integrationId/resources/:resourceId/porting/out',
            httpMethod: 'POST',
            permissions: [],
            title: 'Port Out Validation',
            description: 'Port Our Validation Request.'
        }
    },
    Resources: {
        Create: {
            path: '/resources',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Create resource Profile',
            description: 'Create new resource Profile.'
        },
        Index: {
            path: '/resources',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.CarrierConnectOperator, Permissions.DidOperator],
            title: 'Get Resources',
            description: 'Get List of all resources.',
            menuPermissions: [Permissions.CarrierConnectManager, Permissions.CarrierConnectAdministrator, Permissions.DidManager, Permissions.DidAdministrator, Permissions.DidOperator],
        },
        Profile: {
            path: '/resources/:resourceId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectOperator, Permissions.DidOperator],
            title: 'Resource Profile',
            description: 'Retrieve a Resource Profile record.'
        },
        Update: {
            path: '/resources/:resourceId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Update Resource Profile',
            description: 'Update the specified Resource record.'
        }
    },
    ResourcesNew: {
        Create: {
            path: '/beta/resources',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Create resource Profile',
            description: 'Create new resource Profile.'
        },
        Index: {
            path: '/beta/resources',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.CarrierConnectOperator, Permissions.DidOperator],
            title: 'Get Resources',
            description: 'Get List of all resources.',
            menuPermissions: [Permissions.CarrierConnectManager, Permissions.CarrierConnectAdministrator, Permissions.DidManager, Permissions.DidAdministrator, Permissions.DidOperator],
        },
        Profile: {
            path: '/beta/resources/:resourceId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectOperator, Permissions.DidOperator],
            title: 'Resource Profile',
            description: 'Retrieve a Resource Profile record.'
        },
        Update: {
            path: '/beta/resources/:resourceId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Update Resource Profile',
            description: 'Update the specified Resource record.'
        }
    },
    ResourceConfigurations: {
        Create: {
            path: '/resourceConfigurations',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Create resource Configuration',
            description: 'Create new resource Configuration.'
        },
        Index: {
            path: '/resourceConfigurations',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator, Permissions.CarrierConnectAdministrator, Permissions.DidManager, Permissions.DidAdministrator, Permissions.DidOperator],
            title: 'Get Resource Configuration',
            description: 'Get List of all resource configuration.',
            menuPermissions: [Permissions.CarrierConnectManager, Permissions.CarrierConnectAdministrator, Permissions.DidManager, Permissions.DidAdministrator, Permissions.DidOperator],
        },
        Profile: {
            path: '/resourceConfigurations/:resourceConfigurationId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.CarrierConnectManager, Permissions.CarrierConnectOperator, Permissions.CarrierConnectAdministrator, Permissions.DidManager, Permissions.DidAdministrator, Permissions.DidOperator],
            title: 'Resource Configuration Profile',
            description: 'Retrieve a Resource Configuration Profile record.'
        },
        Update: {
            path: '/resourceConfigurations/:resourceConfigurationId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator, Permissions.CarrierConnectAdministrator, Permissions.DidAdministrator],
            title: 'Update Resource Configuration Profile',
            description: 'Update the specified Resource Configuration record.'
        }
    },
    ResourceConfigurationsIndexed: {
        Index: {
            path: '/resourceConfigurationsIndexed',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionGeneralOperations, Permissions.CarrierConnectOperator, Permissions.DidOperator],
            title: 'Get Active Resource Configurations indexed by resourceId',
            description: 'Get List of all active resource configurations indexed by resourceId.'
        },
    },
    ResourceTypes: {
        Index: {
            path: '/resourceTypes',
            httpMethod: 'GET',
            permissions: [],
            title: 'Get Resource Types',
            description: 'Get a list of all available resource types.'
        }
    },
    ResourceUsers: {
        Index: {
            path: '/resources/:resourceId/users',
            httpMethod: 'GET',
            permissions: [],
            title: 'Resource User index',
            description: 'Get a list of all users able to manage resource.'
        },
        Create: {
            path: '/resources/:resourceId/users/:userId',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Add Resource User',
            description: "Add a user's ability to manage resource."
        },
        Delete: {
            path: '/resources/:resourceId/users/:userId',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionAdministrator],
            title: 'Delete Resource Types',
            description: "Remove a user's ability to manage resource."
        }
    },
    ResourceClone: {
        Create: {
            path: '/resources/:resourceId/clone',
            httpMethod: 'POST',
            permissions: [Permissions.DidOperator, Permissions.CarrierConnectOperator],
            title: 'Clone existing resource',
            description: 'Clone existing resource.'
        }
    },
    ResourceNanp: {
        Index: {
            path: '/resources/:resourceId/nanp',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator, Permissions.CarrierConnectOperator],
            title: 'Resource Nanp index',
            description: 'Get a list of all NANP numbers assigned to specified resource.'
        }
    },
    ResourceNanpAvailable: {
        Index: {
            path: '/resources/:resourceId/nanp/available',
            httpMethod: 'GET',
            permissions: [Permissions.DidOperator, Permissions.CarrierConnectOperator],
            title: 'Search numbers from specified resource',
            description: 'Search for available NANP numbers using specified resources.'
        }
    }
}

export interface ILumenPushRoutes extends IRtxApiRoutes {
    Notification: {
        Create: IRtxApiRoute;
    };
}

export const LumenPushRoutes: ILumenPushRoutes = {
    Notification: {
        Create: {
            path: '/lumen/webhook/tollfree/:partition',
            httpMethod: 'PUT',
            permissions: [],
            title: 'Webhook',
            description: 'Lumen Webhook'
        }
    },
}

export interface ILingoApiRoutes extends IRtxApiRoutes {
    Jobs: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
    };
    Tasks: {
        Index: IRtxApiRoute;
        Profile: IRtxApiRoute;
        Update: IRtxApiRoute;
    };
    TollFree: {
        Create: IRtxApiRoute;
        Delete: IRtxApiRoute;
        Index: IRtxApiRoute;
    };
}

export const LingoApiRoutes: ILingoApiRoutes = {
    Jobs: {
        Index: {
            path: '/jobs',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectOperator],
            title: 'Jobs list',
            description: 'Show list of all jobs.'
        },
        Profile: {
            path: '/jobs/:jobId',
            httpMethod: 'GET',
            permissions: [Permissions.CarrierConnectOperator],
            title: 'Job details',
            description: 'Show details of selected job.'
        }
    },
    Tasks: {
        Index: {
            path: '/tasks/:jobStepId/:resourceId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Show tasks associated with a jobStep resource',
            description: 'Show all tasks associated with jobStep resource'
        },
        Profile: {
            path: '/tasks/:jobTaskId',
            httpMethod: 'GET',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Show task detail',
            description: 'Show details of task'
        },
        Update: {
            path: '/tasks/:jobStepId/:resourceId',
            httpMethod: 'PUT',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Update task',
            description: 'Update task'
        }
    },
    TollFree: {
        Create: {
            path: '/tollfree',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Toll Free number activation',
            description: 'Activate Toll Free numbers.'
        },
        Delete: {
            path: '/tollfree',
            httpMethod: 'DELETE',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Toll Free number deletion',
            description: 'Delete Toll Free numbers.'
        },
        Index: {
            path: '/tollfree/status',
            httpMethod: 'POST',
            permissions: [Permissions.PartitionAdministrator, Permissions.PartitionGeneralOperations],
            title: 'Toll Free number query',
            description: 'Retrieve status of Toll Free numbers.'
        },
    },
}

export interface ISomosApiRoutes extends IRtxApiRoutes {
    UnsAct: {
        Create: IRtxApiRoute;
    };
    UnsApp: {
        Create: IRtxApiRoute;
    };
    UnsAsl: {
        Create: IRtxApiRoute;
    };
    UnsCra: {
        Create: IRtxApiRoute;
    };
    UnsRch: {
        Create: IRtxApiRoute;
    };
    UnsRsv: {
        Create: IRtxApiRoute;
    };
    UnsScp: {
        Create: IRtxApiRoute;
    };
    UnsSna: {
        Create: IRtxApiRoute;
    };
}

export const SomosApiRoutes: ISomosApiRoutes = {
    UnsAct: {
        Create: {
            path: '/v1/ext/uns/act',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsApp: {
        Create: {
            path: '/v1/ext/uns/app',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsAsl: {
        Create: {
            path: '/v1/ext/uns/asl',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsCra: {
        Create: {
            path: '/v1/ext/uns/cra',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsRch: {
        Create: {
            path: '/v1/ext/uns/rch',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsRsv: {
        Create: {
            path: '/v1/ext/uns/rsv',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsScp: {
        Create: {
            path: '/v1/ext/uns/scp',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
    UnsSna: {
        Create: {
            path: '/v1/ext/uns/sna',
            httpMethod: 'POST',
            permissions: [],
            title: 'Unsolicited',
            description: 'Unsolicited'
        }
    },
}

