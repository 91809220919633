import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	CountryIndexResponse,
	RateCenterIndexResponse,
	RatePlanRateIndexRequest,
	RatePlanRateIndexResponse
} from 'RtModels';
import { RtxPageSizes } from 'RtUi/RtxUiConstants';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import {
	RatePlanContainerTabs,
	RatePlanRouter
} from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import { useRatePlans } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSubscriptionRatePlansColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { RtxForm } from 'RtUi/components/rtx/form';
import { SubmitButton } from 'RtUi/components/rtx/form/components/SubmitButton';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxCountrySelect } from 'RtUi/components/rtx/inputs/Select/instances/Country/RtxCountrySelect';
import { RtxRateCenterSelect } from 'RtUi/components/rtx/inputs/Select/instances/RateCenter/RtxRateCenterSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { UserActions } from 'RtUi/state/actions/user';
import { noop } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type RatePlanRateProps = {
	ratePlanId: number;
	autoFocusFilter?: boolean;
	onUpdate?: () => void;
};

export const RatePlanRateGrid = ({
	ratePlanId,
	onUpdate = noop
}: RatePlanRateProps) => {
	const navigate = useNavigate();
	const [resourceParams, setResourceParams] =
		useState<RatePlanRateIndexRequest>();
	const [rateCenter, setRateCenter] = useState<RateCenterIndexResponse>();
	const [country, setCountry] = useState<CountryIndexResponse>();

	const { data, isFetching: isLoading } = useRatePlans(
		{ ratePlanId },
		resourceParams,
		resourceParams !== undefined
	);

	const columns = useMemo(() => getSubscriptionRatePlansColumns(), []);

	const onSubmit = (data: RatePlanRateIndexRequest) => {
		setResourceParams({
			...data,
			pageSize: RtxPageSizes.ExcelMax
		});
		onUpdate();
	};

	return (
		<div>
			<Row>
				<Col className="mb-3">
					<RtxForm<RatePlanRateIndexRequest>
						submitButton={<SubmitButton text="Search" icon="fa-search fa-sm" />}
						cancelButton={
							<Button variant="light" className="me-2">
								<span>Clear</span>
							</Button>
						}
						onSubmit={onSubmit}
						onCancel={() => {
							setRateCenter(undefined);
							setCountry(undefined);
						}}
						defaultValues={{ requestType: 2 }}
					>
						{({ control }) => (
							<Card>
								<Card.Body>
									<Row>
										<Col md={4}>
											<Controller
												control={control}
												name="rateKey"
												render={({ field: { onChange, value } }) => (
													<RtxTextInput
														label="Rate Key (Begins)"
														value={value}
														onChange={onChange}
													/>
												)}
											/>
										</Col>
										<Col md={4}>
											<Controller
												control={control}
												name="iso3166Alpha3"
												render={({ field: { onChange, value } }) => (
													<RtxCountrySelect
														label="Country"
														value={country}
														onChange={(val) => {
															setCountry(val);
															onChange(val.iso3166Alpha3);
														}}
														initialOptionId={value}
													/>
												)}
											/>
										</Col>
										<Col md={4}>
											<Controller
												control={control}
												name="rateCenterId"
												render={({ field: { onChange, value } }) => (
													<RtxRateCenterSelect
														controlGroupClassName="flex-grow-1"
														label="Rate Center"
														value={rateCenter}
														onChange={(val) => {
															setRateCenter(val);
															onChange(val.rateCenterId);
														}}
														initialOptionId={value}
													/>
												)}
											/>
										</Col>
									</Row>
									<Controller
										control={control}
										name="requestType"
										render={({ field: { onChange, value } }) => (
											<RtxRadioInput<number>
												label=""
												onChange={onChange}
												value={value}
												options={[
													{ value: 1, label: 'Current' },
													{ value: 2, label: 'Current and Future Rates' },
													{ value: 3, label: 'All Rates' }
												]}
											/>
										)}
									/>
								</Card.Body>
							</Card>
						)}
					</RtxForm>
				</Col>
			</Row>
			{resourceParams !== undefined && (
				<Row>
					<Col>
						<DataGrid<RatePlanRateIndexResponse>
							data={data?.data}
							columns={columns}
							totalRows={data?.totalCount}
							loading={isLoading}
							pageName={'accountManagement_subscriptionRatePlans'}
							router={RateManagementRouter}
							headerAction={() => {
								const ratePlanProfileRoute =
									RatePlanRouter.getProfileRoute(ratePlanId);
								const { pathname } = BrowserHistory.location;
								const isOnRatePlanPage =
									pathname.includes(ratePlanProfileRoute);

								const hasRatePlanPermissions = UserActions.has(
									...RtCommonApiRoutes.RateLoads.Create.permissions
								);

								if (isOnRatePlanPage || !hasRatePlanPermissions) {
									return <></>;
								}

								return (
									<Button
										variant="light"
										onClick={async () => {
											navigate(
												RatePlanRouter.getProfileRoute(
													ratePlanId,
													RatePlanContainerTabs.UploadRates.header
												)
											);
										}}
									>
										<i className="fas fa-fw fa-plus" />
										<span>&nbsp;Upload Rates</span>
									</Button>
								);
							}}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};
