import { CprValuesInput } from 'RtUi/app/rt800/Cprs/lib/controls/CprValuesInput';
import { CprLabelValuesList } from 'RtUi/components/data/DataGrid/columns/CprLabelValuesDataGridColumn/components/CprLabelValuesList';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2';
import { noop } from 'lodash-es';

interface CprLabelValuesDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	onUpdate?: () => void;
}

export const CprLabelValuesDataGridColumn = <T = any,>({
	displayMode,
	size = 500,
	onUpdate = noop,
	...config
}: CprLabelValuesDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string[]) => {
		return <CprLabelValuesList value={value} shownLimit={25} />;
	};

	return DefaultDataGridColumn({
		...config,
		enableEditing: !displayMode,
		size,
		exportValue: (value: string[]) => value.join(', '),
		getValue: ({ cell }) => getValue(cell.getValue<string[]>()),
		globalFilterFn: (rows, filterValue) => {
			const values = Array.isArray(rows) ? rows : [rows];
			for (const value of values) {
				if (value.includes(filterValue)) {
					return true;
				}
			}
			return false;
		},
		filterFn: (row, id, filterValue) => {
			const rowValue: CprCol = row.getValue(id);
			const values = Array.isArray(rowValue) ? rowValue : [rowValue];
			for (const value of values) {
				if (value.includes(filterValue)) {
					return true;
				}
			}

			return false;
		},
		Edit: ({ row }) => (
			<CprValuesInput
				validator={row.original as any}
				onUpdate={onUpdate}
				shownLimit={25}
			/>
		)
	});
};
