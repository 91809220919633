import { isEmpty } from 'lodash-es';
import {
	IValidationError,
	INestJsWrappedErrorResponse
} from 'RtUi/utils/http/interfaces';

export type RtErrorType = IValidationError[] | INestJsWrappedErrorResponse;

export type RtErrorParams = {
	message?: string;
	messages?: string[];
	error?: RtErrorType | undefined;
};

export class RtError extends Error {
	/**
	 * Given a possible error, construct an RtError
	 */
	public static async createFromResponse(res: Response) {
		const isForbidden = res.status === 403;

		const error: RtErrorType = await res.json();
		const fallbackErrorMessage = 'An unknown error has occurred';

		if (isForbidden) {
			return new RtError({
				message: 'Operation was forbidden given your permissions.',
				error
			});
		}

		if ('error' in error && Array.isArray(error.message)) {
			return new RtError({ message: error.message.join('\n') });
		}

		if (Array.isArray(error)) {
			return new RtError({ error });
		}

		if ('message' in error) {
			if (typeof error.message === 'string') {
				return new RtError({ error, message: error.message });
			}

			if (Array.isArray(error.message)) {
				return new RtError({ error });
			}
		}

		return new RtError({ message: fallbackErrorMessage });
	}

	/**
	 * Given a possible error, extract a validation error for
	 * a certain property
	 */
	public static GetErrorForProperty(error: any, propertyName: string = '') {
		if (error instanceof RtError) {
			return error.getErrorForProperty(propertyName);
		}
	}

	protected pOrigin?: RtErrorType | undefined;
	protected pMessages?: string[];

	constructor(params: RtErrorParams = { message: '' }) {
		super(params.message);

		this.pMessages = params.messages;
		this.pOrigin = params.error;
	}

	/**
	 * If possible, find the errors associated with
	 * a certain property
	 */
	public getErrorForProperty(
		propertyName: string
	): IValidationError | undefined {
		const validationErrors = this.getErrorMessages();

		if (!validationErrors) {
			return;
		}

		for (const validationError of validationErrors) {
			if (typeof validationError === 'string') {
				if (validationError.includes(propertyName)) {
					return this.createValidationError(propertyName, validationError);
				}
				continue;
			}
			if (validationError.property === propertyName) {
				return validationError;
			}
		}
	}

	public get messages(): string[] {
		const validationErrors = this.getErrorMessages();
		const messages: string[] = [];

		if (!isEmpty(this.message)) {
			messages.push(this.message);
		}

		if (Array.isArray(validationErrors)) {
			for (const validationError of validationErrors) {
				if (typeof validationError === 'string') {
					messages.push(validationError);
					continue;
				} else if (typeof validationError.constraints === 'object') {
					messages.push(...Object.values(validationError.constraints));
				}
			}
		}

		return messages;
	}

	private getErrorMessages(): IValidationError[] | string[] | undefined {
		if (this.pMessages) {
			return this.pMessages;
		}
		if (!this.pOrigin) {
			return;
		}

		if (Array.isArray(this.pOrigin)) {
			return this.pOrigin;
		} else if (
			'message' in this.pOrigin &&
			Array.isArray(this.pOrigin.message)
		) {
			return this.pOrigin.message;
		}
	}

	/**
	 * Create a validation error given a property and errorStr
	 */
	private createValidationError(
		property: string,
		errorStr: string
	): IValidationError {
		return {
			target: null,
			value: null,
			property,
			children: [],
			constraints: {
				[property]: errorStr
			}
		};
	}
}
