/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from 'react';
import {
	$FileStreamProfileResponse,
	FileStreamProfileResponse,
	DownloadTypes,
	CompressionTypes,
	FileTypeIndexResponse,
	FileStreamCreateRequest,
	SwitchIndexResponse,
	ExtraFileStreamParams,
	SubscriptionIndexResponse,
	ConnectionIndexResponse
} from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { FileTypeSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileTypeSelect';
import {
	TimeZoneSelect,
	ITimeZoneResourceOption as ITimeZoneSelectOption
} from 'RtUi/components/form/TimeZoneSelect';
import { UserActions } from 'RtUi/state/actions/user';
import { Permissions } from 'RtExports/routes';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import { FileStreamDownloadEditor } from 'RtUi/app/rtVue/FileStreams/lib/forms/FileStreamDownloadEditor';
import { JSONEditor } from 'RtUi/components/ui/JsonEditor';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RtxVueConnectionSelect } from 'RtUi/components/rtx/inputs/Select/instances/VueConnection/RtxVueConnectionSelect';

export type TFileStream = Omit<
	FileStreamProfileResponse,
	'createdTs' | 'updateTs' | 'pauseTs' | 'isVueCpsPorts'
>;

const RequiredExtraFileParams: Required<ExtraFileStreamParams> = {
	useTrunkGroupLabel: false,
	addOffnetTrunkGroups: false,
	replaceQuotes: false,
	versionNumber: ''
};

interface IFileStreamEditorProps {
	onUpdate: (newFileStream: FileStreamProfileResponse) => void;
	onCancel: () => void;
	profile?: FileStreamProfileResponse;
	onEdit?: () => void;
	currentStep?: number;
	displayMode?: boolean;
}

interface IFileStreamEditorState {
	activeTab: string;
	isSubmitting: boolean;
	editingFileStream: TFileStream;
	guardianSwitch?: SwitchIndexResponse;
	error?: any;
	compressionOption?: ISimpleSelectFormControlOption<CompressionTypes>;
	downloadOption?: ISimpleSelectFormControlOption<DownloadTypes>;
	fileType?: FileTypeIndexResponse;
	timeZone?: ITimeZoneSelectOption;
	wizardStep: number;
	origSubscription?: SubscriptionIndexResponse;
	termSubscription?: SubscriptionIndexResponse;
	origConnection?: ConnectionIndexResponse;
	termConnection?: ConnectionIndexResponse;
}

export class FileStreamEditor extends Component<
	IFileStreamEditorProps,
	IFileStreamEditorState
> {
	public Tabs = {
		Profile: 'Profile',
		Download: 'Download',
		Reports: 'Reports',
		Admin: 'Admin'
	};

	private fileStreamResource = new FileStreamResource();

	constructor(props: IFileStreamEditorProps) {
		super(props);

		let editingFileStream: TFileStream;

		if (props.profile) {
			editingFileStream = cloneDeep(props.profile);
		} else {
			editingFileStream = {
				downloadFrequency: 0,
				downloadLocalTempPath: '',
				notificationLevel: '',
				notificationUsers: '',
				fileStreamId: 0,
				description: '',
				fileLabel: 'CDR',
				isActive: 0,
				isCosting: 0,
				fileTypeId: 0,
				isDownload: 0,
				isProcessed: 1,
				downloadRemoteProtocol: DownloadTypes.Sftp,
				downloadRemoteCredentials: '',
				downloadRemotePath: '',
				downloadRemoteDelete: 0,
				downloadCompressionType: CompressionTypes.None,
				downloadRemoteMovePath: '',
				downloadFileRegex: '',
				downloadLocalPath: '',
				workingLocalPath: '',
				workingOutputPath: '',
				topicRawData: '',
				topicRawDataPeakCps: 3,
				topicTransformedData: '',
				outputType: '',
				isLoadRaw: 0,
				isNetworkReporting: 0,
				isFinancialReporting: 0,
				isFraud: 0,
				isArchived: 0,
				isLrnDip: 0,
				isRating: 0,
				isReconciliation: 0,
				isEnhancedTfReporting: 0,
				archiveBucket: '',
				archiveDelay: 0,
				cdrRetention: 4,
				reportingRetention: 6,
				archiveRetention: 12,
				timezone: 'UTC',
				createConnections: 1,
				createConnectionsByNewIp: 1,
				guardianSwitchId: 0,
				origSubscriptionId: 0,
				termSubscriptionId: 0,
				origConnectionId: 0,
				termConnectionId: 0
			};
		}

		this.state = {
			isSubmitting: false,
			editingFileStream,
			activeTab: this.Tabs.Profile,
			wizardStep: this.props.currentStep || 1
		};
	}

	public updateTimeZoneOption(timeZone: ITimeZoneSelectOption) {
		this.setState({ timeZone });

		this.onChange('timezone', timeZone.timeZone);
	}

	public updateOrigConnectionOption(
		origConnection: ConnectionIndexResponse | undefined
	) {
		this.setState({ origConnection: origConnection });

		this.onChange('origConnectionId', origConnection?.connectionId || null);
	}

	public updateTermConnectionOption(
		termConnection: ConnectionIndexResponse | undefined
	) {
		this.setState({ termConnection: termConnection });

		this.onChange('termConnectionId', termConnection?.connectionId || null);
	}

	public updateOrigSubscriptionOption(
		origSubscription: SubscriptionIndexResponse | undefined
	) {
		this.setState({ origSubscription: origSubscription });

		this.onChange(
			'origSubscriptionId',
			origSubscription?.subscriptionId || null
		);
	}

	public updateTermSubscriptionOption(
		termSubscription: SubscriptionIndexResponse | undefined
	) {
		this.setState({ termSubscription: termSubscription });

		this.onChange(
			'termSubscriptionId',
			termSubscription?.subscriptionId || null
		);
	}

	private setOrigConnection(value: ConnectionIndexResponse) {
		this.setState({ origConnection: value });
	}

	private onCancel = () => {
		const editingFileStream = cloneDeep(this.props.profile);

		if (editingFileStream) {
			this.setState({ editingFileStream }, () => {
				this.props.onCancel();
			});
		} else {
			this.props.onCancel();
		}
	};

	private onSubmit = async () => {
		const { editingFileStream, fileType, guardianSwitch } = this.state;

		if (fileType) {
			editingFileStream.fileTypeId = fileType.fileTypeId;
		}

		if (guardianSwitch) {
			editingFileStream.guardianSwitchId = guardianSwitch.switchId;
		}

		try {
			let newFileStream: FileStreamProfileResponse;
			this.setState({ isSubmitting: true, error: undefined });

			if (!this.props.profile) {
				newFileStream = await this.fileStreamResource.create(
					editingFileStream as FileStreamCreateRequest
				);
			} else {
				newFileStream = await this.fileStreamResource.update(
					editingFileStream.fileStreamId,
					editingFileStream
				);
			}
			this.setState({ isSubmitting: false }, () => {
				this.props.onUpdate(newFileStream);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof TFileStream>(
		key: K,
		value: TFileStream[K]
	) => {
		if (!this.state.isSubmitting) {
			const { editingFileStream } = this.state;

			editingFileStream[key] = value;

			this.setState({ editingFileStream });
		}
	};

	private validateExtraParams = (value: any) => {
		if (!value) {
			return undefined;
		}

		const existingKeys = Object.keys(value);
		const missingProperties =
			!isEmpty(value) &&
			Object.keys(RequiredExtraFileParams).filter(
				(key) => !existingKeys.includes(key)
			);

		if (missingProperties && missingProperties.length) {
			return `Invalid JSON, missing properties: ${missingProperties.join(
				', '
			)}`;
		}

		return undefined;
	};

	public render() {
		const { editingFileStream } = this.state;
		const { onEdit = () => null } = this.props;

		return (
			<Row>
				<Col lg={10}>
					<Card>
						{this.props.displayMode && (
							<header className="d-flex border-bottom flex-row-reverse p-2">
								<Button
									type="button"
									variant="warning"
									className="text-end"
									onClick={() => onEdit()}
								>
									<i className="fas fa-fw fa-edit" />
									<span>&nbsp;Edit</span>
								</Button>
							</header>
						)}
						<FormWizard
							hideWizardActions={this.props.displayMode}
							currentStep={this.state.wizardStep}
							onChange={(wizardStep) =>
								this.setState({ wizardStep, isSubmitting: false })
							}
							isSubmitting={this.state.isSubmitting}
							error={this.state.error}
						>
							<FormWizardStep step={1} header={this.Tabs.Profile}>
								<Row>
									<Col md={6}>
										{this.props.profile && (
											<InputFormControl
												label="ID"
												readOnly={true}
												displayMode={true}
												value={String(editingFileStream.fileStreamId)}
											/>
										)}
										<InputFormControl
											required={
												$FileStreamProfileResponse.properties.description
													.isRequired
											}
											label="Description"
											onChange={(value) =>
												this.onChange('description', value ?? '')
											}
											displayMode={this.props.displayMode}
											value={String(editingFileStream.description)}
											autoHeight
										/>
										<InputFormControl
											required={
												$FileStreamProfileResponse.properties.fileLabel
													.isRequired
											}
											label="File Label"
											onChange={(value) =>
												this.onChange('fileLabel', value ?? '')
											}
											displayMode={this.props.displayMode}
											value={String(editingFileStream.fileLabel)}
											autoHeight
										/>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties.isActive
													.isRequired
											}
											onChange={(value) => this.onChange('isActive', value)}
											label="Is Active"
											displayMode={this.props.displayMode}
											value={editingFileStream.isActive}
										/>
										<FileTypeSelect
											required={
												$FileStreamProfileResponse.properties.fileTypeId
													.isRequired
											}
											clearable={
												!$FileStreamProfileResponse.properties.fileTypeId
													.isRequired
											}
											onChange={(fileType) => this.setState({ fileType })}
											displayMode={this.props.displayMode}
											initialOptionId={
												editingFileStream
													? String(editingFileStream.fileTypeId)
													: undefined
											}
											value={this.state.fileType}
										/>
										<SwitchesSelect
											required
											label="Switch"
											value={this.state.guardianSwitch}
											onChange={(guardianSwitch) => {
												this.setState({ guardianSwitch });
											}}
											displayMode={this.props.displayMode}
											initialOptionId={
												editingFileStream
													? String(editingFileStream.guardianSwitchId)
													: undefined
											}
										/>
										<br />
										<RtxVueConnectionSelect
											label="Origination Connection"
											isClearable
											value={this.state.origConnection}
											displayMode={this.props.displayMode}
											onChange={(connection) =>
												this.updateOrigConnectionOption(connection)
											}
											initialOptionId={
												editingFileStream.origConnectionId ?? undefined
											}
										/>
										<br />
										<br />
										<RtxVueConnectionSelect
											label="Termination Connection"
											isClearable
											value={this.state.termConnection}
											displayMode={this.props.displayMode}
											onChange={(connection) =>
												this.updateTermConnectionOption(connection)
											}
											initialOptionId={
												editingFileStream.termConnectionId ?? undefined
											}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Working File Match"
											onChange={(value) =>
												this.onChange('workingFileRegex', value ?? '')
											}
											displayMode={this.props.displayMode}
											value={String(editingFileStream.workingFileRegex || '')}
										/>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties.createConnections
													.isRequired
											}
											onChange={(value) =>
												this.onChange('createConnections', value)
											}
											label="Create Connections"
											displayMode={this.props.displayMode}
											value={editingFileStream.createConnections}
										/>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties
													.createConnectionsByNewIp.isRequired
											}
											onChange={(value) =>
												this.onChange('createConnectionsByNewIp', value)
											}
											label="Create Connections By New Ip"
											displayMode={this.props.displayMode}
											value={editingFileStream.createConnectionsByNewIp}
										/>
										<TimeZoneSelect
											displayMode={this.props.displayMode}
											onChange={(timeZone) =>
												this.updateTimeZoneOption(timeZone)
											}
											value={this.state.timeZone}
											initialOptionId={
												editingFileStream
													? editingFileStream.timezone
													: undefined
											}
										/>
										<InputFormControl
											label="Account Number"
											onChange={(value) =>
												this.onChange('accountNumber', value ?? '')
											}
											displayMode={this.props.displayMode}
											value={editingFileStream.accountNumber || ''}
										/>
										<SubscriptionSelect
											label="Origination Subscription"
											isClearable={true}
											displayMode={this.props.displayMode}
											onChange={(subscription) =>
												this.updateOrigSubscriptionOption(subscription)
											}
											value={this.state.origSubscription}
											initialOptionId={
												editingFileStream
													? editingFileStream.origSubscriptionId || undefined
													: undefined
											}
										/>
										<SubscriptionSelect
											label="Termination Subscription"
											isClearable={true}
											displayMode={this.props.displayMode}
											onChange={(subscription) =>
												this.updateTermSubscriptionOption(subscription)
											}
											value={this.state.termSubscription}
											initialOptionId={
												editingFileStream
													? editingFileStream.termSubscriptionId || undefined
													: undefined
											}
										/>
									</Col>
								</Row>
							</FormWizardStep>
							<FormWizardStep step={2} header={this.Tabs.Download}>
								<FileStreamDownloadEditor
									editingFileStream={editingFileStream}
									displayMode={this.props.displayMode}
									onChange={this.onChange}
								/>
							</FormWizardStep>
							<FormWizardStep
								step={3}
								header={this.Tabs.Reports}
								onSubmit={
									UserActions.has(Permissions.RtxAdministrator)
										? undefined
										: this.onSubmit
								}
							>
								<Row>
									<Col md={6}>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties.isNetworkReporting
													.isRequired
											}
											onChange={(value) =>
												this.onChange('isNetworkReporting', value)
											}
											label="Is Network Reporting"
											displayMode={this.props.displayMode}
											value={editingFileStream.isNetworkReporting}
										/>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties
													.isFinancialReporting.isRequired
											}
											onChange={(value) =>
												this.onChange('isFinancialReporting', value)
											}
											label="Is Financial Reporting"
											displayMode={this.props.displayMode}
											value={editingFileStream.isFinancialReporting}
										/>
										<BooleanRadioFormControl
											onChange={(value) => this.onChange('isFraud', value)}
											label="Is Fraud"
											displayMode={this.props.displayMode}
											value={editingFileStream.isFraud}
										/>
										<BooleanRadioFormControl
											onChange={(value) => this.onChange('isLrnDip', value)}
											label="Is LRN Dip"
											displayMode={this.props.displayMode}
											value={editingFileStream.isLrnDip}
										/>
										<BooleanRadioFormControl
											onChange={(value) =>
												this.onChange('isReconciliation', value)
											}
											label="Is Reconciliation"
											displayMode={this.props.displayMode}
											value={editingFileStream.isReconciliation}
										/>
										<BooleanRadioFormControl
											onChange={(value) =>
												this.onChange('isEnhancedTfReporting', value)
											}
											label="Is Enhanced TF Reporting"
											displayMode={this.props.displayMode}
											value={editingFileStream.isEnhancedTfReporting}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											required={
												$FileStreamProfileResponse.properties.cdrRetention
													.isRequired
											}
											onChange={(value) =>
												this.onChange('cdrRetention', Number(value))
											}
											min={1}
											label="Cdr Retention"
											type="number"
											displayMode={this.props.displayMode}
											value={String(editingFileStream.cdrRetention)}
											autoHeight
										/>
										<InputFormControl
											required={
												$FileStreamProfileResponse.properties.reportingRetention
													.isRequired
											}
											onChange={(value) =>
												this.onChange('reportingRetention', Number(value))
											}
											label="Reporting Retention"
											min={1}
											type="number"
											displayMode={this.props.displayMode}
											value={String(editingFileStream.reportingRetention)}
											autoHeight
										/>
										<InputFormControl
											required={
												$FileStreamProfileResponse.properties.archiveRetention
													.isRequired
											}
											min={1}
											onChange={(value) =>
												this.onChange('archiveRetention', Number(value))
											}
											label="Archive Retention"
											type="number"
											displayMode={this.props.displayMode}
											value={String(editingFileStream.archiveRetention)}
											autoHeight
										/>
										<BooleanRadioFormControl
											onChange={(value) => this.onChange('isRating', value)}
											label="Is Rating"
											displayMode={this.props.displayMode}
											value={editingFileStream.isRating}
										/>
										<BooleanRadioFormControl
											onChange={(value) => this.onChange('isCosting', value)}
											label="Is Costing"
											displayMode={this.props.displayMode}
											value={editingFileStream.isCosting}
										/>
										<BooleanRadioFormControl
											required={
												$FileStreamProfileResponse.properties.isArchived
													.isRequired
											}
											onChange={(value) => this.onChange('isArchived', value)}
											label="Is Archived"
											displayMode={this.props.displayMode}
											value={editingFileStream.isArchived}
										/>
									</Col>
								</Row>
							</FormWizardStep>
							{UserActions.has(Permissions.RtxAdministrator) && (
								<FormWizardStep
									step={4}
									header={this.Tabs.Admin}
									onSubmit={this.onSubmit}
								>
									<Row>
										<Col lg={10}>
											<InputFormControl
												required
												label="Topic Transformed Data"
												onChange={(value) =>
													this.onChange('topicTransformedData', value ?? '')
												}
												disabled={!this.props.displayMode}
												displayMode={true}
												value={String(editingFileStream.topicTransformedData)}
												autoHeight
											/>
											<InputFormControl
												required
												min={1}
												onChange={(value) =>
													this.onChange('topicRawDataPeakCps', Number(value))
												}
												label="Topic Raw Data Peak Cps"
												type="number"
												displayMode={this.props.displayMode}
												value={String(editingFileStream.topicRawDataPeakCps)}
												autoHeight
											/>
											<InputFormControl
												required
												label="Output Type"
												onChange={(value) =>
													this.onChange('outputType', value ?? '')
												}
												disabled={!this.props.displayMode}
												displayMode={true}
												value={String(editingFileStream.outputType)}
												autoHeight
											/>
											<InputFormControl
												required
												label="Topic Raw Data"
												onChange={(value) =>
													this.onChange('topicRawData', value ?? '')
												}
												disabled={!this.props.displayMode}
												displayMode={true}
												value={String(editingFileStream.topicRawData)}
												autoHeight
											/>
											<InputFormControl
												required
												label="Download Local Path"
												onChange={(value) =>
													this.onChange('downloadLocalPath', value ?? '')
												}
												displayMode={this.props.displayMode}
												value={String(
													editingFileStream.downloadLocalPath || ''
												)}
												autoHeight
											/>
											<InputFormControl
												label="Working Local Path"
												required
												onChange={(value) =>
													this.onChange('workingLocalPath', value ?? '')
												}
												displayMode={this.props.displayMode}
												value={String(editingFileStream.workingLocalPath || '')}
												autoHeight
											/>
											<InputFormControl
												required
												label="Working Output Path"
												onChange={(value) =>
													this.onChange('workingOutputPath', value ?? '')
												}
												displayMode={this.props.displayMode}
												value={String(
													editingFileStream.workingOutputPath || ''
												)}
												autoHeight
											/>
											<InputFormControl
												label="Download Local Temp Path"
												required
												onChange={(value) =>
													this.onChange('downloadLocalTempPath', value ?? '')
												}
												displayMode={this.props.displayMode}
												value={String(
													editingFileStream.downloadLocalTempPath || ''
												)}
												autoHeight
											/>
											<InputFormControl
												required
												label="Archive Bucket"
												onChange={(value) =>
													this.onChange('archiveBucket', value ?? '')
												}
												displayMode={this.props.displayMode}
												value={String(editingFileStream.archiveBucket)}
												autoHeight
											/>
											<JSONEditor
												label="Extra Params"
												value={editingFileStream.extraParams ?? undefined}
												customValidationError={this.validateExtraParams(
													editingFileStream.extraParams
												)}
												displayMode={this.props.displayMode}
												onChange={(value) =>
													this.onChange('extraParams', value)
												}
											/>
										</Col>
									</Row>
								</FormWizardStep>
							)}
						</FormWizard>
						{!this.props.displayMode && (
							<div className="d-flex justify-content-center">
								<Button
									type="button"
									variant="white"
									className="mb-3 mt-1"
									disabled={this.state.isSubmitting}
									onClick={this.onCancel}
								>
									Cancel
								</Button>
							</div>
						)}
					</Card>
				</Col>
			</Row>
		);
	}
}
