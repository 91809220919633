import { RtUiMenuPriority, RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { LcrApplicationRouter } from 'RtUi/app/rtLcr/rtLcr.router';
import { SipAgentStatsIndexContainer } from 'RtUi/app/rtLcr/SipAgentStats/SipAgentStats.index';
import { SipAgentStatsRouter } from 'RtUi/app/rtLcr/SipAgentStats/SipAgentStats.router';
import { SubscriptionPeeringIndexContainer } from 'RtUi/app/rtLcr/SubscriptionPeering/SubscriptionPeering.index';
import { SubscriptionPeeringRouter } from 'RtUi/app/rtLcr/SubscriptionPeering/SubscriptionPeering.router';
import { SipTraceIndexLcrContainer } from 'RtUi/app/rtSip/SipTrace/SipTrace.indexLcr';
import { SipTraceRouter } from 'RtUi/app/rtSip/SipTrace/SipTrace.router';

@RtUiModule({
	route: {
		path: `/${LcrApplicationRouter.SectionKey}`,
		iconClass: 'fa-shuffle',
		name: 'RT/LCR Pathfinder',
		orderPriority: RtUiMenuPriority.RT_PATHFINDER + 1
	},
	controllers: [
		SubscriptionPeeringIndexContainer,
		SipAgentStatsIndexContainer,
		SipTraceIndexLcrContainer
	],
	routers: [SubscriptionPeeringRouter, SipAgentStatsRouter, SipTraceRouter]
})
export class RtLcrModule {}
