import {
	RatePlanDefaultProfileResponse,
	RatePlanProfileResponse
} from 'RtModels';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RateLoadEditForm } from 'RtUi/app/AccountManagement/RateManagement/libs/forms/RateLoadEditForm';
import { RateLoadDataGrid } from 'RtUi/app/AccountManagement/RateManagement/libs/grids/RateLoadGrid';
import {
	RatePlanContainerTabs,
	RatePlanRouter
} from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import { RatePlanDefaultEditForm } from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/form/RatePlanDefaultEditForm';
import { RatePlanDefaultDataGrid } from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/grids/RatePlanDefaultDataGrid';
import { RatePlanSubscriptionsDataGrid } from 'RtUi/app/AccountManagement/RatePlan/lib/grid/RatePlanSubscriptionsDataGrid';
import { RatePlanRateGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/RatePlanRateGrid';
import { RatePlanResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/RatePlanResource';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { PartitionRatePlanEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionRatePlanEditor';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { Alert, Card, Col, Row } from 'react-bootstrap';

interface IRatePlanProfileContainerState {
	activeTab: string;
	subscriptionResource: SubscriptionResource;
	hasSharedSubscription: boolean;
	profile?: RatePlanProfileResponse;
	ratePlanDefault?: RatePlanDefaultProfileResponse | null;
}

@RatePlanRouter.getProfileRtUiController()
export class RatePlanProfileContainer extends ProfileApplicationContainer<
	{},
	IRatePlanProfileContainerState
> {
	public Tabs = RatePlanContainerTabs;
	public resource = new RatePlanResource();
	public state: IRatePlanProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		subscriptionResource: new SubscriptionResource(),
		hasSharedSubscription: false
	};
	public ratePlanDefault: RatePlanDefaultProfileResponse | null = null;

	public async componentDidMount() {
		const { subscriptionResource } = this.state;
		const id = this.getIdParam();

		const profile = await this.resource.get(id);

		subscriptionResource.getAll().then((records) => {
			const hasSharedSubscription = records.length > 0;
			this.setState({ hasSharedSubscription });
		});

		if (!profile) {
			return;
		}

		this.setState({ profile });
	}

	public setRatePlanDefaultId(
		ratePlanDefault: RatePlanDefaultProfileResponse | null
	) {
		this.ratePlanDefault = ratePlanDefault;
		if (this.ratePlanDefault) {
			this.ratePlanDefault.startTs = new Date(this.ratePlanDefault.startTs);
			if (this.ratePlanDefault.endTs) {
				this.ratePlanDefault.endTs = new Date(this.ratePlanDefault.endTs);
			}
		}
		this.setState({ ratePlanDefault });
	}

	public getProfileDefault() {
		return Number(this.getIdParam());
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		return (
			<TabbedLayout
				router={RatePlanRouter}
				activeTab={this.state.activeTab}
				profile={profile}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col md={6}>
							<Card>
								<Card.Body>
									<PartitionRatePlanEditor
										displayModes={true}
										editMode={profile}
										onUpdate={(newProfile) =>
											this.setState({
												profile: newProfile
											})
										}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Defaults}>
					{!this.ratePlanDefault && (
						<>
							<RatePlanDefaultDataGrid
								ratePlanId={profile.ratePlanId}
								onEditClick={(ratePlanDefault) =>
									this.setRatePlanDefaultId(ratePlanDefault)
								}
							/>
						</>
					)}
					{this.ratePlanDefault && (
						<RatePlanDefaultEditForm
							ratePlanId={this.ratePlanDefault.ratePlanId}
							profile={this.ratePlanDefault}
							onCancel={() => this.setRatePlanDefaultId(null)}
						/>
					)}
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Rates}>
					<RatePlanRateGrid ratePlanId={profile.ratePlanId} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.RateLoadHistory}>
					<RateLoadDataGrid
						resourceParams={{ ratePlanId: profile.ratePlanId }}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Subscriptions}>
					<RatePlanSubscriptionsDataGrid
						resourceParams={{ ratePlanId: Number(this.getIdParam()) }}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.UploadRates}>
					<Row>
						<Col xs={{ order: 2 }} lg={{ order: 1, span: 6 }}>
							<RateLoadEditForm
								ratePlanId={profile.ratePlanId}
								onCancel={() =>
									this.setState({ activeTab: this.Tabs.Profile.header })
								}
								onSuccess={(profile) =>
									this.goToPath(
										RateManagementRouter.getProfileRoute(profile.rateLoadId)
									)
								}
							/>
						</Col>
						<Col xs={{ order: 1 }} lg={{ order: 2, span: 6 }} className="mb-3">
							{!this.state.hasSharedSubscription && (
								<Alert variant="info-outline">
									There are no subscriptions assigned to this rate plan.
								</Alert>
							)}
							{this.state.hasSharedSubscription && (
								<Card>
									<Alert variant="warning rounded-0 rounded-top mb-0">
										<b>
											The following subscriptions are assigned to this rate plan
										</b>
									</Alert>
									<RatePlanSubscriptionsDataGrid
										resourceParams={{ ratePlanId: Number(this.getIdParam()) }}
									/>
								</Card>
							)}
						</Col>
					</Row>
				</TabbedLayoutTab>

				<TabbedLayoutTab {...this.Tabs.CreateRatePlanDefault}>
					<RatePlanDefaultEditForm
						ratePlanId={this.getProfileDefault()}
						// onCancel={() =>
						// 			this.setState({ activeTab: this.Tabs.Profile.header })
						// 		}
						// onSuccess={(profile) =>
						// 			this.goToPath(
						// 				RateManagementRouter.getProfileRoute(profile.rateLoadId)
						// 			)
						// 		}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
