import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	SipAgentConfigIndexResponse,
	SipAgentConfigProfileResponse
} from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { SipAgentRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import { AdministrationRouter } from 'RtUi/app/Administration/Administration.router';

interface ISipAgentConfigProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const SipAgentConfigProfileContainerTabs: ISipAgentConfigProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class SipAgentConfigRouterClass extends AdministrationRouter<
	SipAgentConfigIndexResponse,
	SipAgentConfigProfileResponse,
	ISipAgentConfigProfileContainerTabs
> {
	constructor() {
		super(
			'SipAgentConfigs',
			'sipAgentConfigs',
			'sipAgentConfigId',
			SipAgentConfigProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.SipAgentConfigs);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile: SipAgentConfigProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const sectionBreadcrumb = super.getSectionBreadcrumb();
		const sipAgentBreadcrumb = SipAgentRouter.getIndexBreadcrumb();
		const sipAgentConfigBreadcrumb = this.getProfileBreadcrumb(
			String(profile.sipAgentConfigId),
			profile
		);

		return [
			sectionBreadcrumb,
			{ ...sipAgentBreadcrumb, name: 'SIP Agent Config' },
			sipAgentConfigBreadcrumb
		];
	}

	public getIndexLabel(record: SipAgentConfigIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: SipAgentConfigProfileResponse) {
		return profile.label;
	}
}

export const SipAgentConfigRouter = new SipAgentConfigRouterClass();
