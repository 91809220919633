import { ButtonGroup, IconButton } from '@mui/material';
import {
	DateTimePicker,
	DateTimePickerProps,
	PickersActionBarAction,
	PickerValidDate
} from '@mui/x-date-pickers';
import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import RemoveIcon from '@mui/icons-material/Remove';
import { addHours, endOfDay, startOfDay, subHours } from 'date-fns';
import { noop } from 'lodash-es';

export type RtxDateTimeInputProps = Omit<
	DateTimePickerProps<PickerValidDate, false>,
	'views' | 'slotProps'
> & {
	displayMode?: boolean;
	required?: boolean;
	clearable?: boolean;
};

export const RtxDateTimeInput = ({
	required,
	disabled,
	displayMode,
	onChange = noop,
	clearable,
	...props
}: RtxDateTimeInputProps) => {
	let internalValue = new Date();
	if (props.value) {
		internalValue = props.value;
	}

	const onChangeHandler = (value: Date | null) => {
		if (value === null) {
			return;
		}
		const newValue = value;
		internalValue = newValue;
		onChange(newValue, { validationError: null });
	};

	const actions: PickersActionBarAction[] = ['cancel', 'accept'];
	if (clearable) {
		actions.unshift('clear');
	}

	return (
		<div className={clsx('d-flex align-items-center', 'mb-3')}>
			<DateTimePicker
				views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
				disabled={disabled || displayMode}
				onChange={onChangeHandler}
				slotProps={{
					actionBar: {
						actions: actions
					},
					textField: {
						required,
						InputProps: {
							...((disabled || displayMode) && { endAdornment: null })
						},
						sx: {
							'width': '100%',
							'.MuiInputBase-root': {
								'marginTop': '0',
								'padding': '10px 14px 5px',
								'.MuiInputBase-input': {
									'padding': 0,
									'&.Mui-disabled': {
										fontWeight: 550,
										color: '#9c9c9c',
										WebkitTextFillColor: 'unset'
									}
								}
							}
						},
						InputLabelProps: {
							shrink: true
						}
					}
				}}
				{...props}
			/>
			<ButtonGroup>
				<IconButton
					onClick={() =>
						onChangeHandler(
							startOfDay(
								internalValue.setSeconds(internalValue.getSeconds() - 1)
							)
						)
					}
				>
					<FastRewindIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						onChangeHandler(
							endOfDay(internalValue.setSeconds(internalValue.getSeconds() + 1))
						)
					}
				>
					<FastForwardIcon />
				</IconButton>
				<IconButton onClick={() => onChangeHandler(subHours(internalValue, 1))}>
					<RemoveIcon />
				</IconButton>
				<IconButton onClick={() => onChangeHandler(addHours(internalValue, 1))}>
					<AddIcon />
				</IconButton>
			</ButtonGroup>
		</div>
	);
};
