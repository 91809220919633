import { RocRequestDocumentsGrid } from 'RtUi/app/rt800/RespOrgChange/lib/grids/RocRequestDocumentsGrid';
import { RocRequestNumbersGrid } from 'RtUi/app/rt800/RespOrgChange/lib/grids/RocRequestNumbersGrid';
import {
	useGetRocRequest,
	useGetRocRequestActions
} from 'RtUi/app/rt800/RespOrgChange/lib/services';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { ROCNumberStatusMap, timestampToReadable } from 'RtUi/utils/maps';
import { useMemo } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { RocRequestRouter } from './RocRequest.router';
import { CancelRocRequestCard } from './lib/components/CancelRocRequestCard';
import { IRocProfileResponse } from './lib/resources/RocRequestResource';
import { Loading } from 'RtUi/components/ui/Loading';

export const RocRequestProfileContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		RocRequestRouter.getProfileTabs()
	);
	const rocTxnId = useIdParam();

	const {
		data: rocRequest,
		refetch,
		isFetching
	} = useGetRocRequest({ rocTxnId });
	const { data: actions, isLoading } = useGetRocRequestActions();

	const rocRequestActions = useMemo(() => actions?.actionItems, [actions]);

	if (isFetching || isLoading) {
		return <Loading />;
	}

	if (!rocRequest || !rocRequestActions) {
		return <p>Not Found!</p>;
	}

	const renderCancelComponent = () => {
		const { isIncoming, tfns } = rocRequest;
		const isCancellable = tfns.every((tfn) => {
			const rocStatus = ROCNumberStatusMap.find(
				(rocNumberStatus) => rocNumberStatus.status === tfn.rocStatusId
			);

			if (!rocStatus) {
				return false;
			}

			return rocStatus.cancelable;
		});

		if (isIncoming || !isCancellable) {
			return null;
		}

		return <CancelRocRequestCard rocTxnId={rocRequest.rocTxnId} />;
	};

	return (
		<TabbedLayout<IRocProfileResponse>
			router={RocRequestRouter}
			profile={rocRequest}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<Row>
					<Col lg={8} className="mb-3">
						<Card body>
							<Form>
								<Row>
									<Col lg>
										<InputFormControl
											label="Gaining RespOrg"
											displayMode
											value={rocRequest.requestingRespOrgId}
										/>
									</Col>
									<Col lg>
										<InputFormControl
											label="Submitted By RespOrg"
											displayMode
											value={rocRequest.submittingRespOrgId}
										/>
									</Col>
								</Row>
								<Row>
									<Col lg>
										<InputFormControl
											label="Submitted Timestamp"
											displayMode
											value={timestampToReadable(rocRequest.submittedTs)}
										/>
									</Col>
									<Col lg>
										<InputFormControl
											label="Due Timestamp"
											displayMode
											value={
												rocRequest.dueTs
													? String(rocRequest.dueTs).substring(0, 10)
													: ''
											}
										/>
									</Col>
								</Row>
								<Row>
									<Col lg={6}>
										<TextAreaFormControl
											label="Notes"
											displayMode
											value={rocRequest.notes}
										/>
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>
					<Col lg={4}>{renderCancelComponent()}</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Numbers}>
				<RocRequestNumbersGrid
					rocTxnId={rocTxnId}
					isIncoming={rocRequest.isIncoming}
					data={rocRequest.tfns}
					actions={rocRequestActions}
					onUpdateNumbers={() => refetch()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Documents}>
				<RocRequestDocumentsGrid
					data={rocRequest.documents}
					rocTxnId={rocTxnId}
					onUpload={() => refetch()}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

RocRequestRouter.setProfileRtUiFunctionalComponent(RocRequestProfileContainer);
