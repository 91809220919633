/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	GuardianAlertHistoryFailureData,
	GuardianAlertHistoryIndexResponse
} from 'RtModels';
import { AlertHistoryResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryResource';
import { FraudConfigurationRouter } from 'RtUi/app/rtGuardian/FraudConfiguration/FraudConfiguration.router';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { Item } from 'react-contexify';
import { NavigateFunction } from 'react-router-dom';
import * as JSURL from 'jsurl';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { isEmpty } from 'lodash-es';
import { LinkDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LinkDataGridColumn';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const alertHistoryMenuLinks = (
	row: GuardianAlertHistoryIndexResponse,
	navigate: NavigateFunction
) => {
	const isFraud = AlertHistoryResource.isFraudAlertType(row.alertRuleId);

	if (!isFraud) {
		return null;
	}

	const onClickHandler = () => {
		navigate(
			FraudConfigurationRouter.getIndexRoute(
				true,
				FraudConfigurationRouter.getProfileTabs().GlobalBlockList.header
			)
		);
	};

	return (
		<Item onClick={onClickHandler}>
			<span className="d-flex justify-content-start align-items-center">
				<i className="fas fa-fw fa-search" />
				<span>
					<span>{FraudConfigurationRouter.getName()}</span>
				</span>
			</span>
		</Item>
	);
};

export const getAlertHistoryColumns = (
	onClickNote: (alertHistory: GuardianAlertHistoryIndexResponse) => void
): Array<DataGridColumn<GuardianAlertHistoryIndexResponse>> => {
	return [
		DefaultDataGridColumn({
			accessorKey: 'summary',
			header: 'Summary',
			minSize: 150,
			getValue: ({ row, cell }) => {
				return (
					<div className="d-flex align-items-center gap-2">
						<OverlayTrigger
							placement="right"
							overlay={(props) => (
								<Tooltip id={`notesGridButton_${row.index}-tooltip`} {...props}>
									See Notes
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									variant="light"
									ref={ref}
									{...triggerHandler}
									size="sm"
									className="ml-1"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();

										onClickNote(
											row.original as GuardianAlertHistoryIndexResponse
										);
									}}
								>
									<i className="fas fa-sticky-note" />
								</Button>
							)}
						</OverlayTrigger>
						<div style={{ whiteSpace: 'break-spaces' }}>
							{cell.getValue<string>()}
						</div>
					</div>
				);
			}
		}),
		DateDataGridColumn({
			accessorKey: 'updatedTs',
			header: 'Last Updated',
			format: 'YYYY-MM-DD HH:mm:ss',
			exportExcelDateFormat: 'yyyy-mm-dd hh:mm:ss AM/PM'
		}),
		DefaultDataGridColumn({
			accessorKey: 'alertSummary',
			header: 'Alert'
		})
	];
};

export const getAlertHistoryFailureDataColumns = (): Array<
	DataGridColumn<GuardianAlertHistoryFailureData>
> => [
	PrecisionDataGridColumn({
		accessorKey: 'failureValue',
		header: 'Value',
		decimals: 1
	}),
	LinkDataGridColumn({
		url: (row) => {
			const searchParams: RtVueReportUrlSearchParamsCreate = {
				timeRange: JSURL.stringify(row.timeFilter)
			};

			return CdrSearchViewRouter.getDrillDownPath(searchParams, row.filters);
		},
		accessorKey: 'vuid',
		header: 'VUID',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'rateCenterId',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DateDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start Date',
		hiddenIfEmpty: true
	}),
	DateDataGridColumn({
		accessorKey: 'endTs',
		header: 'End Date',
		hiddenIfEmpty: true
	})
];

export const alertHistoryFailureDataMenuLinks = (
	row: GuardianAlertHistoryFailureData,
	navigate: NavigateFunction
) => {
	if (
		isEmpty(!row.timeFilter.timeObject) ||
		isEmpty(!row.timeFilter.detailLevel)
	) {
		return null;
	}

	const onClickFailureItem = () => {
		const searchParams: RtVueReportUrlSearchParamsCreate = {
			timeRange: JSURL.stringify(row.timeFilter)
		};

		const drillDownPath = CdrSearchViewRouter.getDrillDownPath(
			searchParams,
			row.filters
		);

		navigate(drillDownPath);
	};

	return (
		<Item onClick={() => onClickFailureItem()}>
			<span className="d-flex justify-content-start align-items-center">
				<i className="fas fa-fw fa-search" />
				<span>
					<span>{CdrSearchViewRouter.getName()}</span>
				</span>
			</span>
		</Item>
	);
};
